import React, { useState, useEffect, useContext } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Typeahead, Highlighter } from "react-bootstrap-typeahead";
import { Row, Col, Button, Stack, Form, Modal } from "react-bootstrap";
import { UserContext } from "../UserContextManager";
import { SupplyContext } from "../SupplyContextManager";
import axios from "axios";
import cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import CurrencyInput from "react-currency-input-field";

function SupplySOMainTerminal() {
  const uContext = useContext(UserContext);
  const sContext = useContext(SupplyContext);

  const [ordersList, setOrdersList] = useState([]);

  const [maxOrderSum, setMaxOrderSum] = useState(0);

  const [formData, updateFormData] = useState({});

  const [orderInfo, setOrderInfo] = useState({});

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmModalText, setConfirmModalText] = useState("");

  const [sum, setSum] = useState("");

  const orderRef = React.createRef();

  const calculateSum = (e) => {
    if (!maxOrderSum) {
      setSum(e);
      return;
    }

    if (e > maxOrderSum) {
      setSum(maxOrderSum);
    } else {
      setSum(e);
    }
  };

  const loadOrderInfo = async (orderNumber) => {
    if (!ordersList.includes(orderNumber)) {
      setOrderInfo({});
      sContext.setHelpersData({'prepayment': 0});
      setMaxOrderSum(0);
      setSum("");
      return;
    }

    let res = await axios.get(`/redesk/supply/so/order-info/${orderNumber}`);

    setOrderInfo(res.data.order_data);

    sContext.setHelpersData({'prepayment': res.data.order_data.prepayment});

    let max_sum = res.data.order_data.so_sum - res.data.order_data.in_desk;
    setMaxOrderSum(max_sum);
  };

  const loadOrdersList = async () => {
    try {
      let res = await axios.get("/redesk/supply/order");
      setOrdersList(res.data.orders_list);
    } catch {
      return;
    }
  };

  const handleChange = (e, k = null) => {
    if (k !== null) {
      updateFormData({ ...formData, [k]: e });
    } else {
      updateFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const compareDraft = () => {
    let draftCopy = [...uContext.userData.draft_data];

    let has_duplicate = false;
    draftCopy.forEach((x) => {
      if (Math.abs(x.sum) === Number(sum) && x.metric_4 === formData.metric_4) {
        has_duplicate = true;
      }
    });

    return has_duplicate;
  };

  const handleSubmitWithConfirm = (e) => {
    if (!ordersList.includes(formData.metric_4)) {
      alert("Номер заказа указан неверно!");
      return;
    }

    if (sum === "" || sum === 0 || sum === "0") {
      alert("Введите сумму!");
      return;
    }

    if (compareDraft()) {
      setConfirmModalText(
        "Похожая запись уже есть в черновике. Создать еще одну?"
      );
      setShowConfirmModal(true);
      return;
    }

    handleSubmit(e);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let draftSendData = { ...orderInfo };

    draftSendData["order_number"] = formData.metric_4;
    draftSendData["sum"] = sum.replace(",", ".");
    draftSendData["comment"] = formData.terminal_comment;

    let res = await axios.post("/redesk/draft/so/supply/send", draftSendData, {
      headers: { "X-CSRF-TOKEN": cookies.get("csrf_access_token") },
    });

    let contextData = uContext.userData;
    contextData.draft_data = res.data.draft_data;
    uContext.setUserData(contextData);

    setSum("");
    loadOrderInfo(draftSendData["order_number"]);

  };

  useEffect(() => {
    loadOrdersList();

    return () => {};
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {};
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Form id="terminal_main_form" className="mt-2">
      <Row>
        <Col xs={3}>
          <Form.Group className="mb-3" controlId="t_o_o_n">
            <Form.Label>Номер заказа</Form.Label>
            <Typeahead
              id="terminal_order_number"
              name="metric_4"
              ref={orderRef}
              labelKey="value"
              placeholder="Номер заказа"
              maxResults={7}
              emptyLabel="Нет совпадений"
              paginationText="Показать больше"
              highlightClassName="font-weight-bold"
              onInputChange={(text) => {
                handleChange(text, "metric_4");
                loadOrderInfo(text);
              }}
              onChange={(selected) => {
                handleChange(selected[0], "metric_4");
                loadOrderInfo(selected[0]);
              }}
              options={ordersList}
              size="sm"
              renderMenuItemChildren={(option, props, idx) => (
                <Highlighter
                  search={props.text}
                  highlightClassName="fw-bold mx-0 px-0 bg-transparent"
                >
                  {option}
                </Highlighter>
              )}
            />
          </Form.Group>
        </Col>
        <Col xs={3}>
          <Form.Group className="mb-3" controlId="t_o_id">
            <Form.Label>Ид объекта</Form.Label>
            <Form.Control
              size="sm"
              name="terminal_object_id"
              value={orderInfo.object_id || ""}
              disabled
            />
          </Form.Group>
        </Col>
        <Col lg={3}>
          <Form.Group className="mb-3">
            <Form.Label>Тип заказа</Form.Label>
            <Form.Control
              size="sm"
              value={orderInfo.order_type || ""}
              disabled
            />
          </Form.Group>
        </Col>
        <Col lg={3}>
          <Form.Group className="mb-3">
            <Form.Label>НУ</Form.Label>
            <Form.Control size="sm" value={orderInfo.manager || ""} disabled />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Form.Group className="mb-3" controlId="t_s">
            <Form.Label>
              Сумма {maxOrderSum ? `(Не более ${maxOrderSum.toFixed(2)})` : ""}
            </Form.Label>
            <CurrencyInput
              autoComplete="off"
              id="terminal_sum"
              name="terminal_sum"
              className="form-control"
              style={{ maxHeight: "30px" }}
              value={sum}
              placeholder="Сумма"
              decimalsLimit={2}
              allowNegativeValue={false}
              groupSeparator={" "}
              onValueChange={(e) => {
                calculateSum(e);
                handleChange(e, "terminal_sum");
              }}
              disabled={maxOrderSum > 0 ? false : true}
            />
          </Form.Group>
        </Col>
        <Col lg={3}>
          <Form.Group className="mb-3">
            <Form.Label>Юрлицо</Form.Label>
            <Form.Control
              size="sm"
              value={orderInfo.company_name || ""}
              disabled
            />
          </Form.Group>
        </Col>
        <Col lg={3}>
          <Form.Group className="mb-3">
            <Form.Label>Ответственный</Form.Label>
            <Form.Control size="sm" value={orderInfo.foreman || ""} disabled />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <Form.Group as={Col} className="mb-3" controlId="t_c">
            <Form.Label>Примечание</Form.Label>
            <Form.Control
              size="sm"
              name="terminal_comment"
              autoComplete="off"
              placeholder="Примечание"
              type="text"
              onChange={handleChange}
            />
          </Form.Group>
        </Col>
        <Col lg={3}>
          <Form.Group className="mb-3">
            <Form.Label>Провели по кассе</Form.Label>
            <Form.Control
              size="sm"
              value={orderInfo.in_desk ? orderInfo.in_desk.toFixed(2) : "0"}
              disabled
            />
          </Form.Group>
        </Col>
        <Col lg={3}>
          <Form.Group className="mb-3">
            <Form.Label>Сумма акций</Form.Label>
            <Form.Control
              size="sm"
              value={orderInfo.so_sum ? orderInfo.so_sum.toFixed(2) : "0"}
              disabled
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-2">
        <Stack gap={2}>
          <Button
            variant="primary"
            size="sm"
            className="mb-1 mt-2 mx-auto"
            onClick={handleSubmitWithConfirm}
          >
            Отправить
          </Button>
        </Stack>
      </Row>
      <Modal
        centered
        show={showConfirmModal}
        onHide={() => setShowConfirmModal(false)}
      >
        <Modal.Body>
          <p>{confirmModalText}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowConfirmModal(false)}>
            Нет
          </Button>
          <Button
            variant="danger"
            style={{ minWidth: "12%" }}
            onClick={(e) => {
              handleSubmit(e);
              setShowConfirmModal(false);
            }}
          >
            Да
          </Button>
        </Modal.Footer>
      </Modal>
    </Form>
  );
}

export default SupplySOMainTerminal;
