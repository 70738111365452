import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Typeahead, Highlighter } from "react-bootstrap-typeahead";
import { Row, Col, Button, Form } from "react-bootstrap";

function MasterFot(props) {
  const [fotParams, setFotParams] = useState({});

  let fotList = props.fotList;

  const updateFotParams = (k, v, index) => {
    if (!v) {
      delete fotParams[k];

      let oldData = { ...props.formData };
      oldData[`terminal_fot_master_fot_${index}`] = null;
      props.setFormData(oldData);

      props.updateFotData(`terminal_fot_master_fot_${index}`, 0);

      document.getElementById(`terminal_fot_master_fot_${index}`).value = null;

      return;
    }

    let fp = fotParams;
    fp[k] = v;
    setFotParams(fp);
  };

  const setFormData = (data) => {
    props.setFormData(data);
  };

  const removeItem = (e, index) => {
    let oldData = { ...props.formData };

    props.updateFotData(`terminal_fot_master_fot_${index}`, 0);

    delete oldData[`terminal_fot_master_${index}`];
    delete oldData[`terminal_fot_master_fot_${index}`];

    setFormData(oldData);

    props.delOnClick(e);
  };

  return (
    <>
      {fotList.map((uuid, index) => {
        return (
          <>
            <Row
              key={uuid}
              list-key={uuid}
              className="border border-primary rounded-4"
              style={{
                boxShadow: "0px 5px 10px 2px rgba(34, 60, 80, 0.2)",
                padding: 0,
                paddingTop: "5px",
                marginLeft: "1px",
                marginBottom: "15px",
              }}
            >
              <Col xs={6}>
                <Form.Group as={Col} className="mb-3">
                  <Form.Label>ФИО мастера</Form.Label>
                  <Typeahead
                    id={index}
                    name={`terminal_fot_master_${index}`}
                    labelKey="value"
                    placeholder="ФИО мастера"
                    maxResults={7}
                    emptyLabel="Нет совпадений"
                    paginationText="Показать больше"
                    highlightClassName="font-weight-bold"
                    onChange={(selected) => {
                      props.onChange(
                        selected[0],
                        `terminal_fot_master_${index}`
                      );
                      updateFotParams(
                        `terminal_fot_master_${index}`,
                        selected[0],
                        index
                      );
                    }}
                    onInputChange={(text) => {
                      props.onChange(text, `terminal_fot_master_${index}`);
                      updateFotParams(
                        `terminal_fot_master_${index}`,
                        text,
                        index
                      );
                    }}
                    options={props.options}
                    size="sm"
                    renderMenuItemChildren={(option, props, idx) => (
                      <Highlighter
                        search={props.text}
                        highlightClassName="fw-bold mx-0 px-0 bg-transparent"
                      >
                        {option}
                      </Highlighter>
                    )}
                  />
                </Form.Group>
              </Col>
              <Col xs={6}>
                <Form.Group as={Col} className="mb-3">
                  <Form.Label>ФОТ мастера</Form.Label>
                  <Form.Control
                    size="sm"
                    defaultValue=""
                    autoComplete="off"
                    id={`terminal_fot_master_fot_${index}`}
                    name={`terminal_fot_master_fot_${index}`}
                    onKeyPress={props.onKeyPress}
                    placeholder="ФОТ мастера"
                    onChange={(e) => {
                      props.updateFotData(e.target.name, e.target.value);
                      props.onChange(
                        e.target.value,
                        `terminal_fot_master_fot_${index}`
                      );
                      props.calculateRemainingFot();
                    }}
                    disabled={
                      props.options.indexOf(
                        fotParams[`terminal_fot_master_${index}`]
                      ) > -1
                        ? false
                        : true
                    }
                  />
                </Form.Group>
              </Col>
              <Col xs={6}>
                <Form.Group className="mb-3" controlId="t_f_p_t">
                  <Form.Label>Тип платежа ФОТ</Form.Label>
                  <Form.Select
                    size="sm"
                    name={`terminal_fot_master_payment_type_${index}`}
                    defaultValue="Нал"
                    onChange={(e) => {
                      props.onChange(
                        e.target.value,
                        `terminal_fot_master_payment_type_${index}`
                      );
                    }}
                  >
                    <option key="Нал" value="Нал">
                      Нал
                    </option>
                    <option key="Безнал" value="Безнал">
                      Безнал
                    </option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xs={1}>
                <Form.Group as={Col} className="mb-3" controlId="t_f_m_t">
                  <Form.Label>Временный</Form.Label>
                  <Form.Select
                    size="sm"
                    name={`terminal_fot_master_temp_${index}`}
                    defaultValue="Нет"
                    onChange={(e) => {
                      props.onChange(
                        e.target.value,
                        `terminal_fot_master_temp_${index}`
                      );
                    }}
                  >
                    <option key="Нет">Нет</option>
                    <option key="Да">Да</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xs={4}>
                <Form.Group className="mb-3" controlId="t_c">
                  <Form.Label>Примечание</Form.Label>
                  <Form.Control
                    size="sm"
                    autoComplete="off"
                    name={`terminal_fot_master_comment_${index}`}
                    placeholder="Примечание"
                    type="text"
                    onChange={(e) => {
                      props.onChange(
                        e.target.value,
                        `terminal_fot_master_comment_${index}`
                      );
                    }}
                  />
                </Form.Group>
              </Col>
              {index > 0 ? (
                <>
                  <Col xs={1} className="pt-4">
                    <Button
                      variant="danger"
                      list-key={uuid}
                      className="mb-3 mt-2"
                      size="sm"
                      onClick={(e) => {
                        removeItem(e, index);
                      }}
                    >
                      -
                    </Button>
                  </Col>
                </>
              ) : null}
            </Row>
          </>
        );
      })}
    </>
  );
}

export default MasterFot;
