import { Button, InputGroup, Form, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { forwardRef } from 'react';

export const SearchBox = forwardRef( (props, ref) =>
{
    //props
    // onSearchClick()
    // onSearchInput()

    const handleEnter = (e, f) => {
        var key=e.key || e.which;

        if (key == 'Enter')
        {
            f();
        }

        if (key == 'Escape')
        {
            e.target.value = "";
            f();
        }
    }

    return (
        <InputGroup>
            <Form.Control
                onInput={ props.onSearchInput } onKeyUp={ (e) => {handleEnter(e, props.onSearchClick)} }
                ref={ref} id="history_search" size="sm" value={props.value} name="history_search" placeholder="Поиск"
            />
            <Button variant="outline-primary" id="button-addon2" className='btn-with-icon'
                size="sm" onClick={ props.onSearchClick }>
            <span className='material-icons-sm'>search</span>
            </Button>
        </InputGroup>
    );
});