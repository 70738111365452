import React, { useState } from 'react'

export const ManagementSecretContext = React.createContext({
  helpersData: {
    secret_cash: 0,
    secret_noncash: 0,
  },
  setHelpersData: () => {},
})

export const ManagementSecretContextProvider = (props) => {
  const setHelpersData = (helpersData) => {
    setState({...state, helpersData: helpersData})
  }


  const initState = {
    helpersData: {
        secret_cash: 0,
    secret_noncash: 0,
    },
    setHelpersData: setHelpersData
  }

  const [state, setState] = useState(initState)

  return (
    <ManagementSecretContext.Provider value={state}>
      {props.children}
    </ManagementSecretContext.Provider>
  )
}
