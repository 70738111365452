import React, {useState, useEffect} from 'react';
import "bootstrap/dist/css/bootstrap.css";
import {Row, Col, Button, Table, Modal, Form, Container, Spinner} from "react-bootstrap";
import ReactPaginate from 'react-paginate';
import axios from 'axios';
import { MultiSelect } from 'primereact/multiselect';
import { SearchBox } from "../general/SearchBox";
import { CalcByClick } from '../general/CalcByClick';
import "primereact/resources/themes/lara-light-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

function NuSpbHistory(){

  const searchRef = React.createRef();
  const tableSearchRef = React.createRef();

  const [historyData, setHistoryData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [historyModal, setHistoryModal] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(-1);
  const [searchString, setSearchString] = useState(null);
  const [searchRefValue, setSearchRefValue] = useState('');

  const [filterDropdowns, setFilterDropdowns] = useState([]);
  const [itemFilter, setItemFilter] = useState([]);
  const [workFilter, setWorkFilter] = useState([]);
  const [CalcByClick_value, setCalcByClick_value ] = useState(0);

  const inst_CalcByClick = new CalcByClick(setCalcByClick_value , 'table.rd-accounting td' );

const loadFilterDropdowns = async() => {
  let res = await axios.get('/redesk/terminal/main-dropdowns', {params:{desk_type:'nu_spb'}})
  setFilterDropdowns(res.data.dropdowns_data);
}

const fetchHistory = async (currentPage) => {

  let paginationParams = {
    'page': currentPage,
    'per_page': 15,
    'search_string': searchString,
    'filter_item': itemFilter.join(';'),
    'filter_metric_1': workFilter.join(';'),
  }

  let res = await axios.get('/redesk/nu_spb/history', {params:paginationParams});
  let data = await res.data;
  return data;
}

const handlePageClick = async (data) => {

  setCurrentPage(data.selected);

  let currentPage = data.selected

  const historyItems = await fetchHistory(currentPage);

  setHistoryData(historyItems.history_data)
};

  useEffect(() => {

    let initialHistory = async () => {

      setLoading(true);

      setCurrentPage(0);

      let paginationParams = {
        'page': currentPage,
        'per_page': 15,
        'search_string': searchString,
        'filter_item': itemFilter.join(';'),
        'filter_metric_1': workFilter.join(';'),
      }

      let res = await axios.get('/redesk/nu_spb/history', {params:paginationParams});
      let data = await res.data;
      let total = data.total
      setPageCount(Math.ceil(total/15))
      setHistoryData(data.history_data);
      setLoading(false);
    };

    initialHistory();

    return () => {};
  }, [searchString, workFilter, itemFilter]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    loadFilterDropdowns();
    return () => {};
  }, [])

  return(
    <Container fluid className="shadow pt-2">
      <Row className='pb-2'>
        <Col lg={3} style={{textAlign: "left"}}>
          <SearchBox
            ref={searchRef}
            onSearchClick={ () => {setSearchString(searchRef.current.value); setCurrentPage(0)} }
            onSearchInput={ (e) => {setSearchRefValue(e.target.value)} }
            id="history_search"
            name="history_search"
            placeholder="Поиск"
          />
        </Col>
        <Col lg={3}>
          <MultiSelect
            value = {itemFilter}
            options={filterDropdowns.filter(e => e.dropdown_name === 'nu_spb_item').filter(e => ((e.value !== 'Предоплата') || (e.spec !== 'Расход')))}
            placeholder="Статья"
            maxSelectedLabels={0}
            onChange={(e) => {setItemFilter(e.value)}}
            showSelectAll={false}
            panelHeaderTemplate={<></>}
            selectedItemsLabel={`${itemFilter.length} Выбрано`}
            className="small wide"
          />
        </Col>
        <Col lg={3}>
          <MultiSelect
            value = {workFilter}
            options={filterDropdowns.filter(e => e.dropdown_name === 'nu_spb_work_type')}
            placeholder="Вид работ"
            maxSelectedLabels={0}
            onChange={(e) => {setWorkFilter(e.value)}}
            showSelectAll={false}
            panelHeaderTemplate={<></>}
            selectedItemsLabel={`${workFilter.length} Выбрано`}
            className="small wide"
          />          
        </Col>

        <Col lg={2}></Col>

        <Col lg={1} style={{textAlign: "right"}}>
          <Button variant="outline-primary" size="sm" className='btn-with-icon pb-0' onClick={() => setHistoryModal(true)}>
            <span class="material-icons-sm">fullscreen</span>
          </Button>
        </Col>
        
      </Row>
          {(historyData !== 'no_result' && loading === false) ? (
          <>
          <Row>
            <Col sm={12}>
              <Table striped bordered size="sm" className="rd-accounting" responsive>
                <thead class="align-middle">
                  <tr>
                    <th>Дата подтверждения</th>
                    <th>Тип статьи</th>
                    <th>Сумма</th>
                    <th>Статья</th>
                    <th>Подстатья</th>
                    <th>Метрика 1</th>
                    <th>Метрика 4</th>
                    <th>ИД</th>
                    <th>Ответственный</th>
                    <th>Тип платежа</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    historyData.map((item) => (

                      <tr class="align-middle" key={item.uuid} row-uuid={item.uuid}>
                        <td>{item.confirmation_date}</td>
                        <td>{item.item_type}</td>
                        <td className="sum-column"
                            data-calcbyclickvalue={item.sum}
                            onClick={inst_CalcByClick.onItemClick} >
                                {window.redesk_app.common.fmtFinValue.format(item.sum)}
                        </td>
                        <td>{item.item}</td>
                        <td>{item.sub_item}</td>
                        <td>{item.metric_1}</td>
                        <td>{item.metric_4}</td>
                        <td>{item.object_id}</td>
                        <td>{item.responsible_user}</td>
                        <td>{item.payment_type}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
            </Col>
            </Row>
            <Row>
              <Col lg={2} className='align-self-center'>
                Выбрано: <span onClick={inst_CalcByClick.onResultClick}>{window.redesk_app.common.fmtFinValue.format(CalcByClick_value)}</span>
              </Col>
              <Col lg={2} className='align-self-center'></Col>
              <Col lg={4}>
                <ReactPaginate
                  nextLabel={'>>'}
                  previousLabel={'<<'}
                  breakLabel={'...'}
                  pageCount={pageCount}
                  pageRangeDisplayed={3}
                  forcePage={currentPage}
                  containerClassName={'pagination justify-content-center'}
                  pageClassName={'page-item'}
                  pageLinkClassName={'page-link'}
                  previousClassName={'page-item'}
                  previousLinkClassName={'page-link'}
                  nextClassName={'page-item'}
                  nextLinkClassName={'page-link'}
                  breakClassName={'page-item'}
                  breakLinkClassName={'page-link'}
                  activeClassName={'active'}
                  size="sm"
                  onPageChange={handlePageClick}
                />
              </Col>
              <Col lg={2} className='align-self-center'></Col>
              <Col lg={2} className='align-self-center'></Col>
            </Row>
          </>
          ) : (historyData === 'no_result' && loading === false) ? (
          <Row>
            <Col sm={12} style={{textAlign: "center"}}>
              <h4 style={{marginTop: "15%", marginBottom: "15%"}}>История пуста</h4>
            </Col>
          </Row>
          ) : (
            <Row>
            <Col sm={12} style={{textAlign: "center"}}>
              <div style={{marginTop: "15%", marginBottom: "15%"}}>
                <Spinner animation="border" variant="primary"/>
              </div>
            </Col>
            </Row>
          )}
          <Modal show={historyModal} fullscreen={true} onHide={() => setHistoryModal(false)}>
              <Modal.Header closeButton>
              <Modal.Title>История</Modal.Title>
              </Modal.Header>
                <Modal.Body>
                <Row className='pb-2'>
                  <Col lg={2}>
                    <SearchBox
                      ref={tableSearchRef}
                      onSearchClick={ () => {setSearchString(tableSearchRef.current.value)} }
                      onSearchInput={ (e) => {searchRef.current.value = e.target.value} }
                      id="history_search"
                      name="history_search"
                      placeholder="Поиск"
                    />
                  </Col>
                  <Col lg={10}></Col>
                </Row>
                {(historyData !== 'no_result' && loading === false) ? (
                  <Table striped bordered size="sm" className="rd-accounting" responsive>
                    <thead class="align-middle">
                      <tr>
                        <th>Дата создания</th>
                        <th>Дата подтверждения</th>
                        <th>Тип статьи</th>
                        <th>Сумма</th>
                        <th>Статья</th>
                        <th>Подстатья</th>
                        <th>Метрика 1</th>
                        <th>ИД</th>
                        <th>Метрика 4</th>
                        <th>Метрика 5</th>
                        <th>Ответственный</th>
                        <th>Примечание</th>
                        <th>Тип платежа</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        historyData.map((item) => (

                          <tr class="align-middle" key={item.uuid} row-uuid={item.uuid}>
                            <td>{item.creation_date}</td>
                            <td>{item.confirmation_date}</td>
                            <td>{item.item_type}</td>
                            <td className="sum-column">{window.redesk_app.common.fmtFinValue.format(item.sum)}</td>
                            <td>{item.item}</td>
                            <td>{item.sub_item}</td>
                            <td>{item.metric_1}</td>
                            <td>{item.object_id}</td>
                            <td>{item.metric_4}</td>
                            <td>{item.metric_5}</td>
                            <td>{item.responsible_user}</td>
                            <td>{item.comment}</td>
                            <td>{item.payment_type}</td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </Table>
                ) : (historyData === 'no_result' && loading === false) ? (
                  <Col sm={12} style={{textAlign: "center"}}>
                    <h4 style={{marginTop: "15%", marginBottom: "15%"}}>История пуста</h4>
                  </Col>
                ) : (
                  <Col sm={12} style={{textAlign: "center"}}>
                    <div style={{marginTop: "15%", marginBottom: "15%"}}>
                      <Spinner animation="border" variant="primary"/>
                    </div>
                  </Col>
                )}
                </Modal.Body>
                {(historyData !== 'no_result' && loading === false) ? (
                  <Modal.Footer className='justify-content-center'>
                    <ReactPaginate
                      nextLabel={'>>'}
                      previousLabel={'<<'}
                      breakLabel={'...'}
                      pageCount={pageCount}
                      pageRangeDisplayed={3}

                      forcePage={currentPage}
                      containerClassName={'pagination justify-content-center'}
                      pageClassName={'page-item'}
                      pageLinkClassName={'page-link'}
                      previousClassName={'page-item'}
                      previousLinkClassName={'page-link'}
                      nextClassName={'page-item'}
                      nextLinkClassName={'page-link'}
                      breakClassName={'page-item'}
                      breakLinkClassName={'page-link'}
                      activeClassName={'active'}

                      onPageChange={handlePageClick}
                    />
                  </Modal.Footer>
                ) : (
                    <Modal.Footer className='justify-content-center'>
                    </Modal.Footer>
                )}

          </Modal>
    </Container>
  )
}



export default NuSpbHistory;
