import React, { useState, useEffect, useContext } from "react";
import "bootstrap/dist/css/bootstrap.css";
import {
  Row,
  Col,
  Button,
  Table,
  Modal,
  Form,
  Container,
  ToggleButtonGroup,
  ToggleButton,
  Spinner,
  Fade,
} from "react-bootstrap";
import { UserContext } from "../UserContextManager";
import axios from "axios";
import cookies from "js-cookie";
import { AccountingContext } from "../AccountingContextManager";
import { CCContext } from "../CCContextManager";
import { DesignContext } from "../DesignContextManager";
import { DKContext } from "../DKContextManager";
import { FundContext } from "../FundContextManager";
import { ManagementContext } from "../ManagementHelpersManager";
import { MarketingContext } from "../MarketingContextManager";
import { MechContext } from "../MechContextManager";
import { NPContext } from "../NPContextManager";
import { NsdoContext } from "../NsdoContextManager";
import { NUContext } from "../NUHelpersManager";
import { OPContext } from "../OPContextManager";
import { SMPContext } from "../SMPContextManager";
import { CalcByClick } from "../general/CalcByClick";

function IncomingItems() {
  const uContext = useContext(UserContext);

  const [isLoading, setIsLoading] = useState(false);

  const [metrics, setMetrics] = useState([]);

  const [iiData, setIiData] = useState([]);
  const [batchConfirm, setBatchConfirm] = useState([]);
  const [tgbValue, setTgbValue] = useState("incoming-reas");

  const [currentObjectAccount, setCurrentObjectAccount] = useState(false);
  const [modalIi, setModalIi] = useState({});
  const [show, setShow] = useState(false);
  const [fIModal, setFIModal] = useState(false);

  const aContext = useContext(AccountingContext);
  const cContext = useContext(CCContext);
  const dContext = useContext(DesignContext);
  const dkContext = useContext(DKContext);
  const fContext = useContext(FundContext);
  const mContext = useContext(ManagementContext);
  const marketingContext = useContext(MarketingContext);
  const mechContext = useContext(MechContext);
  const npContext = useContext(NPContext);
  const nsdoContext = useContext(NsdoContext);
  //const nuContext = useContext(NUContext);
  const opContext = useContext(OPContext);
  const smpContext = useContext(SMPContext);

  const [CalcByClick_value, setCalcByClick_value] = useState(0);
  const inst_CalcByClick = new CalcByClick(
    setCalcByClick_value,
    "table.rd-accounting td"
  );

  const contextDict = {
    accounting: aContext,
    cc: cContext,
    design: dContext,
    dk: dkContext,
    fund: fContext,
    management: mContext,
    marketing: marketingContext,
    mech: mechContext,
    np: npContext,
    nsdo: nsdoContext,
    op: opContext,
    smp: smpContext,
  };

  const loadIiData = async () => {
    setIsLoading(true);

    let url = "";
    tgbValue === "incoming-reas"
      ? (url = "/redesk/reas/incoming/items")
      : (url = "/redesk/reas/outcoming/items");

    let res = await axios.get(url, {
      params: { desk_owner: window.location.pathname },
    });

    setIiData(res.data.ii_data);
    setIsLoading(false);
  };

  const loadUserData = async () => {
    let userDataRes = await axios.get("/redesk/general/remainder");

    let data = await userDataRes.data;

    //let draftRes = await axios.get('/redesk/draft');

    //let draftData = await draftRes.data;

    data.user_data["draft_data"] = uContext.userData.draft_data;

    uContext.setUserData(data.user_data);
  };

  const handleShow = (e) => {
    if (!e.ctrlKey & !e.metaKey) {
      setModalIi(
        iiData.find((element) => {
          return element.uuid === e.currentTarget.getAttribute("row-uuid");
        })
      );
      setShow(true);
    }
  };

  const loadHelpers = async () => {
    let currentDesk = window.location.pathname.replace("/", "");

    if (!(currentDesk in contextDict)) {
      return;
    }

    let url = `/redesk/${currentDesk}/helpers`;
    let res = await axios.get(url);
    contextDict[currentDesk].setHelpersData(res.data.helpers_data);
  };

  const handleClose = () => setShow(false);

  const showWholeTable = () => {
    setFIModal(true);
  };

  const confirmRow = async (uuid, metric_5) => {
    let sMetric;
    if (!metric_5 || metric_5 === "Счет") {
      sMetric = false;
    } else {
      sMetric = metric_5;
    }

    await axios.post(
      "/redesk/reas/incoming/confirm",
      { batchConfirm: [{ uuid: uuid, metric_5: sMetric }] },
      { headers: { "X-CSRF-TOKEN": cookies.get("csrf_access_token") } }
    );
    loadIiData();
    setShow(false);
    loadUserData();
    loadHelpers();
  };

  const dismissRow = async (e = null) => {
    e.preventDefault();

    let rowsTodismiss;

    if (batchConfirm.length < 1) {
      rowsTodismiss = [e.currentTarget.getAttribute("row-uuid")];
    } else {
      rowsTodismiss = batchConfirm;
    }

    if (rowsTodismiss[0] === null) {
      return null;
    } else {
      let res = await axios.post(
        "/redesk/reas/incoming/dismiss",
        { dismiss: rowsTodismiss },
        { headers: { "X-CSRF-TOKEN": cookies.get("csrf_access_token") } }
      );
      //setIiData(res.data.ii_data);
      loadIiData();
      setFIModal(false);
      setShow(false);
      setBatchConfirm([]);
      loadUserData();
      loadHelpers();
    }
  };

  const deleteDismissed = async (e) => {
    e.preventDefault();

    let res = await axios.post(
      "/redesk/reas/outcoming/delete",
      { delete: e.currentTarget.getAttribute("row-uuid") },
      { headers: { "X-CSRF-TOKEN": cookies.get("csrf_access_token") } }
    );
    loadIiData();
    //setIiData(res.data.ii_data);
    setFIModal(false);
    setShow(false);
    loadUserData();
    loadHelpers();
  };

  const confirmRowBatch = async () => {
    if (batchConfirm.length < 1) {
      return null;
    } else {
      let bCopy = [];
      batchConfirm.forEach((x) => {
        let a = iiData.filter((e) => {
          return e.uuid === x;
        });

        let m;
        if (!a[0]["metric_5"] || a[0]["metric_5"] === "Счет") {
          m = false;
        } else {
          m = a[0]["metric_5"];
        }

        bCopy.push({ uuid: x, metric_5: m });
      });

      let res = await axios.post(
        "/redesk/reas/incoming/confirm",
        { batchConfirm: bCopy },
        { headers: { "X-CSRF-TOKEN": cookies.get("csrf_access_token") } }
      );
      loadIiData();
      //setIiData(res.data.ii_data);
      setFIModal(false);
      setBatchConfirm([]);
      loadUserData();
      loadHelpers();
    }
  };

  const handleCheckBox = (e) => {
    if (e.target.checked) {
      setBatchConfirm((batchConfirm) => [
        ...batchConfirm,
        e.target.getAttribute("id"),
      ]);
    } else {
      let filterBatch = batchConfirm.filter(
        (item) => item !== e.target.getAttribute("id")
      );
      setBatchConfirm(filterBatch);
    }
  };

  const loadMetrics = async () => {
    let desk = window.location.pathname;

    if (!["/fund", "/management"].includes(desk)) {
      return;
    }

    let defaultMetrics = ["Карта", "Нал"];

    if (desk === "/fund") {
      setMetrics(defaultMetrics);
      return;
    }

    if (desk === "/management") {
      let res = await axios.get("/redesk/general/incoming/metrics");

      setMetrics(res.data.metrics.concat(defaultMetrics));
      return;
    }
  };

  const handleMetricChange = (e) => {
    let uuid = e.currentTarget.getAttribute("id");
    let tbCopy = [...iiData];

    let ii = tbCopy.findIndex((x) => x.uuid === uuid);
    tbCopy[ii]["metric_5"] = e.target.value;
    setIiData(tbCopy);
  };

  useEffect(() => {
    loadIiData();
    loadMetrics();
    return () => {};
  }, [tgbValue]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container fluid className="shadow pt-2">
      <Row className="mb-2 mt-1">
        <Col xs={6} style={{ textAlign: "left" }}>
          <ToggleButtonGroup
            type="radio"
            name="reas-type"
            defaultValue="incoming-reas"
            onChange={(val) => {
              setTgbValue(val);
              loadUserData();
            }}
          >
            <ToggleButton
              id="tgbtn-inc"
              variant="outline-primary"
              value="incoming-reas"
              size="sm"
            >
              Входящие
            </ToggleButton>
            <ToggleButton
              id="tgbtn-outc"
              variant="outline-primary"
              value="outcoming-reas"
              size="sm"
            >
              Исходящие
            </ToggleButton>
          </ToggleButtonGroup>
        </Col>

        {iiData.length > 0 && isLoading === false ? (
          <Col xs={6} style={{ textAlign: "right" }}>
            <Button
              variant="outline-primary"
              size="sm"
              className="btn-with-icon pb-0"
              onClick={showWholeTable}
            >
              <span class="material-icons-sm">fullscreen</span>
            </Button>
          </Col>
        ) : (
          <></>
        )}
      </Row>
      <Row>
        {iiData.length > 0 && isLoading === false ? (
          <div>
            <Col sm={12}>
              <Table
                striped
                bordered
                hover
                size="sm"
                style={{ cursor: "pointer" }}
                responsive
                className="rd-accounting"
              >
                <thead>
                  <tr>
                    <th>Дата создания</th>
                    <th>
                      {tgbValue === "incoming-reas" ? "От кого" : "Владелец"}
                    </th>
                    <th>Тип статьи</th>
                    <th>Сумма</th>
                    <th>Статья</th>
                    <th>Метрика 1</th>
                    <th>ИД</th>
                    <th>Метрика 4</th>
                    <th>Ответ-й</th>
                    <th>Примечание</th>
                    <th>Тип платежа</th>
                  </tr>
                </thead>
                <tbody>
                  {iiData.map((item) => (
                    <tr
                      key={item.uuid}
                      row-uuid={item.uuid}
                      style={
                        tgbValue === "outcoming-reas" &&
                        item.confirmation_date !== null &&
                        item.confirmed == false
                          ? { backgroundColor: "#ff9494" }
                          : tgbValue === "outcoming-reas" &&
                            item.confirmed == true
                          ? { backgroundColor: "#b1f7ad" }
                          : {}
                      }
                      onClick={handleShow}
                    >
                      <td>{item.creation_date}</td>
                      <td>{item.desk_owner}</td>
                      <td>{item.item_type}</td>
                      <td
                        className="col-value"
                        data-calcbyclickvalue={item.sum}
                        onClick={inst_CalcByClick.onItemClick}
                      >
                        {window.redesk_app.common.fmtFinValue.format(item.sum)}
                      </td>
                      <td>{item.item}</td>
                      <td>{item.metric_1}</td>
                      <td>{item.object_id}</td>
                      <td>{item.metric_4}</td>
                      <td>{item.responsible_user}</td>
                      <td>{item.comment}</td>
                      <td>{item.payment_type}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
            <Col>
              <strong>Выбрано:&nbsp;</strong>
              <span onClick={inst_CalcByClick.onResultClick}>
                {window.redesk_app.common.fmtFinValue.format(CalcByClick_value)}
              </span>
              <span>&nbsp;₽</span>
            </Col>

            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Объект {modalIi.object_id}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>
                  <strong>Дата создания:</strong> {modalIi.creation_date}
                </p>
                <p>
                  <strong>Ид объекта:</strong> {modalIi.object_id}
                </p>
                <p>
                  <strong>От кого:</strong> {modalIi.desk_owner}
                </p>
                <p>
                  <strong>Ответственный:</strong> {modalIi.responsible_user}
                </p>
                <p>
                  <strong>Создал:</strong> {modalIi.created_by_user}
                </p>
                <p>
                  <strong>Тип статьи:</strong> {modalIi.item_type}
                </p>
                <p>
                  <strong>Статья:</strong> {modalIi.item}
                </p>
                <p>
                  <strong>Сумма:</strong>{" "}
                  {window.redesk_app.common.fmtFinValue.format(modalIi.sum)}
                </p>

                {["/fund", "/management"].includes(window.location.pathname) &&
                tgbValue === "incoming-reas" ? (
                  <Row>
                    <Col xs={1} style={{ marginRight: "12px" }}>
                      <p>
                        <strong>Счет:</strong>
                      </p>
                    </Col>
                    <Col xs={5}>
                      <Form.Group className="mb-3" controlId="a_a">
                        <Form.Select
                          size="sm"
                          id={modalIi.uuid}
                          name="terminal_metric_5"
                          value={modalIi.metric_5}
                          aria-label="terminal_metric_5"
                          onChange={handleMetricChange}
                        >
                          <option key="Счет">Счет</option>
                          {metrics.map((v) => {
                            return (
                              <option key={v} value={v}>
                                {v}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                ) : (
                  <></>
                )}
                <p>
                  <strong>Тип платежа:</strong> {modalIi.payment_type}
                </p>
                <p>
                  <strong>Вид работы:</strong> {modalIi.metric_1}
                </p>
                <p>
                  <strong>Подстатья:</strong> {modalIi.sub_item}
                </p>
                <p>
                  <strong>Юрлицо:</strong> {modalIi.company_name}
                </p>
                <p>
                  <strong>Примечание:</strong> {modalIi.comment}
                </p>
              </Modal.Body>
              {tgbValue === "incoming-reas" ? (
                <Modal.Footer>
                  <Button
                    row-uuid={modalIi.uuid}
                    variant="danger"
                    size="sm"
                    onClick={dismissRow}
                  >
                    Отменить
                  </Button>
                  <Button
                    row-uuid={modalIi.uuid}
                    variant="primary"
                    size="sm"
                    onClick={() => {
                      confirmRow(modalIi.uuid, modalIi.metric_5);
                    }}
                  >
                    Подтвердить
                  </Button>
                </Modal.Footer>
              ) : tgbValue === "outcoming-reas" &&
                (modalIi.confirmation_date !== null) &
                  (modalIi.confirmed == false) ? (
                <Modal.Footer>
                  <Button
                    row-uuid={modalIi.uuid}
                    variant="danger"
                    size="sm"
                    onClick={deleteDismissed}
                  >
                    Удалить
                  </Button>
                </Modal.Footer>
              ) : (
                <></>
              )}
            </Modal>
          </div>
        ) : iiData.length < 1 && isLoading === false ? (
          <Col sm={12} style={{ textAlign: "center" }}>
            <h4 style={{ marginTop: "15%", marginBottom: "15%" }}>
              Нет заявок
            </h4>
          </Col>
        ) : (
          <Col sm={12} style={{ textAlign: "center" }}>
            <div style={{ marginTop: "15%", marginBottom: "15%" }}>
              <Spinner animation="border" variant="primary" />
            </div>
          </Col>
        )}

        <Modal
          show={fIModal}
          fullscreen={true}
          onHide={() => {
            setFIModal(false);
            setBatchConfirm([]);
          }}
        >
          <Modal.Header closeButton>
            {tgbValue === "incoming-reas" ? (
              <Modal.Title>Входящие</Modal.Title>
            ) : (
              <Modal.Title>Исходящие</Modal.Title>
            )}
          </Modal.Header>
          <Modal.Body>
            <Table
              striped
              bordered
              size="sm"
              className="rd-accounting"
              responsive
            >
              <thead>
                <tr>
                  <th>Дата создания</th>
                  <th>Ид</th>
                  <th>От кого</th>
                  <th>Ответственный</th>
                  <th>Создал</th>
                  <th>Тип статьи</th>
                  <th>Сумма</th>
                  {["/fund", "/management"].includes(
                    window.location.pathname
                  ) && tgbValue === "incoming-reas" ? (
                    <th>Счет</th>
                  ) : (
                    <></>
                  )}
                  <th>Статья</th>
                  <th>Подстатья</th>
                  <th>Вид работ</th>
                  <th>Юрлицо</th>
                  <th>Примечание</th>
                  <th>Тип платежа</th>
                  {tgbValue === "incoming-reas" ? <th>Выбор записи</th> : <></>}
                </tr>
              </thead>
              <tbody>
                {iiData.map((item) => (
                  <tr
                    key={item.uuid}
                    row-uuid={item.uuid}
                    style={
                      tgbValue === "outcoming-reas" &&
                      item.confirmation_date !== null &&
                      item.confirmed == false
                        ? { backgroundColor: "#ff9494" }
                        : tgbValue === "outcoming-reas" &&
                          item.confirmed == true
                        ? { backgroundColor: "#b1f7ad" }
                        : {}
                    }
                  >
                    <td>{item.creation_date}</td>
                    <td>{item.object_id}</td>
                    <td>{item.desk_owner}</td>
                    <td>{item.responsible_user}</td>
                    <td>{item.created_by_user}</td>
                    <td>{item.item_type}</td>
                    <td className="col-value">
                      {window.redesk_app.common.fmtFinValue.format(item.sum)}
                    </td>
                    {["/fund", "/management"].includes(
                      window.location.pathname
                    ) && tgbValue === "incoming-reas" ? (
                      <td>
                        <Form.Group controlId="a_a_t">
                          <Form.Select
                            size="sm"
                            name="account"
                            onChange={handleMetricChange}
                            value={item.metric_5}
                            id={item.uuid}
                            aria-label="terminal_metric_5"
                          >
                            <option key="Счет">Счет</option>
                            {metrics.map((v) => {
                              return (
                                <option key={v} value={v}>
                                  {v}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </Form.Group>
                      </td>
                    ) : (
                      <></>
                    )}
                    <td>{item.item}</td>
                    <td>{item.sub_item}</td>
                    <td>{item.metric_1}</td>
                    <td>{item.company_name}</td>
                    <td>{item.comment}</td>
                    <td>{item.payment_type}</td>
                    {tgbValue === "incoming-reas" ? (
                      <td className="col-centred">
                        <Form.Check
                          type="checkbox"
                          key={item.uuid}
                          id={item.uuid}
                          onChange={handleCheckBox}
                        />
                      </td>
                    ) : (
                      <></>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            {tgbValue === "incoming-reas" ? (
              <>
                <Button
                  row-uuid={modalIi.uuid}
                  variant="danger"
                  size="sm"
                  onClick={dismissRow}
                >
                  Отменить
                </Button>
                <Button variant="primary" size="sm" onClick={confirmRowBatch}>
                  Подтвердить
                </Button>
              </>
            ) : (
              <></>
            )}
          </Modal.Footer>
        </Modal>
      </Row>
    </Container>
  );
}

export default IncomingItems;
