import React, {useState, useEffect, useContext} from 'react';
import { UserContext } from '../../UserContextManager'
import "bootstrap/dist/css/bootstrap.css";
import {Row, Col, Button, Form} from "react-bootstrap";
import CurrencyInput from 'react-currency-input-field';

function Prepayment(props){

  const uContext = useContext(UserContext);

  const [paymentTypeValue, setPaymentTypeValue] = useState('Нал');
  const [prepaymentValue, setPrepaymentValue] = useState('')

  const prepaymentSum = props.prepaymentData.prepayment_sum;

  const updateFormData = (k, v) => {
    props.updateFormData(k, v);
  }

  const setFormDataDict = (data) => {
    props.setFormDataDict(data)
  }

  const setFormData = (data) => {
    props.setFormData(data)
  }

  const removeAddon = (e) => {
    props.removeAddon(e);
  }

  const addDropdownAddon = (e) => {
    props.addDropdownAddon(e);
  }

  const calculateFPRP = (e) => {

    if (e === undefined){
      setPrepaymentValue('')
      setFormDataDict({'terminal_fot_prepayment': 0})
      return
    }

    if (parseFloat(e.replace(',', '.')) > prepaymentSum){
      setPrepaymentValue(prepaymentSum > 0 ? prepaymentSum: 0)

      setFormDataDict({'terminal_fot_prepayment': prepaymentSum > 0 ? prepaymentSum: 0})
    }else{
      setPrepaymentValue(e)
      setFormDataDict({'terminal_fot_prepayment': e.replace(',', '.')})
    }
  }

  useEffect(() => {
    setFormDataDict({
      'terminal_fot_prepayment_item_type':'Расход',
      'terminal_fot_prepayment_payment_type':'Нал',
    })

    return () => {}
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return(
    <>
      <Row className='border border-primary rounded-4' style={{boxShadow: '0px 5px 10px 2px rgba(34, 60, 80, 0.2)', padding:0, paddingTop: '5px', marginLeft: '1px', marginBottom: '15px'}}>
      <Col xs={6}>
        <Form.Group className="mb-3">
          <Form.Label>Предоплата</Form.Label>
          <CurrencyInput
              id="terminal_fot_prepayment"
              name="terminal_fot_prepayment"
              className='form-control'
              style={{maxHeight: '30px'}}
              placeholder="0"
              decimalsLimit={2}
              allowNegativeValue={false}
              groupSeparator={' '}
              onValueChange={(e) => {calculateFPRP(e)}}
              disabled={prepaymentSum > 0? false: true}
              value={prepaymentValue}
            />
        </Form.Group>
      </Col>
      <Col xs={6}>
        <Form.Group className="mb-3">
          <Form.Label>Сумма предоплат</Form.Label>
          <Form.Control size="sm" value={prepaymentSum} name="terminal_fot_prepaymnet_prpsum" placeholder={prepaymentSum} disabled />
        </Form.Group>
      </Col>
        <Col xs={6}>
          <Form.Group className="mb-3">
            <Form.Label>Тип платежа</Form.Label>
            <Form.Select size="sm" name="terminal_fot_prepayment_payment_type" value={paymentTypeValue} onChange={(e) => {setPaymentTypeValue(e.target.value); updateFormData('terminal_fot_prepayment_payment_type', e.target.value)}}>
              {props.dropdownData.map(({value, label, dropdown_name}, index) => {if(dropdown_name === 'terminal_payment_type'){
                if(value === 'Нал'){
                return <option selected value={value}>{label}</option>
              }else{
                return <option value={value}>{label}</option>
              }}return null})}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col xs={5} style={{'padding-right': 0}}>
          <Form.Group className="mb-3" controlId="t_c">
              <Form.Label>Примечание</Form.Label>
              <Form.Control size="sm" name={`terminal_fot_prepayment_comment`} placeholder="Примечание" type="text" onChange={(e) => {updateFormData('terminal_fot_prepayment_comment', e.target.value)}} />
          </Form.Group>
        </Col>
        <Col xs={1} className="pt-4 justify-content-center">
          <Button variant="danger" list-key="Предоплата" className="mb-3 mt-2" size="sm" onClick={(e) => {let oldData = {...props.formData}; delete oldData['terminal_fot_prepayment']; delete oldData['terminal_fot_prepayment_item_type']; delete oldData['terminal_fot_prepayment_payment_type']; setFormData(oldData); removeAddon(e); addDropdownAddon(e)}}>
            -
          </Button>
        </Col>
      </Row>
    </>
  );
}

export default Prepayment
