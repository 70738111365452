import './App.css';
import "bootstrap/dist/css/bootstrap.css";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Login from './components/login/Login';
import NuDesk from './components/nu/NuDesk';
import NsdoDesk from './components/nsdo/NsdoDesk';
import Dash from './components/general/Dash';
import NoMatch from './components/general/NoMatch';
import SupplyDesk from './components/supply/SupplyDesk';
import SupplySpbDesk from './components/supply_spb/SupplySpbDesk';
import AccountingDesk from './components/accounting/AccountingDesk';
import MarketingDesk from './components/marketing/MarketingDesk';
import ManagementDesk from './components/management/ManagementDesk';
import DesignDesk from './components/design/DesignDesk';
import OPDesk from './components/OP/OPDesk';
import CCDesk from './components/CC/CCDesk';
import CCPartnersDesk from './components/cc_partners/CCPartnersDesk';
import DKDesk from './components/dk/DKDesk';
import MechDesk from './components/mech/MechDesk';
import DeskSelection from './components/general/DeskSelection';
import FundDesk from './components/fund/FundDesk';
import NPDesk from './components/np/NPDesk';
import SMPDesk from './components/smp/SMPDesk';
import NuSpbDesk from './components/nu_spb/NuSpbDesk';
import ManagementSpbDesk from './components/management_spb/ManagementSpbDesk';
import DesDesk from './components/des/DesDesk';

function App() {

  return(
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Dash />} />
          <Route path="desk-selection" element={<DeskSelection />} />
          <Route path="nu" element={<NuDesk />} />\
          <Route path="np" element={<NPDesk />} />
          <Route path="nu_spb" element={<NuSpbDesk />} />
          <Route path="smp" element={<SMPDesk />} />
          <Route path="dk" element={<DKDesk />} />
          <Route path="des" element={<DesDesk />} />
          <Route path="mech" element={<MechDesk />} />
          <Route path="nsdo" element={<NsdoDesk />} />
          <Route path="fund" element={<FundDesk />} />
          <Route path="accounting" element={<AccountingDesk />} />
          <Route path="supply" element={<SupplyDesk />} />
          <Route path="supply_spb" element={<SupplySpbDesk />} />
          <Route path="marketing" element={<MarketingDesk />} />
          <Route path="management" element={<ManagementDesk />} />
          <Route path="management_spb" element={<ManagementSpbDesk />} />
          <Route path="design" element={<DesignDesk />} />
          <Route path="op" element={<OPDesk />} />
          <Route path="cc" element={<CCDesk />} />
          <Route path="cc_partners" element={<CCPartnersDesk />} />
          <Route path="login" element={<Login />} />
          <Route path="*" element={<NoMatch />} />
        </Routes>
      </BrowserRouter>
  );
}

export default App;
