import React, { useState } from 'react'

export const NUContext = React.createContext({
  helpersData: {
    main_helper: 0,
    np_helper: 0,
    output_helper: 0,
    output_all_helper: 0,
    revenue_helper: 0,
    noncash_helper: 0,
    loan: 0
  },
  setHelpersData: () => {},
})

export const NUContextProvider = (props) => {
  const setHelpersData = (helpersData) => {
    setState({...state, helpersData: helpersData})
  }


  const initState = {
    helpersData: {
      main_helper: 0,
      np_helper: 0,
      output_helper: 0,
      output_all_helper: 0,
      revenue_helper: 0,
      noncash_helper: 0,
      loan: 0
    },
    setHelpersData: setHelpersData
  }

  const [state, setState] = useState(initState)

  return (
    <NUContext.Provider value={state}>
      {props.children}
    </NUContext.Provider>
  )
}
