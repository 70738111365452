import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Draft from "../general/Draft";
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";
import DesMainTerminal from "./DesMainTerminal";

function DesTerminal() {

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <Container fluid className=" shadow pt-2">
      <Row>
        <Col>
          <DesMainTerminal />
        </Col>
        <Col xs={12}>
          <Draft />
        </Col>
      </Row>
    </Container>
  );
}

export default DesTerminal;
