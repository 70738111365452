import React, {useState, useEffect} from 'react';
import "bootstrap/dist/css/bootstrap.css";
import Draft from '../general/Draft';
import {Container, Row, Col, Tabs, Tab} from "react-bootstrap";
import MainTerminal from './MainTerminal';
import FotTerminal from './FotTerminal';
import FotStatement from '../general/FotStatement';
import TransitionPremium from './TransitionPremium'
import AmegaTerminal from './AmegaTerminal';

function Terminal(){

  const [tabState, setTabState] = useState('terminal-amega')

  useEffect(() => {
    return () => {}
  }, [])


  return(
    <Container fluid className=" shadow pt-2">
      <Row>
        <Col>
            <Tabs
            justify
            id="controlled-inner-tab"
            activeKey={tabState}
            onSelect={(k) => setTabState(k)}
            unmountOnExit={false}
            >   <Tab eventKey="terminal-amega" title="Амега">
                  <AmegaTerminal />
                </Tab>
                
                <Tab eventKey="terminal-main" title="Остальное">
                    <MainTerminal />
                </Tab>
                <Tab eventKey="terminal-transition-premium" title="Премия">
                    <TransitionPremium />
                </Tab>
                <Tab eventKey="terminal-statement" title="Вед. ФОТ">
                    <FotStatement />
                </Tab>
                {/* <Tab eventKey="terminal-fot" title="Поэтапки" disabled>
                    <FotTerminal />
                </Tab> */}
            </Tabs>
        </Col>
        <Col xs={12}>
          <Draft />
        </Col>
      </Row>
    </Container>
  )

}

export default Terminal;
