import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import {
  Row,
  Col,
  Button,
  Table,
  Modal,
  Form,
  Container,
  Spinner,
} from "react-bootstrap";
import ReactPaginate from "react-paginate";
import axios from "axios";
import cookies from "js-cookie";
import { SearchBox } from "../general/SearchBox";
import { CalcByClick } from "../general/CalcByClick";
import { MultiSelect } from "primereact/multiselect";

function AccountingHistory() {
  const historyUrl = "/redesk/accounting/history";
  const filterOptionsUrl = "/redesk/accounting/history/multiselect-options";

  const searchRef = React.createRef();
  const tableSearchRef = React.createRef();

  const [historyData, setHistoryData] = useState([]);

  const [loading, setLoading] = useState(false);

  const [historyModal, setHistoryModal] = useState(false);

  const [pageCount, setPageCount] = useState(0);

  const [currentPage, setCurrentPage] = useState(-1);

  const [searchString, setSearchString] = useState(null);

  const [searchRefValue, setSearchRefValue] = useState("");

  const [multiselectOptions, setMultiselectOptions] = useState({});

  const emptyFilters = {
    item_type: null,
    item: null,
    sub_item: null,
    metric_1: null,
    metric_4: null,
    metric_5: null,
    object_id: null,
    responsible_user: null,
    payment_type: null,
  };

  const [selectedFilters, setSelectedFilters] = useState(emptyFilters);

  const [CalcByClick_value, setCalcByClick_value] = useState(0);

  const inst_CalcByClick = new CalcByClick(
    setCalcByClick_value,
    "table.rd-accounting td"
  );

  const fetchHistory = async (currentPage) => {
    let paginationParams = {
      page: currentPage,
      per_page: 100,
      search_string: searchString,
      filters: selectedFilters,
    };

    let res = await axios.post(historyUrl, paginationParams, {
      headers: { "X-CSRF-TOKEN": cookies.get("csrf_access_token") },
    });
    let data = await res.data;
    return data;
  };

  const fetchMultiselectOptions = async () => {
    let res = await axios.get(filterOptionsUrl);
    setMultiselectOptions(res.data.options);
  };

  const handlePageClick = async (data) => {
    setCurrentPage(data.selected);

    let currentPage = data.selected;

    const historyItems = await fetchHistory(currentPage);

    setHistoryData(historyItems.history_data);
  };

  const handleMultiselectFilters = (filters, category) => {
    let sfCopy = { ...selectedFilters };
    sfCopy[category] = filters;
    setCurrentPage(0);
    setSelectedFilters(sfCopy);
  };

  useEffect(() => {
    let initialHistory = async () => {
      setLoading(true);

      fetchMultiselectOptions();

      setCurrentPage(0);

      let paginationParams = {
        page: currentPage,
        per_page: 100,
        search_string: searchString,
        filters: selectedFilters,
      };

      let res = await axios.post(historyUrl, paginationParams, {
        headers: { "X-CSRF-TOKEN": cookies.get("csrf_access_token") },
      });
      let data = await res.data;
      let total = data.total;
      setPageCount(Math.ceil(total / 100));
      setHistoryData(data.history_data);
      setLoading(false);
    };

    initialHistory();

    return () => {};
  }, [searchString, selectedFilters]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container fluid className="shadow pt-2">
      <Row className="pb-2">
        <Col lg={4} style={{ textAlign: "left" }}>
          <SearchBox
            value={searchRefValue}
            ref={searchRef}
            onSearchClick={() => {
              setSearchString(searchRef.current.value);
              setCurrentPage(0);
            }}
            onSearchInput={(e) => {
              setSearchRefValue(e.target.value);
            }}
            id="history_search"
            name="history_search"
            placeholder="Поиск"
          />
        </Col>

        <Col lg={2}>
          <Button
            variant="outline-primary"
            className="btn-with-icon pb-0"
            size="sm"
            onClick={() => {
              setSelectedFilters(emptyFilters);
              setSearchRefValue("");
              setSearchString("");
            }}
          >
            <span class="material-icons-sm">delete</span>
          </Button>
        </Col>
        <Col lg={2}></Col>
        <Col lg={2}></Col>

        <Col lg={2} style={{ textAlign: "right" }}>
          {/*<Button variant="outline-primary" size="sm" className='btn-with-icon pb-0' onClick={() => setHistoryModal(true)}>
            <span class="material-icons-sm">fullscreen</span>
          </Button>*/}
        </Col>
      </Row>

      <Row>
        <Col lg={12}>
          <Table
            striped
            bordered
            size="sm"
            className="rd-accounting"
            responsive
          >
            <thead class="align-middle">
              <tr>
                <th>Дата подтверждения</th>
                <th>
                  <MultiSelect
                    value={selectedFilters.cfo}
                    emptyFilterMessage="Ничего не найдено"
                    placeholder="ЦФО"
                    maxSelectedLabels={1}
                    options={multiselectOptions.cfo || {}}
                    optionLabel="name"
                    showSelectAll={true}
                    selectedItemsLabel={
                      selectedFilters.cfo
                        ? `${selectedFilters.cfo.length} Выбрано`
                        : ""
                    }
                    onChange={(e) => {
                      handleMultiselectFilters(e.value, "cfo");
                    }}
                    scrollHeight="350px"
                    className="small"
                    style={{ width: "100%" }}
                  />
                </th>
                <th>
                  <MultiSelect
                    value={selectedFilters.item_type}
                    emptyFilterMessage="Ничего не найдено"
                    placeholder="Тип статьи"
                    maxSelectedLabels={1}
                    options={multiselectOptions.item_type || {}}
                    optionLabel="name"
                    showSelectAll={true}
                    selectedItemsLabel={
                      selectedFilters.item_type
                        ? `${selectedFilters.item_type.length} Выбрано`
                        : ""
                    }
                    onChange={(e) => {
                      handleMultiselectFilters(e.value, "item_type");
                    }}
                    scrollHeight="350px"
                    className="small"
                    style={{ width: "100%" }}
                  />
                </th>
                <th>Сумма</th>
                <th>
                  <MultiSelect
                    filter
                    virtualScrollerOptions={
                      multiselectOptions.item ? { itemSize: 40 } : null
                    }
                    emptyFilterMessage="Ничего не найдено"
                    placeholder="Статья"
                    maxSelectedLabels={1}
                    options={multiselectOptions.item || {}}
                    optionLabel="name"
                    showSelectAll={true}
                    value={selectedFilters.item}
                    selectedItemsLabel={
                      selectedFilters.item
                        ? `${selectedFilters.item.length} Выбрано`
                        : ""
                    }
                    onChange={(e) => {
                      handleMultiselectFilters(e.value, "item");
                    }}
                    scrollHeight="350px"
                    className="small"
                    style={{ width: "100%" }}
                  />
                </th>
                <th>
                  <MultiSelect
                    filter
                    virtualScrollerOptions={
                      multiselectOptions.sub_item ? { itemSize: 40 } : null
                    }
                    emptyFilterMessage="Ничего не найдено"
                    placeholder="Подстатья"
                    maxSelectedLabels={1}
                    options={multiselectOptions.sub_item || {}}
                    optionLabel="name"
                    showSelectAll={true}
                    value={selectedFilters.sub_item}
                    selectedItemsLabel={
                      selectedFilters.sub_item
                        ? `${selectedFilters.sub_item.length} Выбрано`
                        : ""
                    }
                    onChange={(e) => {
                      handleMultiselectFilters(e.value, "sub_item");
                    }}
                    scrollHeight="350px"
                    className="small"
                    style={{ width: "100%" }}
                  />
                </th>
                <th>
                  <MultiSelect
                    filter
                    virtualScrollerOptions={
                      multiselectOptions.metric_1 ? { itemSize: 40 } : null
                    }
                    emptyFilterMessage="Ничего не найдено"
                    placeholder="М1"
                    maxSelectedLabels={1}
                    options={multiselectOptions.metric_1 || {}}
                    optionLabel="name"
                    showSelectAll={true}
                    value={selectedFilters.metric_1}
                    selectedItemsLabel={
                      selectedFilters.metric_1
                        ? `${selectedFilters.metric_1.length} Выбрано`
                        : ""
                    }
                    onChange={(e) => {
                      handleMultiselectFilters(e.value, "metric_1");
                    }}
                    scrollHeight="350px"
                    className="small"
                    style={{ width: "100%" }}
                  />
                </th>
                <th>
                  <MultiSelect
                    filter
                    virtualScrollerOptions={
                      multiselectOptions.metric_4 ? { itemSize: 40 } : null
                    }
                    emptyFilterMessage="Ничего не найдено"
                    placeholder="М4"
                    maxSelectedLabels={1}
                    options={multiselectOptions.metric_4 || {}}
                    optionLabel="name"
                    showSelectAll={true}
                    value={selectedFilters.metric_4}
                    selectedItemsLabel={
                      selectedFilters.metric_4
                        ? `${selectedFilters.metric_4.length} Выбрано`
                        : ""
                    }
                    onChange={(e) => {
                      handleMultiselectFilters(e.value, "metric_4");
                    }}
                    scrollHeight="350px"
                    className="small"
                    style={{ width: "100%" }}
                  />
                </th>
                <th>
                  <MultiSelect
                    filter
                    virtualScrollerOptions={
                      multiselectOptions.metric_5 ? { itemSize: 40 } : null
                    }
                    emptyFilterMessage="Ничего не найдено"
                    placeholder="Счет"
                    maxSelectedLabels={1}
                    options={multiselectOptions.metric_5 || {}}
                    optionLabel="name"
                    showSelectAll={true}
                    value={selectedFilters.metric_5}
                    selectedItemsLabel={
                      selectedFilters.metric_5
                        ? `${selectedFilters.metric_5.length} Выбрано`
                        : ""
                    }
                    onChange={(e) => {
                      handleMultiselectFilters(e.value, "metric_5");
                    }}
                    scrollHeight="350px"
                    className="small"
                    style={{ width: "100%" }}
                  />
                </th>
                <th>
                  <MultiSelect
                    filter
                    virtualScrollerOptions={
                      multiselectOptions.object_id ? { itemSize: 40 } : null
                    }
                    emptyFilterMessage="Ничего не найдено"
                    placeholder="ИД"
                    maxSelectedLabels={1}
                    options={multiselectOptions.object_id || {}}
                    optionLabel="name"
                    showSelectAll={true}
                    value={selectedFilters.object_id}
                    selectedItemsLabel={
                      selectedFilters.object_id
                        ? `${selectedFilters.object_id.length} Выбрано`
                        : ""
                    }
                    onChange={(e) => {
                      handleMultiselectFilters(e.value, "object_id");
                    }}
                    scrollHeight="350px"
                    className="small"
                    style={{ width: "100%" }}
                  />
                </th>
                <th>
                  <MultiSelect
                    filter
                    virtualScrollerOptions={
                      multiselectOptions.responsible_user
                        ? { itemSize: 40 }
                        : null
                    }
                    emptyFilterMessage="Ничего не найдено"
                    placeholder="Ответственный"
                    maxSelectedLabels={1}
                    options={multiselectOptions.responsible_user || {}}
                    optionLabel="name"
                    showSelectAll={true}
                    value={selectedFilters.responsible_user}
                    selectedItemsLabel={
                      selectedFilters.responsible_user
                        ? `${selectedFilters.responsible_user.length} Выбрано`
                        : ""
                    }
                    onChange={(e) => {
                      handleMultiselectFilters(e.value, "responsible_user");
                    }}
                    scrollHeight="350px"
                    className="small"
                    style={{ width: "100%" }}
                  />
                </th>
                <th>
                  <MultiSelect
                    emptyFilterMessage="Ничего не найдено"
                    placeholder="Тип платежа"
                    maxSelectedLabels={1}
                    options={multiselectOptions.payment_type || {}}
                    optionLabel="name"
                    showSelectAll={true}
                    value={selectedFilters.payment_type}
                    selectedItemsLabel={
                      selectedFilters.payment_type
                        ? `${selectedFilters.payment_type.length} Выбрано`
                        : ""
                    }
                    onChange={(e) => {
                      handleMultiselectFilters(e.value, "payment_type");
                    }}
                    scrollHeight="350px"
                    className="small"
                    style={{ width: "100%" }}
                  />
                </th>
              </tr>
            </thead>
            {historyData !== "no_result" && loading === false ? (
              <>
                <tbody>
                  {historyData.map((item) => (
                    <tr
                      class="align-middle"
                      key={item.uuid}
                      row-uuid={item.uuid}
                    >
                      <td>{item.confirmation_date}</td>
                      <td>{item.cfo}</td>
                      <td>{item.item_type}</td>
                      <td
                        className="sum-column"
                        data-calcbyclickvalue={item.sum}
                        onClick={inst_CalcByClick.onItemClick}
                      >
                        {window.redesk_app.common.fmtFinValue.format(item.sum)}
                      </td>
                      <td>{item.item}</td>
                      <td>{item.sub_item}</td>
                      <td>{item.metric_1}</td>
                      <td>{item.metric_4}</td>
                      <td>{item.metric_5}</td>
                      <td>{item.object_id}</td>
                      <td>{item.responsible_user}</td>
                      <td>{item.payment_type}</td>
                    </tr>
                  ))}
                </tbody>
              </>
            ) : historyData === "no_result" && loading === false ? (
              <td colSpan={11}>
                <h4
                  style={{
                    marginTop: "15%",
                    marginBottom: "10%",
                    textAlign: "center",
                  }}
                >
                  История пуста
                </h4>
              </td>
            ) : (
              <td colSpan={11} style={{ textAlign: "center" }}>
                <Spinner
                  style={{ marginTop: "15%", marginBottom: "10%" }}
                  animation="border"
                  variant="primary"
                />
              </td>
            )}
          </Table>
        </Col>
      </Row>
      <Row>
        <Col lg={2} className="align-self-center">
          Выбрано:{" "}
          <span onClick={inst_CalcByClick.onResultClick}>
            {window.redesk_app.common.fmtFinValue.format(CalcByClick_value)}
          </span>
        </Col>
        <Col lg={2} className="align-self-center"></Col>
        <Col lg={4}>
          {pageCount > 1 && loading === false ? (
            <ReactPaginate
              nextLabel={">>"}
              previousLabel={"<<"}
              breakLabel={"..."}
              pageCount={pageCount}
              pageRangeDisplayed={3}
              forcePage={currentPage}
              containerClassName={"pagination justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
              size="sm"
              onPageChange={handlePageClick}
            />
          ) : (
            <></>
          )}
        </Col>
        <Col lg={2} className="align-self-center"></Col>
        <Col lg={2} className="align-self-center"></Col>
      </Row>

      <Modal
        show={historyModal}
        fullscreen={true}
        onHide={() => setHistoryModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>История</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="pb-2">
            <Col lg={3}>
              <SearchBox
                ref={tableSearchRef}
                onSearchClick={() => {
                  setSearchString(tableSearchRef.current.value);
                }}
                onSearchInput={(e) => {
                  searchRef.current.value = e.target.value;
                }}
                id="history_search"
                name="history_search"
                placeholder="Поиск"
              />
            </Col>
            <Col lg={3}></Col>
            <Col lg={6}></Col>
          </Row>
          {historyData !== "no_result" && loading === false ? (
            <Row>
              <Col lg={12}>
                <Table
                  striped
                  bordered
                  size="sm"
                  className="rd-accounting"
                  responsive
                >
                  <thead class="align-middle">
                    <tr>
                      <th>Дата создания</th>
                      <th>Дата подтверждения</th>
                      <th>Тип статьи</th>
                      <th>Сумма</th>
                      <th>Статья</th>
                      <th>Подстатья</th>
                      <th>Метрика 1</th>
                      <th>ИД</th>
                      <th>Метрика 4</th>
                      <th>Метрика 5</th>
                      <th>Ответственный</th>
                      <th>Примечание</th>
                      <th>Тип платежа</th>
                    </tr>
                  </thead>
                  <tbody>
                    {historyData.map((item) => (
                      <tr
                        class="align-middle"
                        key={item.uuid}
                        row-uuid={item.uuid}
                      >
                        <td>{item.creation_date}</td>
                        <td>{item.confirmation_date}</td>
                        <td>{item.item_type}</td>
                        <td className="sum-column">
                          {window.redesk_app.common.fmtFinValue.format(
                            item.sum
                          )}
                        </td>
                        <td>{item.item}</td>
                        <td>{item.sub_item}</td>
                        <td>{item.metric_1}</td>
                        <td>{item.object_id}</td>
                        <td>{item.metric_4}</td>
                        <td>{item.metric_5}</td>
                        <td>{item.responsible_user}</td>
                        <td>{item.comment}</td>
                        <td>{item.payment_type}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          ) : historyData === "no_result" && loading === false ? (
            <Row>
              <Col sm={12} style={{ textAlign: "center" }}>
                <h4 style={{ marginTop: "15%", marginBottom: "15%" }}>
                  История пуста
                </h4>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col sm={12} style={{ textAlign: "center" }}>
                <div style={{ marginTop: "15%", marginBottom: "15%" }}>
                  <Spinner animation="border" variant="primary" />
                </div>
              </Col>
            </Row>
          )}
        </Modal.Body>
        {historyData !== "no_result" && loading === false ? (
          <Modal.Footer className="justify-content-center">
            <ReactPaginate
              nextLabel={">>"}
              previousLabel={"<<"}
              breakLabel={"..."}
              pageCount={pageCount}
              pageRangeDisplayed={3}
              forcePage={currentPage}
              containerClassName={"pagination justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
              onPageChange={handlePageClick}
            />
          </Modal.Footer>
        ) : (
          <Modal.Footer className="justify-content-center"></Modal.Footer>
        )}
      </Modal>
    </Container>
  );
}

export default AccountingHistory;
