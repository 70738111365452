import React, { useState, useContext } from 'react';
import "bootstrap/dist/css/bootstrap.css";
import ManagementTerminal from './ManagementTerminal';
import IncomingItems from '../general/IncomingItems';
import {Tabs, Tab} from "react-bootstrap";
import ManagementHistory from './ManagementHistory';
import { UserContext } from '../UserContextManager';
import ManagementRemainders from './ManagementRemainders';

function ManagementNav() {

  const uContext = useContext(UserContext);

  const [state, setState] = useState('terminal');

  return(
    <Tabs
      justify
      id="controlled-tab-example"
      activeKey={state}
      onSelect={(k) => setState(k)}
      //className="mb-3"
      unmountOnExit={true}
    >
      <Tab eventKey="terminal" title="Терминал" unmountOnExit={false}>
        <ManagementTerminal />
      </Tab>
      <Tab eventKey="profile" title="Переброски">
        <IncomingItems />
      </Tab>
      <Tab eventKey="contact" title="История">
        <ManagementHistory />
      </Tab>
      {uContext.userData.role.split(';').includes('MM') ? (
        <Tab eventKey="mr" title="Остатки">
          <ManagementRemainders />
        </Tab>
      ) : (<></>)}
    </Tabs>
  )

}

export default ManagementNav
