import React from 'react'
import { Navigate } from 'react-router-dom';
import {useState, useEffect} from 'react';
import {Card, Row, Col, Container, ToggleButtonGroup, ToggleButton} from "react-bootstrap";
import LoginSignUp from './SignUp';
import LoginSignIn from './SignIn';
import cookies from 'js-cookie';

function Login(){

  const [sisu, setSisu] = useState('login-signIn')

  useEffect(() => {
    return () => {}
  }, [sisu]); // eslint-disable-line react-hooks/exhaustive-deps


  if (cookies.get("csrf_access_token")){
    return(<Navigate to="/" />)
  }else{
    return(
      <Container fluid>
        <Row className="justify-content-center" style={{'marginTop': '150px'}}>
          <Col xs={12} sm={10} lg={3}>
            <Card>
              <Card.Header as="h5" style={{textAlign: "center"}}>
                <ToggleButtonGroup
                  type="radio"
                  name="signIn-signUp"
                  defaultValue='login-signIn'
                  className="mb-3 mt-2 mx-auto"
                  size="sm"
                  onChange = {(val) => {setSisu(val)}}
                >
                  <ToggleButton id='tgbtn-signIn'  variant='outline-secondary' value='login-signIn'>
                    Вход
                  </ToggleButton>
                  <ToggleButton id='tgbtn-signUp'  variant='outline-secondary' value='login-signUp'>
                    Регистрация
                  </ToggleButton>
                </ToggleButtonGroup>
              </Card.Header>
              <Card.Body>
                {sisu === 'login-signUp' ? (
                  <LoginSignUp />
                ): (
                  <LoginSignIn />
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default Login;
