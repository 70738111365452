import React, { useState } from 'react'

export const NuSpbContext = React.createContext({
  helpersData: {
    foreman: '',
    revenue: 0,
    address: ''
  },
  setHelpersData: () => {},
})

export const NuSpbContextProvider = (props) => {
  const setHelpersData = (helpersData) => {
    setState({...state, helpersData: helpersData})
  }


  const initState = {
    helpersData: {
      foreman: '',
      revenue: 0,
      address: ''
    },
    setHelpersData: setHelpersData
  }

  const [state, setState] = useState(initState)

  return (
    <NuSpbContext.Provider value={state}>
      {props.children}
    </NuSpbContext.Provider>
  )
}
