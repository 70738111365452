import React, { useState, useEffect, useContext } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Row, Col, Button, Table, Modal, Form } from "react-bootstrap";
import { UserContext } from "../UserContextManager";
import axios from "axios";
import cookies from "js-cookie";
import NumberFormat from "react-number-format";
import { AccountingContext } from "../AccountingContextManager";
import { CCContext } from "../CCContextManager";
import { DesignContext } from "../DesignContextManager";
import { DKContext } from "../DKContextManager";
import { FundContext } from "../FundContextManager";
import { ManagementContext } from "../ManagementHelpersManager";
import { MarketingContext } from "../MarketingContextManager";
import { MechContext } from "../MechContextManager";
import { NPContext } from "../NPContextManager";
import { NsdoContext } from "../NsdoContextManager";
import { NUContext } from "../NUHelpersManager";
import { OPContext } from "../OPContextManager";
import { SMPContext } from "../SMPContextManager";
import { ManagementSpbContext } from "../ManagementSpbContextManager";
import { CalcByClick } from "../general/CalcByClick";
import { OutlaysBlock } from "./OutlaysBlock";

function Draft(props) {
  const uContext = useContext(UserContext);

  const aContext = useContext(AccountingContext);
  const cContext = useContext(CCContext);
  const dContext = useContext(DesignContext);
  const dkContext = useContext(DKContext);
  const fContext = useContext(FundContext);
  const mContext = useContext(ManagementContext);
  const marketingContext = useContext(MarketingContext);
  const mechContext = useContext(MechContext);
  const npContext = useContext(NPContext);
  const nsdoContext = useContext(NsdoContext);
  //const nuContext = useContext(NUContext);
  const opContext = useContext(OPContext);
  const smpContext = useContext(SMPContext);
  const managementSpbContext = useContext(ManagementSpbContext);

  const contextDict = {
    accounting: aContext,
    cc: cContext,
    design: dContext,
    dk: dkContext,
    fund: fContext,
    management: mContext,
    marketing: marketingContext,
    mech: mechContext,
    np: npContext,
    nsdo: nsdoContext,
    op: opContext,
    smp: smpContext,
    management_spb: managementSpbContext,
  };

  const [draftData, setDraftData] = useState([]);

  const [modalDraft, setModalDraft] = useState({});
  const [show, setShow] = useState(false);
  const [fsModal, setFsModal] = useState(false);

  const [CalcByClick_value, setCalcByClick_value] = useState(0);
  const inst_CalcByClick = new CalcByClick(
    setCalcByClick_value,
    "table.rd-accounting td"
  );

  const handleClose = () => setShow(false);

  const handleShow = (e) => {
    if (!e.ctrlKey & !e.metaKey) {
      setModalDraft(
        draftData.find((element) => {
          return element.uuid === e.currentTarget.getAttribute("row-uuid");
        })
      );
      setShow(true);
    }
  };

  const showWholeTable = () => {
    setFsModal(true);
  };

  const updateDraftData = (newData) => {
    let oldContext = uContext.userData;
    oldContext.draft_data = newData.draft_data;
    uContext.setUserData(oldContext);
  };

  const loadUserData = async () => {
    let res = await axios.get("/redesk/general/remainder");
    res.data.user_data["draft_data"] = uContext.userData.draft_data;
    uContext.setUserData(res.data.user_data);
  };

  const loadHelpers = async () => {
    let currentDesk = window.location.pathname.replace("/", "");

    if (!(currentDesk in contextDict)) {
      return;
    }

    let url = `/redesk/${currentDesk}/helpers`;
    let res = await axios.get(url);
    contextDict[currentDesk].setHelpersData(res.data.helpers_data);
  };

  const deleteDraft = async (e) => {
    e.preventDefault();
    let res = await axios.post(
      "/redesk/draft/delete",
      [e.currentTarget.getAttribute("row-uuid")],
      { headers: { "X-CSRF-TOKEN": cookies.get("csrf_access_token") } }
    );
    updateDraftData(res.data);
    setShow(false);
  };

  const confirmDraft = async (e) => {
    e.preventDefault();

    let currentDesk = window.location.pathname;

    try {
      let res = await axios.post(
        "/redesk/draft/confirm",
        {
          current_desk: currentDesk,
          draft_data: [e.currentTarget.getAttribute("row-uuid")],
        },
        { headers: { "X-CSRF-TOKEN": cookies.get("csrf_access_token") } }
      );

      updateDraftData(res.data);
      setShow(false);
      loadUserData();
      loadHelpers();
      if (currentDesk == "/nu") {
        await OutlaysBlock([modalDraft]);
      }
    } catch (error) {
      setDraftData([]);
    }
  };

  const handleCheckBox = (e) => {
    let ddCopy = [...draftData];
    let oi = ddCopy.findIndex((x) => x.uuid === e.target.id);

    if (e.target.checked) {
      ddCopy[oi].checked = true;
    } else {
      ddCopy[oi].checked = false;
    }

    setDraftData(ddCopy);
  };

  const deleteDraftBatch = async () => {
    let ddCopy = [...draftData];
    let deleteBatch = [];

    ddCopy.forEach(function (x) {
      if (x.checked) {
        deleteBatch.push(x.uuid);
      }
    });

    if (deleteBatch.length < 1) {
      return null;
    } else {
      let res = await axios.post("/redesk/draft/delete", deleteBatch, {
        headers: { "X-CSRF-TOKEN": cookies.get("csrf_access_token") },
      });
      updateDraftData(res.data);
      setFsModal(false);
    }
  };

  const confirmDraftBatch = async () => {
    let ddCopy = [...draftData];
    let confirmBatch = [];
    let currentDesk = window.location.pathname;

    let collection = []

    ddCopy.forEach(function (x) {
      if (x.checked) {
        confirmBatch.push(x.uuid);
        collection = [...collection, ...draftData.filter(row => row.uuid == x.uuid)]
      }
    });

    if (confirmBatch.length < 1) {
      return null;
    } else {
      try {
        let res = await axios.post(
          "/redesk/draft/confirm",
          {
            current_desk: currentDesk,
            draft_data: confirmBatch,
          },
          {
            headers: { "X-CSRF-TOKEN": cookies.get("csrf_access_token") },
          }
        );

        updateDraftData(res.data);
        setFsModal(false);
        loadUserData();
        loadHelpers();

        if (currentDesk == "/nu") {
          await OutlaysBlock(collection);
        }
      } catch (error) {
        setDraftData([]);
      }
    }
  };

  const handleSelectAll = () => {
    let checkedArray = draftData.filter(function (x) {
      return x.checked === true;
    });

    let dd;
    if (checkedArray.length > 0) {
      dd = [...draftData].map((x) => ({ ...x, checked: false }));
    } else {
      dd = [...draftData].map((x) => ({ ...x, checked: true }));
    }

    setDraftData(dd);
  };

  useEffect(() => {
    setDraftData(uContext.userData.draft_data);
    return () => {};
  }, [uContext.userData.draft_data]);

  if (draftData.length > 0) {
    return (
      <React.Fragment>
        <Row>
          <Col lg={11} className="mb-2 d-flex justify-content-left nu-dr-info">
            <strong>В черновике:&nbsp;</strong>
            <NumberFormat
              value={uContext.userData.draft_data
                .reduce((a, o) => {
                  return a + o.sum;
                }, 0)
                .toFixed(2)}
              displayType={"text"}
              thousandSeparator={" "}
              decimalSeparator={"."}
              suffix={" ₽"}
            />
            <p>&emsp;</p>
            <strong>В черновике (нал):&nbsp;</strong>
            <NumberFormat
              value={uContext.userData.draft_data
                .filter((item) => item.payment_type === "Нал")
                .reduce((a, o) => {
                  return a + o.sum;
                }, 0)
                .toFixed(2)}
              displayType={"text"}
              thousandSeparator={" "}
              decimalSeparator={"."}
              suffix={" ₽"}
            />
            <p>&emsp;</p>
            <strong>Выбрано:&nbsp;</strong>
            <span onClick={inst_CalcByClick.onResultClick}>
              {window.redesk_app.common.fmtFinValue.format(CalcByClick_value)}
            </span>
            <span>&nbsp;₽</span>
          </Col>

          <Col lg={1} style={{ textAlign: "right" }}>
            <Button
              variant="outline-primary"
              size="sm"
              className="mb-1 mt-2 mx-auto pb-0"
              onClick={showWholeTable}
            >
              <span class="material-icons">fullscreen</span>
            </Button>
          </Col>
          <Col sm={12}>
            <Table
              striped
              bordered
              hover
              size="sm"
              responsive
              className="rd-accounting"
            >
              <thead class="align-middle">
                <tr>
                  <th>Создано</th>
                  <th>Сумма</th>
                  <th>Статья</th>
                  <th>Подстатья</th>
                  <th>Метрика 1</th>
                  <th>Ид</th>
                  <th>Метрика 4</th>
                  <th>Ответственный</th>
                  <th>Примечание</th>
                  <th>Тип платежа</th>
                </tr>
              </thead>

              <tbody>
                {draftData.map((item) => (
                  <tr
                    class="align-middle"
                    key={item.uuid}
                    row-uuid={item.uuid}
                    onClick={handleShow}
                    style={{ cursor: "pointer" }}
                  >
                    <td>{item.draft_date}</td>
                    <td
                      className="col-value"
                      data-calcbyclickvalue={item.sum}
                      onClick={inst_CalcByClick.onItemClick}
                    >
                      {window.redesk_app.common.fmtFinValue.format(item.sum)}
                    </td>
                    <td>{item.item}</td>
                    <td>{item.sub_item}</td>
                    <td>{item.work_type}</td>
                    <td>{item.object_id}</td>
                    <td>{item.metric_4}</td>
                    <td>{item.responsible_user}</td>
                    <td>{item.comment}</td>
                    <td>{item.payment_type}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Черновик {modalDraft.object_id}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <strong>Дата содания:</strong> {modalDraft.draft_date}
            </p>
            <p>
              <strong>Ид объекта:</strong> {modalDraft.object_id}
            </p>
            <p>
              <strong>ЦФО:</strong> {modalDraft.cfo}
            </p>
            <p>
              <strong>Ответственный:</strong> {modalDraft.responsible_user}
            </p>
            <p>
              <strong>Тип статьи:</strong> {modalDraft.item_type}
            </p>
            <p>
              <strong>Статья:</strong> {modalDraft.item}
            </p>
            <p>
              <strong>Сумма:</strong>{" "}
              {window.redesk_app.common.fmtFinValue.format(modalDraft.sum)}
            </p>
            <p>
              <strong>Тип платежа:</strong> {modalDraft.payment_type}
            </p>
            <p>
              <strong>Метрика 1:</strong> {modalDraft.work_type}
            </p>
            <p>
              <strong>Метрика 4:</strong> {modalDraft.metric_4}
            </p>
            <p>
              <strong>Метрика 5:</strong> {modalDraft.metric_5}
            </p>
            <p>
              <strong>Подстатья:</strong> {modalDraft.sub_item}
            </p>
            <p>
              <strong>Юрлицо:</strong> {modalDraft.company_name}
            </p>
            <p>
              <strong>Примечание:</strong> {modalDraft.comment}
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              row-uuid={modalDraft.uuid}
              variant="danger"
              onClick={deleteDraft}
            >
              Удалить
            </Button>
            <Button
              row-uuid={modalDraft.uuid}
              variant="primary"
              onClick={confirmDraft}
            >
              Подтвердить
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={fsModal}
          fullscreen={true}
          onHide={() => setFsModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Черновик</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table
              striped
              bordered
              size="sm"
              responsive
              className="rd-accounting"
            >
              <thead class="align-middle">
                <tr>
                  <th>Создано</th>
                  <th>Ид</th>
                  <th>Ответственный</th>
                  <th>Тип статьи</th>
                  <th>Сумма</th>
                  <th>Статья</th>
                  <th>Подстатья</th>
                  <th>Метрика 1</th>
                  <th>Метрика 4</th>
                  <th>Метрика 5</th>
                  <th>Юрлицо</th>
                  <th>Создал</th>
                  <th>Примечание</th>
                  <th>Тип платежа</th>
                  <th>
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={handleSelectAll}
                    >
                      {" "}
                      ✓{" "}
                    </Button>
                  </th>
                </tr>
              </thead>
              <tbody>
                {draftData.map((item) => (
                  <tr class="align-middle" key={item.uuid} row-uuid={item.uuid}>
                    <td>{item.draft_date}</td>
                    <td>{item.object_id}</td>
                    <td>{item.responsible_user}</td>
                    <td>{item.item_type}</td>
                    <td className="col-value">
                      {window.redesk_app.common.fmtFinValue.format(item.sum)}
                    </td>
                    <td>{item.item}</td>
                    <td>{item.sub_item}</td>
                    <td>{item.work_type}</td>
                    <td>{item.metric_4}</td>
                    <td>{item.metric_5}</td>
                    <td>{item.company_name}</td>
                    <td>{item.draft_by}</td>
                    <td>{item.comment}</td>
                    <td>{item.payment_type}</td>
                    <td className="col-centred">
                      <Form.Check
                        type="checkbox"
                        id={item.uuid}
                        key={item.uuid}
                        checked={item.checked}
                        onChange={handleCheckBox}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={deleteDraftBatch}>
              Удалить
            </Button>
            <Button variant="primary" onClick={confirmDraftBatch}>
              Подтвердить
            </Button>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Button variant="secondary" onClick={() => setFsModal(false)}>
              Закрыть
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  } else {
    return null;
  }
}

export default Draft;
