import React, {useEffect, useContext, useState} from 'react'
import "bootstrap/dist/css/bootstrap.css";
import {Container, Row, Col, Button} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Logout from '../login/Logout';
import DesignNav from './DesignNav';
import RemainderCard from '../general/RemainderCard';
import { UserContext } from '../UserContextManager';
import { DesignContext } from '../DesignContextManager';
import UserInfo from '../general/UserInfo';
import axios from 'axios';
import { DeskChangeBtn } from '../general/DeskChangeBtn';

function DesignDesk(){

  const uContext = useContext(UserContext);
  const dContext = useContext(DesignContext);

  const navigate = useNavigate();

  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1);

  const loadUserData = async () => {

    let userDataRes = await axios.get('/redesk/general/remainder');

    let data = await userDataRes.data;

    let draftRes = await axios.get('/redesk/draft');

    let draftData = await draftRes.data;

    data.user_data['draft_data'] = draftData.draft_data;

    uContext.setUserData(data.user_data);

    let roles = data.user_data.role.split(';')
    if (roles.includes('design')){
      navigate('/design')    
    }else{
      navigate('/');
    }
  }

  const loadDesignHelpers = async (emonth=month, eyear=year) => {

    let res = await axios.get('/redesk/design/helpers');
    dContext.setHelpersData(res.data.helpers_data);
    
  }

  useEffect(() => {
    loadUserData();
    loadDesignHelpers();
    return () => {}
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container fluid>
      <Row className="justify-content-center" style={{'marginTop': '15px'}}>
        <Col xs={6} style={{textAlign: "left"}}>
          <UserInfo />
        </Col>
        <Col xs={6} style={{textAlign: "right"}}>
          {uContext.userData.role.split(';').length > 1 ? 
          (<DeskChangeBtn />) : (<></>)
          }
          <Logout />
        </Col>
      </Row>
      <Row className="justify-content-center mb-5" style={{'marginTop': '100px'}}>
      <Col sm={12} lg={2}>
        <RemainderCard
          bgColor='light'
          keyProp='cash'
          textColor='black'
          title='Нал'
          value={dContext.helpersData.remainder.toFixed(2)}
          tooltipText='Сумма наличных'
        />
        <RemainderCard
            bgColor='light'
            keyProp='outcoming_reas_remainder'
            textColor='black'
            title='Исходящие переброски'
            value={dContext.helpersData.outcoming.toFixed(2)}
            tooltipText='Сумма всех исходящих наличных неподтвержденных перебросок'
            tooltipPlacement='right'
          />
          <RemainderCard
            bgColor='light'
            keyProp='incoming_reas_remainder'
            textColor='black'
            title='Входящие переброски'
            value={dContext.helpersData.incoming.toFixed(2)}
            tooltipText='Сумма всех входящих наличных неподтвержденных перебросок'
            tooltipPlacement='right'
          />
      </Col>
        <Col sm={12} lg={8} className='p-0 mb-5'>
          <DesignNav />
        </Col>
        <Col sm={12} lg={2}>
        </Col>
      </Row>
    </Container>
  );
}

export default DesignDesk;
