import React, { useState, useEffect, useContext } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Row, Col, Button, Stack, Form, Modal } from "react-bootstrap";
import { UserContext } from "../UserContextManager";
import { DesContext } from "../DesContextManager";
import { Typeahead, Highlighter } from "react-bootstrap-typeahead";
import axios from "axios";
import cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import CurrencyInput from "react-currency-input-field";

function DesMainTerminal() {
  const sourceRef = React.createRef();

  const uContext = useContext(UserContext);
  const dContext = useContext(DesContext);
  const navigate = useNavigate();

  const [dropdownsData, setDropdownsData] = useState([]);
  const [responsibles, setResponsibles] = useState([]);
  const [sourceData, setSourceData] = useState([]);
  const [formData, updateFormData] = useState({
    terminal_payment_type: "Нал",
    terminal_item_type: "Расход",
  });

  const [cfo, setCfo] = useState("ЦФО");

  const [itemType, setItemType] = useState("Расход");
  const [item, setItem] = useState("Статья");
  const [subItem, setSubItem] = useState("Подстатья");
  const [paymentType, setPaymentType] = useState("Нал");

  const [selectedObject, setSelectedObject] = useState(null);

  const [masterData, setMasterData] = useState([]);

  const [objectsList, setObjectsList] = useState([]);

  const loadMasterData = async () => {
    let res = await axios.get(`/redesk/des/masters`);
    setMasterData(res.data.master_data);
  };

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmModalText, setConfirmModalText] = useState("");

  const [sum, setSum] = useState("");

  const loadDesDropdowns = async () => {
    try {
      let dropdownsRes = await axios.get("/redesk/des/dropdowns", {});

      setDropdownsData(dropdownsRes.data.dropdowns);

      let responsiblesFiltered = dropdownsRes.data.dropdowns.filter(
        (x) => x.dropdown_name == "des_responsible"
      );
      let responsiblesList = responsiblesFiltered.map((x) => x.value);
      setResponsibles(responsiblesList);
    } catch (error) {
      console.log(error);
    }
  };

  const loadDesHelpers = async (objectId) => {
    let conCopy = dContext.helpersData;

    if (objectsList.includes(objectId)) {
      let res = await axios.get(`/redesk/des/helpers/${objectId}`);

      conCopy.main_helper = res.data.helpers_data.main_helper;
      conCopy.np_helper = res.data.helpers_data.np_helper;
      conCopy.output_helper = res.data.helpers_data.output_helper;
      conCopy.output_all_helper = res.data.helpers_data.output_all_helper;
    } else {
      conCopy.main_helper = 0;
      conCopy.np_helper = 0;
      conCopy.output_helper = 0;
      conCopy.output_all_helper = 0;
    }

    dContext.setHelpersData(conCopy);
  };

  const loadObjectsList = async () => {
    let res = await axios.get("/redesk/des/objects");
    setObjectsList(res.data.objects_data);
  };

  const handleItemType = (e) => {
    let formDataCopy = { ...formData };
    delete formDataCopy.terminal_item;
    formDataCopy.terminal_item_type = e;

    setItem("Статья");
    setItemType(e);
    updateFormData(formDataCopy);
  };

  const handleItem = (e) => {
    let formDataCopy = { ...formData };
    delete formDataCopy.terminal_sub_item;
    formDataCopy.terminal_item = e;

    setSubItem("Подстатья");
    setItem(e);
    updateFormData(formDataCopy);
  };

  const handlePaymentType = (e) => {
    let formDataCopy = { ...formData };
    formDataCopy.terminal_payment_type = e;

    setPaymentType(e);
    updateFormData(formDataCopy);
  };

  const compareDraft = () => {
    let draftCopy = [...uContext.userData.draft_data];

    let has_duplicate = false;
    draftCopy.forEach((x) => {
      if (
        Math.abs(x.sum) === Number(formData.terminal_sum) &&
        x.responsible_user === formData.terminal_responsible_user &&
        x.item === formData.terminal_item &&
        x.object_id == selectedObject
      ) {
        has_duplicate = true;
      }
    });

    return has_duplicate;
  };

  const handleChange = (e, k = null) => {
    let newFormData = {};

    if (k !== null) {
      newFormData = { ...formData, [k]: e };
    } else {
      newFormData = { ...formData, [e.target.name]: e.target.value };
    }

    updateFormData(newFormData);
  };

  const handleSubmitWithConfirm = (e) => {
    if (cfo === "ЦФО") {
      alert("Укажите ЦФО!");
      return;
    }

    if (!formData.terminal_item) {
      alert("Статья не указана!");
      return;
    }

    if (!objectsList.includes(selectedObject)) {
      alert("ИД объекта указан неправильно!");
      return;
    }

    if (!formData.terminal_sum || formData.terminal_sum <= 0) {
      alert("Сумма не указана!");
      return;
    }

    if (!formData.terminal_work_type) {
      alert("Вид работ не указан!");
      return;
    }

    if (
      formData.terminal_metric_4 &&
      !masterData.includes(formData.terminal_metric_4)
    ) {
      alert("Мастер указан неверно!");
      return;
    }

    if (!formData.terminal_responsible_user) {
      alert("Ответственный не указан!");
      return;
    }

    if (formData.terminal_sum >= 1000000 && compareDraft()) {
      setConfirmModalText(
        `Похожая запись уже есть в черновике, а указанная сумма больше миллиона (${formData.terminal_sum}). Вы уверены?`
      );
      setShowConfirmModal(true);
      return;
    }

    if (formData.terminal_sum >= 1000000) {
      setConfirmModalText(
        `Указанная сумма больше миллиона (${formData.terminal_sum}). Вы уверены?`
      );
      setShowConfirmModal(true);
      return;
    }

    if (compareDraft()) {
      setConfirmModalText(
        "Похожая запись уже есть в черновике. Создать еще одну?"
      );
      setShowConfirmModal(true);
      return;
    }

    handleSubmit(e);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let draftSendData = formData;
    draftSendData["cfo"] = cfo;
    draftSendData["desk_owner"] = "ДЭС";

    if (selectedObject) {
      draftSendData["terminal_object_id"] = selectedObject;
    }

    if (
      draftSendData.terminal_sub_item &&
      draftSendData.terminal_sub_item === "Подстатья"
    ) {
      delete draftSendData["terminal_sub_item"];
    }

    let res = await axios.post("/redesk/draft/send", draftSendData, {
      headers: { "X-CSRF-TOKEN": cookies.get("csrf_access_token") },
    });
    let contextData = uContext.userData;
    contextData.draft_data = res.data.draft_data;
    uContext.setUserData(contextData);
  };

  useEffect(() => {
    loadDesDropdowns();
    loadMasterData();
    loadObjectsList();
    return () => {};
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Form id="terminal_main_form" className="mt-2">
      <Row>
        <Col xs={4}>
          <Form.Group className="mb-3" controlId="t_с">
            <Form.Label>ЦФО</Form.Label>
            <Form.Select
              size="sm"
              name="terminal_сащ"
              aria-label="terminal_сащ"
              value={cfo}
              onChange={(e) => setCfo(e.target.value)}
            >
              <option key="ЦФО" value="ЦФО" disabled>
                ЦФО
              </option>
              {dropdownsData.map(({ value, dropdown_name, spec }, index) => {
                if (dropdown_name === "des_cfo") {
                  return (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  );
                }
                return null;
              })}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs={4}>
          <Form.Group className="mb-3" controlId="t_i_t">
            <Form.Label>Тип статьи</Form.Label>
            <Form.Select
              size="sm"
              name="terminal_item_type"
              value={itemType}
              aria-label="terminal_item_type"
              onChange={(e) => {
                handleItemType(e.target.value);
              }}
            >
              <option key="Приход" value="Приход">
                Приход
              </option>
              <option key="Расход" value="Расход">
                Расход
              </option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col xs={4}>
          <Form.Group className="mb-3" controlId="t_i">
            <Form.Label>Статья</Form.Label>
            <Form.Select
              size="sm"
              name="terminal_item"
              value={item}
              aria-label="terminal_item"
              onChange={(e) => {
                handleItem(e.target.value);
              }}
            >
              <option key="Статья" disabled>
                Статья
              </option>
              {dropdownsData.map(({ value, dropdown_name, spec }, index) => {
                if (dropdown_name === "des_item" && spec === itemType) {
                  return (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  );
                }
                return null;
              })}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col xs={4}>
          <Form.Group className="mb-3" controlId="t_m">
            <Form.Label>Подстатья</Form.Label>
            <Form.Select
              size="sm"
              name="terminal_sub_item"
              value={subItem}
              aria-label="terminal_sub_item"
              onChange={(e) => {
                handleChange(e);
                setSubItem(e.target.value);
              }}
            >
              <option key="Подстатья">Подстатья</option>
              {item === "ФОТ"
                ? Array.from({ length: 12 }, (_, i) => i + 1).map(
                    (value, index) => {
                      return (
                        <option key={value} value={value}>
                          {value}
                        </option>
                      );
                    }
                  )
                : dropdownsData.map(({ value, dropdown_name }, index) => {
                    if (dropdown_name === "des_sub_item") {
                      return (
                        <option key={value} value={value}>
                          {value}
                        </option>
                      );
                    }
                    return null;
                  })}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs={4}>
          <Form.Group className="mb-3" controlId="t_o_id">
            <Form.Label>ИД объекта</Form.Label>
            <Typeahead
              id="terminal_object_id"
              name="terminal_object_id"
              placeholder="ИД объекта"
              maxResults={7}
              emptyLabel="Нет совпадений"
              paginationText="Показать больше"
              highlightClassName="font-weight-bold"
              onInputChange={(text) => {
                setSelectedObject(text);
                loadDesHelpers(text)
              }}
              onChange={(selected) => {
                setSelectedObject(selected[0]);
                loadDesHelpers(selected[0])
              }}
              options={objectsList}
              size="sm"
              renderMenuItemChildren={(option, props, idx) => (
                <Highlighter
                  search={props.text}
                  highlightClassName="fw-bold mx-0 px-0 bg-transparent"
                >
                  {option}
                </Highlighter>
              )}
            />
          </Form.Group>
        </Col>
        <Col xs={4}>
          <Form.Group className="mb-3" controlId="t_s">
            <Form.Label>Сумма</Form.Label>
            <CurrencyInput
              autoComplete="off"
              id="terminal_sum"
              name="terminal_sum"
              className="form-control"
              style={{ maxHeight: "30px" }}
              value={sum}
              placeholder="Сумма"
              decimalsLimit={2}
              allowNegativeValue={false}
              groupSeparator={" "}
              onValueChange={(e) => {
                setSum(e);
                handleChange(e, "terminal_sum");
              }}
            />
          </Form.Group>
        </Col>
        <Col xs={4}>
          <Form.Group className="mb-3" controlId="t_p_t">
            <Form.Label>Тип платежа</Form.Label>
            <Form.Select
              size="sm"
              name="terminal_payment_type"
              value={paymentType}
              aria-label="terminal_payment_type"
              onChange={(e) => {
                handlePaymentType(e.target.value);
              }}
            >
              <option key="Безнал" value="Безнал">
                Безнал
              </option>
              <option key="Нал" value="Нал">
                Нал
              </option>
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs={4}>
          <Form.Group className="mb-3" controlId="t_o">
            <Form.Label>Вид работ</Form.Label>
            <Form.Select
              size="sm"
              name="terminal_work_type"
              defaultValue="Вид работ"
              aria-label="terminal_work_type"
              onChange={handleChange}
            >
              <option key="Вид работ" value="Вид работ" disabled>
                Вид работ
              </option>
              {dropdownsData.map(({ value, dropdown_name }, index) => {
                if (dropdown_name === "des_work_type") {
                  return (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  );
                }
                return null;
              })}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col xs={4}>
          <Form.Group className="mb-3" controlId="t_o_id">
            <Form.Label>Мастер</Form.Label>
            <Typeahead
              id="terminal_metric_4"
              name="terminal_metric_4"
              placeholder="Мастер"
              maxResults={7}
              emptyLabel="Нет совпадений"
              paginationText="Показать больше"
              highlightClassName="font-weight-bold"
              onInputChange={(text) => {
                handleChange(text, "terminal_metric_4");
              }}
              onChange={(selected) => {
                handleChange(selected[0], "terminal_metric_4");
              }}
              options={masterData}
              size="sm"
              renderMenuItemChildren={(option, props, idx) => (
                <Highlighter
                  search={props.text}
                  highlightClassName="fw-bold mx-0 px-0 bg-transparent"
                >
                  {option}
                </Highlighter>
              )}
            />
          </Form.Group>
        </Col>
        <Col xs={4}>
          <Form.Group className="mb-3" controlId="t_r_u">
            <Form.Label>Ответственный</Form.Label>
            <Form.Select
              size="sm"
              name="terminal_responsible_user"
              defaultValue="Ответственный"
              aria-label="terminal_responsible_user"
              onChange={handleChange}
            >
              <option key="Ответственный" value="Ответственный">
                Ответственный
              </option>
              {dropdownsData.map(({ value, dropdown_name }, index) => {
                if (dropdown_name === "des_responsible") {
                  return (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  );
                }
                return null;
              })}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      <Row></Row>
      <Row>
        <Col xs={4}>
          <Form.Group className="mb-3" controlId="t_c">
            <Form.Label>Примечание</Form.Label>
            <Form.Control
              size="sm"
              autoComplete="off"
              name="terminal_comment"
              placeholder="Примечание"
              type="text"
              onChange={handleChange}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-2">
        <Stack gap={2}>
          <Button
            variant="primary"
            className="mb-3 mt-2 mx-auto"
            onClick={handleSubmitWithConfirm}
            size="sm"
          >
            Отправить
          </Button>
        </Stack>
      </Row>
      <Modal
        centered
        show={showConfirmModal}
        onHide={() => setShowConfirmModal(false)}
      >
        <Modal.Body>
          <p>{confirmModalText}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowConfirmModal(false)}>
            Нет
          </Button>
          <Button
            variant="danger"
            style={{ minWidth: "12%" }}
            onClick={(e) => {
              handleSubmit(e);
              setShowConfirmModal(false);
            }}
          >
            Да
          </Button>
        </Modal.Footer>
      </Modal>
    </Form>
  );
}

export default DesMainTerminal;
