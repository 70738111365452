import React, {useState} from 'react';
import "bootstrap/dist/css/bootstrap.css";
import NPTerminal from './NPTerminal';
import IncomingItems from '../general/IncomingItems';
import NPHistory from './NPHistory';
import {Tabs, Tab} from "react-bootstrap";


function NPNav() {

  const [state, setState] = useState('terminal');

  return(
    <Tabs
      justify
      id="controlled-tab-example"
      activeKey={state}
      onSelect={(k) => setState(k)}
      //className="mb-3"
      unmountOnExit={true}
    >
      <Tab style={{maxWidth: '100%'}} eventKey="terminal" title="Терминал" unmountOnExit={false}>
        <NPTerminal />
      </Tab>
      <Tab eventKey="profile" title="Переброски">
        <IncomingItems />
      </Tab>
      <Tab eventKey="contact" title="История">
        <NPHistory />
      </Tab>
    </Tabs>
  )

}

export default NPNav;
