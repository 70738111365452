import React, {useState, useEffect} from 'react';
import "bootstrap/dist/css/bootstrap.css";
import Draft from '../general/Draft';
import {Container, Row, Col, Tabs, Tab} from "react-bootstrap";
import SupplySOMainTerminal from './SupplySOMainTerminal';
import SupplySOOutcoming from './SypplySOOutcoming';
import SupplySOConfirmed from './SupplySOConfirmed';
import SupplySODeclined from './SupplySODeclined';

function SupplySOTerminal(){

  const [tabState, setTabState] = useState('terminal-fot')

  useEffect(() => {
    return () => {}
  }, [])


  return(
    <Container fluid className=" shadow pt-2">
      <Row>
        <Col>
            <Tabs
            justify
            id="controlled-inner-tab"
            activeKey={tabState}
            onSelect={(k) => setTabState(k)}
            unmountOnExit={false}
            style={{maxWidth: '450px'}}
            >   
                <Tab eventKey="terminal-fot" title="Терминал">
                    <SupplySOMainTerminal />
                </Tab>
                <Tab eventKey="terminal-main" title="Исх."  unmountOnExit={true}>
                    <SupplySOOutcoming />
                </Tab>
                <Tab eventKey="terminal-transition-premium" title="Подтв."  unmountOnExit={true}>
                    <SupplySOConfirmed />
                </Tab>
                <Tab eventKey="terminal-statement" title="Отмененные"  unmountOnExit={true}>
                    <SupplySODeclined />
                </Tab>
            </Tabs>
        </Col>
        <Col xs={12}>
          <Draft />
        </Col>
      </Row>
    </Container>
  )

}

export default SupplySOTerminal;
