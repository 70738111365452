import React, { useState, useEffect, useContext } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Row, Col, Button, Stack, Form, Modal } from "react-bootstrap";
import { UserContext } from "../UserContextManager";
import axios from "axios";
import cookies from "js-cookie";
import CurrencyInput from "react-currency-input-field";
import { Typeahead, Highlighter } from "react-bootstrap-typeahead";
import { useNavigate } from "react-router-dom";

function NsdoMainTerminal() {
  const navigate = useNavigate();

  const defaultResponsible = [
    "РАР",
    "БМЗ",
    "СИС",
    "Дизайн",
    "Маркетинг",
    "МаркетингФОТ",
  ];

  const uContext = useContext(UserContext);

  const [resposibleKey, setResponsiblekey] = useState(0);

  const [objectData, setObjectData] = useState([]);
  const [responsibleData, setResponsibleData] = useState([]);
  const [formData, updateFormData] = useState({});

  const [dropdownData, setDropdownData] = useState([]);

  const [paymentType, setPaymentType] = useState("Тип платежа");
  const [item, setItem] = useState("Статья");
  const [metricValue, setMetricValue] = useState("Метрика");
  const [itemType, setItemType] = useState("Тип статьи");
  const [workType, setWorkType] = useState("Вид работ");
  const [companyName, setCompanyName] = useState("");
  const [responsibleUser, setResponsibleUser] = useState("Ответственный");
  const [worktypes, setWorktypes] = useState([]);

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmModalText, setConfirmModalText] = useState("");

  const [sum, setSum] = useState("");

  const objectRef = React.createRef();
  const responsibleRef = React.createRef();

  const loadTerminalDropdown = async () => {
    try {
      let dropdownsRes = await axios.get("/redesk/terminal/main-dropdowns", {
        params: { desk_type: "nsdo" },
      });

      setDropdownData(dropdownsRes.data.dropdowns_data);
    } catch (error) {
      navigate("/");
    }
  };

  const loadResponsibleUser = async (objectId) => {
    let fdCopy = { ...formData };

    if (objectData.includes(objectId)) {
      let res = await axios.get(`/redesk/nsdo/responsible/${objectId}`);

      if (
        responsibleData.includes(res.data.responsible) &&
        item !== "Переброска"
      ) {
        setResponsibleUser(res.data.responsible);

        fdCopy["terminal_responsible_user"] = res.data.responsible;
      } else {
        setResponsibleUser("Ответственный");
        fdCopy["terminal_responsible_user"] = "";
      }
    } else {
      setResponsibleUser("Ответственный");
      fdCopy["terminal_responsible_user"] = "";
    }
    fdCopy["terminal_object_id"] = objectId;
    updateFormData(fdCopy);
    setResponsiblekey(resposibleKey + 1);
  };

  const handleChange = (e, k = null) => {
    if (k !== null) {
      updateFormData({ ...formData, [k]: e });
    } else {
      updateFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const loadResponsibleData = async () => {
    let res = await axios.get("/redesk/nsdo/responsible");
    setResponsibleData(res.data.responsible_data);
  };

  const loadWorkTypes = async () => {
    let res = await axios.get("/redesk/nsdo/work-type");
    setWorktypes(res.data.work_types);
  };

  const loadObjectData = async () => {
    let res = await axios.get("/redesk/nsdo/objects");
    setObjectData(res.data.object_data);
  };

  const loadCompanyName = async (objectId) => {
    if (objectData.includes(objectId)) {
      let res = await axios.get(`/redesk/nsdo/company/${objectId}`);
      setCompanyName(res.data.company_name);
    } else {
      setCompanyName("");
    }
  };

  const compareDraft = () => {
    let draftCopy = [...uContext.userData.draft_data];

    let has_duplicate = false;
    draftCopy.forEach((x) => {
      if (
        x.metric_5 === formData.terminal_metric_5 &&
        Math.abs(x.sum) === Number(formData.terminal_sum) &&
        x.responsible_user === responsibleUser &&
        x.item === item &&
        x.object_id === formData.terminal_object_id &&
        x.payment_type === paymentType
      ) {
        has_duplicate = true;
      }
    });

    return has_duplicate;
  };

  const handleSubmitWithConfirm = (e) => {
    if (
      item !== "Переброска" &&
      !objectData.includes(formData["terminal_object_id"])
    ) {
      alert("Укажите ид объекта!");
      return;
    }

    if (itemType === "Тип статьи") {
      alert("Укажите тип статьи!");
      return;
    }

    if (item === "Статья") {
      alert("Укажите статью!");
      return;
    }

    if (sum === "") {
      alert("Введите сумму!");
      return;
    }

    if (paymentType === "Тип платежа") {
      alert("Укажите тип платежа!");
      return;
    }

    if (workType === "Вид работ" && item !== "Переброска") {
      alert("Укажите вид работ!");
      return;
    }

    if (
      item !== "Переброска" &&
      responsibleData.indexOf(formData.terminal_responsible_user) < 0 &&
      !responsibleData.includes(formData.terminal_responsible_user)
    ) {
      alert("Ответственный указан неправильно!");
      return;
    }

    if (
      item === "Переброска" &&
      defaultResponsible.indexOf(formData.terminal_responsible_user) < 0
    ) {
      alert("Ответственный указан неправильно!");
      return;
    }

    if (item === "Переброска" && paymentType === "Безнал") {
      alert("Нельзя делать переброску с таким типом платежа!");
      return;
    }

    if (compareDraft()) {
      setConfirmModalText(
        "Похожая запись уже есть в черновике. Создать еще одну?"
      );
      setShowConfirmModal(true);
      return;
    }

    handleSubmit(e);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let draftSendData = { ...formData };
    draftSendData["cfo"] = "СДО";
    draftSendData["terminal_item_type"] = itemType;
    draftSendData["terminal_item"] = item;
    draftSendData["terminal_metric_5"] =
      metricValue === "Метрика" ? null : metricValue;
    draftSendData["terminal_payment_type"] = paymentType;
    draftSendData["terminal_work_type"] = workType;
    draftSendData["terminal_company_name"] =
      companyName === "" ? null : companyName;
    draftSendData["desk_owner"] = "НСДО";

    if (draftSendData["terminal_work_type"] && workType === "Вид работ") {
      delete draftSendData["terminal_work_type"];
    }

    //objectRef.current.clear();
    //responsibleRef.current.clear();

    let res = await axios.post("/redesk/draft/send", draftSendData, {
      headers: { "X-CSRF-TOKEN": cookies.get("csrf_access_token") },
    });
    let contextData = uContext.userData;
    contextData.draft_data = res.data.draft_data;
    uContext.setUserData(contextData);

    /*document.getElementById("terminal_main_form").reset();
    setItemType('Тип статьи');
    updateFormData({});
    setItem('Статья');
    setPaymentType('Тип платежа');
    setMetricValue('Метрика');
    setWorkType('Вид работ');
    setSum('');
    setCompanyName('');*/
  };

  useEffect(() => {
    loadTerminalDropdown();
    loadObjectData();
    loadResponsibleData();
    loadWorkTypes();
    return () => {};
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Form id="terminal_main_form" className="mt-2">
      <Row>
        <Col xs={6}>
          <Form.Group className="mb-3" controlId="t_o_id">
            <Form.Label>Ид объекта</Form.Label>
            <Typeahead
              id="terminal_object_id"
              name="terminal_object_id"
              ref={objectRef}
              placeholder="Ид объекта"
              maxResults={7}
              emptyLabel="Нет совпадений"
              paginationText="Показать больше"
              highlightClassName="font-weight-bold"
              onInputChange={(text) => {
                handleChange(text, "terminal_object_id");
                loadCompanyName(text);
                loadResponsibleUser(text);
              }}
              onChange={(selected) => {
                handleChange(selected[0], "terminal_object_id");
                loadCompanyName(selected[0]);
                loadResponsibleUser(selected[0]);
              }}
              options={objectData}
              size="sm"
              renderMenuItemChildren={(option, props, idx) => (
                <Highlighter
                  search={props.text}
                  highlightClassName="fw-bold mx-0 px-0 bg-transparent"
                >
                  {option}
                </Highlighter>
              )}
            />
          </Form.Group>
        </Col>
        <Form.Group as={Col} className="mb-3" controlId="t_i_t">
          <Form.Label>Тип статьи</Form.Label>
          <Form.Select
            size="sm"
            name="terminal_item_type"
            value={itemType}
            aria-label="terminal_item_type"
            onChange={(e) => {
              setItemType(e.target.value);
              setItem("Статья");
            }}
          >
            <option key="Тип статьи" value="Тип статьи" disabled>
              Тип статьи
            </option>
            <option key="Приход" value="Приход">
              Приход
            </option>
            <option key="Расход" value="Расход">
              Расход
            </option>
          </Form.Select>
        </Form.Group>
      </Row>
      <Row>
        <Col xs={6}>
          <Form.Group className="mb-3" controlId="t_s">
            <Form.Label>Сумма</Form.Label>
            <CurrencyInput
              autoComplete="off"
              id="terminal_sum"
              name="terminal_sum"
              className="form-control"
              style={{ maxHeight: "30px" }}
              value={sum}
              placeholder="Сумма"
              decimalsLimit={2}
              allowNegativeValue={false}
              groupSeparator={" "}
              onValueChange={(e) => {
                setSum(e);
                handleChange(e, "terminal_sum");
              }}
            />
          </Form.Group>
        </Col>
        <Form.Group as={Col} className="mb-3" controlId="t_i">
          <Form.Label>Статья</Form.Label>
          <Form.Select
            size="sm"
            name="terminal_item"
            value={item}
            aria-label="terminal_item"
            disabled={itemType === "Тип статьи" ? true : false}
            onChange={(e) => {
              setItem(e.target.value);
            }}
          >
            <option key="Статья" disabled>
              Статья
            </option>
            {dropdownData.map(
              ({ value, label, dropdown_name, spec }, index) => {
                if (dropdown_name === "nsdo_item" && spec === itemType) {
                  return (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  );
                }
                return null;
              }
            )}
          </Form.Select>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} className="mb-3" controlId="t_w_t">
          <Form.Label>Метрика</Form.Label>
          <Form.Select
            size="sm"
            name="terminal_metric_5"
            value={metricValue}
            aria-label="terminal_metric_5"
            onChange={(e) => {
              handleChange(e);
              setMetricValue(e.target.value);
            }}
            disabled={
              paymentType === "Безнал" || paymentType === "Тип платежа"
                ? true
                : false
            }
          >
            <option key="Метрика" disabled>
              Метрика
            </option>
            <option key="Нал">Нал</option>
            <option key="Карта">Карта</option>
          </Form.Select>
        </Form.Group>
        <Form.Group as={Col} className="mb-3" controlId="t_p_t">
          <Form.Label>Тип платежа</Form.Label>
          <Form.Select
            size="sm"
            name="terminal_payment_type"
            value={paymentType}
            aria-label="terminal_payment_type"
            onChange={(e) => {
              handleChange(e);
              setPaymentType(e.target.value);
              setMetricValue("Метрика");
            }}
          >
            <option key="Тип платежа" disabled>
              Тип платежа
            </option>
            <option key="Нал">Нал</option>
            <option key="Безнал">Безнал</option>
          </Form.Select>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} className="mb-3" controlId="t_m_4">
          <Form.Label>Вид работ</Form.Label>
          <Form.Select
            size="sm"
            name="terminal_work_type"
            value={workType}
            aria-label="terminal_work_type"
            onChange={(e) => {
              handleChange(e);
              setWorkType(e.target.value);
            }}
          >
            <option key="Вид работ">Вид работ</option>
            {worktypes.map((x) => (
              <option key={x} value={x}>
                {x}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group as={Col} className="mb-3" controlId="t_r_u">
          <Form.Label>Ответственный</Form.Label>
          <Typeahead
            key={resposibleKey}
            id="terminal_responsible_user"
            name="terminal_responsible_user"
            ref={responsibleRef}
            labelKey={"value"}
            placeholder="Ответственный"
            maxResults={7}
            emptyLabel="Нет совпадений"
            paginationText="Показать больше"
            highlightClassName="font-weight-bold"
            onChange={(selected) =>
              handleChange(selected[0], "terminal_responsible_user")
            }
            onInputChange={(text) =>
              handleChange(text, "terminal_responsible_user")
            }
            options={
              item === "Переброска" ? defaultResponsible : responsibleData
            }
            defaultSelected={
              responsibleUser === "Ответственный" ? "" : [responsibleUser]
            }
            size="sm"
            renderMenuItemChildren={(option, props, idx) => (
              <Highlighter
                search={props.text}
                highlightClassName="fw-bold mx-0 px-0 bg-transparent"
              >
                {option}
              </Highlighter>
            )}
          />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} className="mb-3" controlId="t_c_n">
          <Form.Label>Юрлицо</Form.Label>
          <Form.Control
            size="sm"
            name="terminal_company_name"
            placeholder={companyName}
            type="text"
            disabled
          />
        </Form.Group>
        <Form.Group as={Col} className="mb-3" controlId="t_c">
          <Form.Label>Примечание</Form.Label>
          <Form.Control
            size="sm"
            autoComplete="off"
            name="terminal_comment"
            placeholder="Примечание"
            type="text"
            onChange={handleChange}
          />
        </Form.Group>
      </Row>
      <Row className="mb-2">
        <Stack gap={2}>
          <Button
            variant="primary"
            className="mb-3 mt-2 mx-auto"
            onClick={handleSubmitWithConfirm}
          >
            Отправить
          </Button>
        </Stack>
      </Row>
      <Modal
        centered
        show={showConfirmModal}
        onHide={() => setShowConfirmModal(false)}
      >
        <Modal.Body>
          <p>{confirmModalText}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowConfirmModal(false)}>
            Нет
          </Button>
          <Button
            variant="danger"
            style={{ minWidth: "12%" }}
            onClick={(e) => {
              handleSubmit(e);
              setShowConfirmModal(false);
            }}
          >
            Да
          </Button>
        </Modal.Footer>
      </Modal>
    </Form>
  );
}

export default NsdoMainTerminal;
