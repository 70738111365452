import React, {useState, useEffect, useContext} from 'react';
import "bootstrap/dist/css/bootstrap.css";
import {Row, Col, Button, Stack, Form, Modal} from "react-bootstrap";
import { UserContext } from '../UserContextManager';
import axios from 'axios';
import cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";
import CurrencyInput from 'react-currency-input-field';
import { Typeahead, Highlighter } from 'react-bootstrap-typeahead';

function FundMainTerminal(){

  const uContext = useContext(UserContext);
  const navigate = useNavigate();

  const objectRef = React.createRef();

  const [dropdownData, setDropdownData] = useState([]);
  const [objectData, setObjectData] = useState([]);
  const [formData, updateFormData] = useState({terminal_payment_type: 'Нал', terminal_item_type: 'Расход'});
  const [CFO, setCFO] = useState('ЦФО');

  const [responsible, setResponsible] = useState('Ответственный');

  const [itemType, setItemType] = useState('Расход');
  const [item, setItem] = useState('Статья');
  const [paymentType, setPaymentType] = useState('Нал');

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmModalText, setConfirmModalText] = useState('');

  const [sum, setSum] = useState('');

  const loadTerminalDropdown = async () => {
    try{
      let dropdownsRes = await axios.get('/redesk/fund/dropdowns');
      setDropdownData(dropdownsRes.data.dropdowns_data);
    } catch (error){
      navigate('/');
    }
  }

  const loadObjectData = async () => {
    let res = await axios.get('/redesk/fund/objects');
    setObjectData(res.data.object_data);
  }

  const handleItemType = (e) => {
    
    let formDataCopy = {...formData}
    delete formDataCopy.terminal_item
    formDataCopy.terminal_item_type = e

    setItem('Статья');
    setItemType(e);
    updateFormData(formDataCopy);
    
  }

  const handlePaymentType = (e) => {

    let formDataCopy = {...formData};
    formDataCopy.terminal_payment_type = e;

    setPaymentType(e);
    updateFormData(formDataCopy);

  }

  const handleCFO = (e) => {
    let formDataCopy = {...formData};
    formDataCopy.terminal_cfo = e;

    setCFO(e);
    updateFormData(formDataCopy);
  }

  const handleChange = (e, k=null) => {

    let newFormData = {}

    if (k !== null){
      newFormData = {...formData, [k]: e}
    }else{
      newFormData = {...formData, [e.target.name]: e.target.value}
    }

    updateFormData(newFormData);
  }

  const compareDraft = () => {

    let draftCopy = [...uContext.userData.draft_data]
    
    let has_duplicate = false
    draftCopy.forEach((x) => {
      if(x.metric_5 === formData.terminal_metric_5 
        && Math.abs(x.sum) === Number(sum)
        && x.responsible_user === responsible
        && x.item === item
        && x.object_id === formData.terminal_object_id
        && x.payment_type === paymentType
        ){
        has_duplicate = true
      }
    })

    return has_duplicate
  }

  const handleSubmitWithConfirm = (e) => {

    if (!formData.terminal_cfo){
      alert('ЦФО не указан!')
      return
    }
    
    if (item == 'Статья'){
      alert('Статья не указана!')
      return
    }

    if (item !== 'Переброска' && !objectData.includes(formData['terminal_object_id'])){
      alert('ИД указан неверно!')
      return
    }

    if (sum == '' || sum <= 0){
      alert('Сумма не указана!')
      return
    }

    if (item !== 'Переброска' && (!formData.terminal_sub_item || formData.terminal_sub_item === 'Подстатья')){
      alert('Подстатья не указана!')
      return
    }

    if ((!formData.terminal_work_type || formData.terminal_work_type === 'Вид работ') && item !== 'Переброска'){
      alert('Вид работ не указан!')
      return
    }

    if (responsible == 'Ответственный'){
      alert('Ответственный не указан!')
      return
    }

    if (formData.terminal_sum >= 1000000){
      setConfirmModalText(`Указанная сумма больше миллиона (${formData.terminal_sum}). Вы уверены?`)
      setShowConfirmModal(true);
      return
    }

    if (item === 'Переброска' && paymentType === 'Безнал'){
      alert('Нельзя делать переброску с таким типом платежа!')
      return
    }
    
    if (compareDraft()){
      setConfirmModalText('Похожая запись уже есть в черновике. Создать еще одну?')
      setShowConfirmModal(true);
      return
    }

    handleSubmit(e)
  }

  const handleSubmit = async (e) => {

    e.preventDefault();

    let draftSendData = formData
    draftSendData['cfo'] = CFO
    draftSendData['desk_owner'] = 'Кассир'

    draftSendData['terminal_item_type'] = itemType
    draftSendData['terminal_item'] = item
    draftSendData['terminal_sum'] = sum
    draftSendData['terminal_responsible_user'] = responsible
    draftSendData['terminal_payment_type'] = paymentType

    if (paymentType == 'Безнал'){
      delete draftSendData['terminal_metric_5']
    }

    if (draftSendData['terminal_metric_5'] && draftSendData['terminal_metric_5'] === 'Метрика 5'){
      delete draftSendData['terminal_metric_5']
    }

    if (draftSendData['terminal_company_name'] && draftSendData['terminal_company_name'] === 'Юрлицо'){
      delete draftSendData['terminal_company_name']
    }

    if (draftSendData['terminal_sub_item'] && draftSendData['terminal_sub_item'] === 'Подстатья'){
      delete draftSendData['terminal_sub_item']
    }

    if (draftSendData['terminal_work_type'] && draftSendData['terminal_work_type'] === 'Вид работ'){
      delete draftSendData['terminal_work_type']
    }

    //objectRef.current.clear();
    let res = await axios.post('/redesk/draft/send', draftSendData, {headers:{'X-CSRF-TOKEN': cookies.get("csrf_access_token")}})
    let contextData = uContext.userData;
    contextData.draft_data = res.data.draft_data;
    uContext.setUserData(contextData);
    
    /*setShowConfirmModal(false);
    document.getElementById("terminal_main_form").reset();
    setItemType('Расход');
    setItem('Статья');
    setPaymentType('Нал');
    setCFO('ЦФО');
    setConfirmModalText('');
    updateFormData({terminal_payment_type: 'Нал', terminal_item_type: 'Расход'});
    setSum('');
    setResponsible('Ответственный')*/
  }

  useEffect(() => {
    loadTerminalDropdown();
    loadObjectData();
    return () => {}
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return(
    <Form id='terminal_main_form' className='mt-2'>
        <Row>
          <Col xs={6}>
            <Form.Group className="mb-3" controlId="t_cfo">
              <Form.Label>ЦФО</Form.Label>
              <Form.Select size="sm" name="terminal_cfo" defaultValue='ЦФО' aria-label="terminal_cfo" onChange={(e) => {handleCFO(e.target.value)}}>
                <option key="ЦФО" disabled>ЦФО</option>
                {dropdownData.map(({value, label, dropdown_name}, index) => {if(dropdown_name === 'fund_cfo'){return <option key={value} value={label}>{label}</option>}return null})}
              </Form.Select>
            </Form.Group>
          </Col>
          <Form.Group as={Col} className="mb-3" controlId="t_i_t" >
            <Form.Label>Тип статьи</Form.Label>
            <Form.Select size="sm" name="terminal_item_type" value={itemType} aria-label="terminal_item_type" onChange={(e) => {handleItemType(e.target.value); setItem('Статья'); setResponsible('Ответственный')}}>
              <option key="Приход" value="Приход">Приход</option>
              <option key="Расход" value="Расход">Расход</option>
            </Form.Select>
        </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} className="mb-3" controlId="t_i">
            <Form.Label>Статья</Form.Label>
            <Form.Select size="sm" name="terminal_item" value={item} aria-label="terminal_item" onChange={(e) => {handleChange(e); setItem(e.target.value); setResponsible('Ответственный')}}>
              <option key="Статья" disabled>Статья</option>
              {dropdownData.map(({value, label, dropdown_name, spec}, index) => {if(dropdown_name === 'fund_item' && spec === itemType){return <option key={value} value={value}>{label}</option>}return null})}
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} className="mb-3" controlId="t_s_i">
            <Form.Label>Подстатья</Form.Label>
            <Form.Select size="sm" name="terminal_sub_item" defaultValue="Подстатья" aria-label="terminal_sub_item" onChange={handleChange}>
              <option key="Подстатья">Подстатья</option>
              {dropdownData.map(({value, label, dropdown_name}, index) => {if(dropdown_name === 'fund_sub_item'){return <option key={value} value={value}>{label}</option>}return null})}
            </Form.Select>
          </Form.Group>
        </Row>
        <Row>
          <Col xs={6}>
            <Form.Group className="mb-3" controlId="t_s">
              <Form.Label>Сумма</Form.Label>
              <CurrencyInput
              autoComplete='off'
              id="terminal_sum"
              name="terminal_sum"
              className='form-control'
              style={{maxHeight: '30px'}}
              value={sum}
              placeholder="Сумма"
              decimalsLimit={2}
              allowNegativeValue={false}
              groupSeparator={' '}
              onValueChange={(e) => {setSum(e); handleChange(e, 'terminal_sum')}}
            />
            </Form.Group>
          </Col>
          <Form.Group as={Col} className="mb-3" controlId="t_o">
            <Form.Label>Вид работ</Form.Label>
            <Form.Select size="sm" name="terminal_work_type" defaultValue="Вид работ" aria-label="terminal_work_type" onChange={handleChange}>
              <option key="Вид работ" value="Вид работ">Вид работ</option>
              {dropdownData.map(({value, label, dropdown_name}, index) => {if(dropdown_name === 'fund_work_type'){return <option key={value} value={value}>{label}</option>}return null})}
            </Form.Select>
          </Form.Group>
        </Row>
        <Row>
          <Col xs={6}>
          <Form.Group className="mb-3" controlId="t_o_id">
            <Form.Label>Ид объекта</Form.Label>
            <Typeahead
              id='terminal_object_id'
              name='terminal_object_id'
              ref = {objectRef}
              placeholder='Ид объекта'
              maxResults={7}
              emptyLabel='Нет совпадений'
              paginationText='Показать больше'
              highlightClassName='font-weight-bold'
              onInputChange={(text) => {handleChange(text, 'terminal_object_id')}}
              onChange={(selected) => {handleChange(selected[0], 'terminal_object_id')}}
              options={objectData}
              size='sm'
              renderMenuItemChildren={(option, props, idx) => (
                <Highlighter search={props.text} highlightClassName='fw-bold mx-0 px-0 bg-transparent'>
                  {option}
                </Highlighter>
              )}
             />
          </Form.Group>
          </Col>
          <Form.Group as={Col} className="mb-3" controlId="t_r_u">
            <Form.Label>Ответственный</Form.Label>
            <Form.Select size="sm" name="terminal_responsible_user" value={responsible} aria-label="terminal_responsible_user" onChange={(e) => {handleChange(e); setResponsible(e.target.value)}}>
              <option key="Ответственный" value="Ответственный" disabled>Ответственный</option>
              {dropdownData.map(({value, label, dropdown_name, spec}, index) => {if(dropdown_name === 'fund_responsible_user' && (spec.split(';').includes(item) ||spec.split(';').includes('all')) ){return <option key={value} value={value}>{label}</option>}return null})}
            </Form.Select>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} className="mb-3" controlId="t_m_5">
            <Form.Label>Метрика 5</Form.Label>
            <Form.Select size="sm" name="terminal_metric_5" defaultValue="Метрика 5" aria-label="terminal_metric_5" onChange={handleChange}>
              <option key="Метрика 5" value="Метрика 5">Метрика 5</option>
              {dropdownData.map(({value, label, dropdown_name}, index) => {if(dropdown_name == 'fund_metric_5' && paymentType == 'Нал'){return <option key={value} value={value}>{label}</option>}return null})}
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} className="mb-3" controlId="t_c_n">
            <Form.Label>Юрлицо</Form.Label>
            <Form.Select size="sm" name="terminal_company_name" defaultValue="Юрлицо" aria-label="terminal_company_name" onChange={handleChange}>
              <option key="Юрлицо">Юрлицо</option>
              {dropdownData.map(({value, label, dropdown_name}, index) => {if(dropdown_name === 'fund_company_name'){return <option key={value} value={value}>{label}</option>}return null})}
            </Form.Select>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} className="mb-3" controlId="t_p_t">
            <Form.Label>Тип платежа</Form.Label>
            <Form.Select size="sm" name="terminal_payment_type" value={paymentType} aria-label="terminal_payment_type" onChange={(e) => {handlePaymentType(e.target.value)}}>
              <option key="Безнал" value="Безнал">Безнал</option>
              <option key="Нал" value="Нал">Нал</option>
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} className="mb-3" controlId="t_c">
            <Form.Label>Примечание</Form.Label>
            <Form.Control size="sm" autoComplete='off' name="terminal_comment" placeholder="Примечание" type="text" onChange={handleChange} />
          </Form.Group>
        </Row>
        <Row className="mb-2">
        <Stack gap={2}>
          <Button variant="primary" className="mb-3 mt-2 mx-auto" onClick={handleSubmitWithConfirm}>
            Отправить
          </Button>
        </Stack>
      </Row>
      <Modal centered show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
      <Modal.Body>
        <p>{confirmModalText}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick = {() => setShowConfirmModal(false)}>
          Нет
        </Button>
        <Button variant="danger" style={{minWidth: "12%"}} onClick={(e) => {handleSubmit(e); setShowConfirmModal(false)}}>
          Да
        </Button>
      </Modal.Footer>
    </Modal>
    </Form>
  )
}


export default FundMainTerminal;
