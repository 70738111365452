import React, {useState, useEffect} from 'react';
import "bootstrap/dist/css/bootstrap.css";
import {Row, Col, Table, Container, Spinner} from "react-bootstrap";
import axios from 'axios';


function ManagementRemainders(){

    const [loading, setLoading] = useState(false)

    const [remainders, setRemainders] = useState([])

    const loadRemainders = async () => {
        
        setLoading(true);

        let res = await axios.get('/redesk/management/mm');
    
        setRemainders(res.data.remainders);
        setLoading(false);
      }
  

    useEffect(() => {
        loadRemainders();
        return () => {};
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    return(
        <Container fluid className="shadow pt-2">
            {remainders && loading === false ? (
                <Row>
                    <Col lg={12} className="overflow-auto accnt-exc-list-maincontainer">
                        <Table striped bordered size="sm" className="rd-accounting" responsive>
                            <thead class="align-middle">
                                <tr>
                                <th>Владелец</th>
                                <th>Подтверждено (Нал)</th>
                                <th>Входящие (Нал)</th>
                                <th>Исходящие (Нал)</th>
                                <th>Факт</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                remainders.map((item) => (
                                    <tr class="align-middle" key={item.uuid} row-uuid={item.uuid}>
                                        <td>{item.owner}</td>
                                        <td className="sum-column">{window.redesk_app.common.fmtFinValue.format(item.cash_confirmed)}</td>
                                        <td className="sum-column" >{window.redesk_app.common.fmtFinValue.format(item.cash_incoming)}</td>
                                        <td className="sum-column" >{window.redesk_app.common.fmtFinValue.format(item.cash_outcoming)}</td>
                                        <td className="sum-column" >{window.redesk_app.common.fmtFinValue.format(item.fact)}</td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            ) : (
                <Row>
                    <Col sm={12} style={{textAlign: "center"}}>
                        <div style={{marginTop: "15%", marginBottom: "15%"}}>
                            <Spinner animation="border" variant="primary"/>
                        </div>
                    </Col>
                </Row>
            )}
            
        </Container>
    )
}

export default ManagementRemainders;
