import React, {useState, useEffect} from 'react';
import "bootstrap/dist/css/bootstrap.css";
import {Row, Col, Table, Spinner, Form} from "react-bootstrap";
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import { MultiSelect } from 'primereact/multiselect';


function AccountingExcerptHistory(){

  const [isLoading, setIsLoading] = useState(false)

  const [excerptList, setExcerptList] = useState([]);
  const [filterList, setFilterlist] = useState([]);
  const [accountFilter, setAccountFilter] = useState([]);

  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsTotal, setItemsTotal] = useState(0);

  const loadExcerptList = async (currentPage) => {

    setIsLoading(true);

    let paginationParams = {
      'page': currentPage,
      'per_page': window.redesk_app.user_settings.per_page,
      'filters': accountFilter.join(';'),
    }

    let res = await axios.get('/redesk/accounting/excerpt/history', {params: paginationParams});

    setExcerptList(res.data.excerpt_list);

    setIsLoading(false);

    setPageCount( Math.ceil(res.data.total/window.redesk_app.user_settings.per_page) )
    setItemsTotal(res.data.total);
  }

  const loadFilters = async () => {
    let res = await axios.get('/redesk/accounting/excerpt/history/filters')
    setFilterlist(res.data.filters);
  }

  const handlePageClick = async (data) => {

    setCurrentPage(data.selected);

    loadExcerptList(data.selected);

  };

  useEffect(() => {
    loadExcerptList()
    return () => {}
  }, [accountFilter]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    loadFilters();
    return () => {};
  }, [])

  return(
    <>
    <Col lg={10} style={{textAlign: "left"}}>
    <Form.Group controlId="a_a_f" >
        <MultiSelect
            value = {accountFilter}
            placeholder="Счет"
            options={filterList}
            maxSelectedLabels={1}
            onChange={(e) => {setAccountFilter(e.value)}}
            showSelectAll={true}
            selectedItemsLabel={`${accountFilter.length} Выбрано`}
            scrollHeight='350px'
            className='small'
          />
    </Form.Group>
    </Col>
    <Row>
      <Col lg={12} className="overflow-auto accnt-exc-history-maincontainer">
        {(excerptList.length > 0 & isLoading === false) ? (
          <Table striped bordered size="sm" style={{textAlign: "center", marginTop: '5px'}} className='accnt-exc-list-maintable' responsive >
            <thead>
              <tr>
                <th>Дата</th>
                <th>Сумма</th>
                <th>Назначение платежа</th>
                <th>Счет</th>
                <th>Комментарий</th>
              </tr>
            </thead>
            <tbody>
              {
                excerptList.map((item) => (
                    <tr key={item.uuid} uuid={item.uuid}>
                    <td className="align-middle accnt-exc-list-date-col">{item.date}</td>
                    <td className="align-middle accnt-exc-list-sum-col">
                    <p>
                    {window.redesk_app.common.fmtFinValue.format(item.ex_sum)}
                    </p>
                    </td>
                    <td class="align-middle accnt-exc-list-purp-col">
                    <p class="text-start lh-sm">{item.payment_purpose}</p><p class="text-end lh-sm accnt-exc-list-subsource"><span>Контрагент: {item.source}</span></p></td>
                    <td class="align-middle accnt-exc-list-org-col">{item.organization}</td>
                    <td class="align-middle accnt-exc-list-org-col">{item.comment}</td>
                  </tr>
                ))
              }
            </tbody>
          </Table>
        ) : (excerptList.length < 1 & isLoading === false) ? (
          <Col sm={12} style={{textAlign: "center"}}>
          <h4 style={{marginTop: "15%", marginBottom: "15%"}}>Нет выписок</h4>
          </Col>
        ) : (
          <Col sm={12} style={{textAlign: "center"}}>
          <div style={{marginTop: "15%", marginBottom: "15%"}}>
            <Spinner animation="border" variant="primary"/>
          </div>
        </Col>
        )}
      </Col>
      <Col xs={12} className='mt-2'>
      {itemsTotal > 10 ?
        <ReactPaginate
          nextLabel={'>>'}
          previousLabel={'<<'}
          breakLabel={'...'}
          pageCount={pageCount}
          pageRangeDisplayed={3}
          forcePage={currentPage}
          containerClassName={'pagination justify-content-center mb-3'}
          pageClassName={'page-item'}
          pageLinkClassName={'page-link'}
          previousClassName={'page-item'}
          previousLinkClassName={'page-link'}
          nextClassName={'page-item'}
          nextLinkClassName={'page-link'}
          breakClassName={'page-item'}
          breakLinkClassName={'page-link'}
          activeClassName={'active'}
          onPageChange={handlePageClick}
        />
        : <></>
      }
      </Col>
    </Row>
    </>
  );
}

export default AccountingExcerptHistory;
