import React, {useState, useEffect, useContext} from 'react';
import "bootstrap/dist/css/bootstrap.css";
import { Typeahead, Highlighter } from 'react-bootstrap-typeahead';
import {Row, Col, Button, Stack, Form, DropdownButton, Dropdown, ButtonGroup, OverlayTrigger, Tooltip, Modal} from "react-bootstrap";
import MasterFot from './fotAddComponents/MasterFot';
import FoTAddon from './fotAddComponents/FotAddon';
import { v4 as uuidV4 } from 'uuid';
import { UserContext } from '../UserContextManager';
import { NUContext } from '../NUHelpersManager';
import axios from 'axios';
import cookies from 'js-cookie';
import CurrencyInput from 'react-currency-input-field';

function FotTerminal(props){

  const uContext = useContext(UserContext);
  const nContext = useContext(NUContext);

  const[resposibleKey, setResponsiblekey] = useState(0);
  const[responsibleUser, setResponsibleUser] = useState('Ответственный')

  const [objectData, setObjectData] = useState([]);
  const [responsibleUserData, setResponsibleUserData] = useState([]);
  const [formData, updateFormData] = useState({});
  const [dropdownData, setDropdownData] = useState([]);
  const [selectedObjectAddress, setSelectedObjectAddress] = useState('');
  const [masterData, setMasterData] = useState([]);
  const [objectMaxDiscount, setObjectMaxDiscount] = useState(0);
  const [estimatorDiscount, setEstimatorDiscount] = useState(0);
  const [mainWorksSum, setMainWorksSum] = useState(0);
  const [mainWork, setMainWork] = useState('');

  const [currentDiscount, setCurrentDiscount] = useState('');

  const [actDiscount, setActDiscount] = useState(0);

  const [prepaymentData, setPrepaymentData] = useState({'prepayment_sum':0, 'np_prepayment_sum':0});

  const [fotCoefficient, setFotCoefficient] = useState(0)

  const [addonDropdown, setAddonDropdown] = useState([
    'СМП',
    'Установка дверей',
    'Предоплата НП',
    'Предоплата',
    'Удержание за инструмент',
  ])

  const [companyName, setCompanyName] = useState('');

  const [addonComponents, setAddonComponents] = useState([])

  const [masterFotECount, setMasterFotECount] = useState([uuidV4()]);

  const [paymentTypeValue, setPaymentTypeValue] = useState('Нал');
  const [fotCalculation, setFotCalculation] = useState(0);
  const [masterFotData, setMasterFotData] = useState({});

  const [remainingFot, setRemainingFot] = useState(0);

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmModalText, setConfirmModalText] = useState('');

  const objectRef = React.useRef(null);
  const responsibleRef = React.useRef(null);
  const discountRef = React.useRef(null);

  const loadprepaymentData = async(objectId) => {
    if (objectData.includes(objectId)){

      let res = await axios.get(`/redesk/nu/prepayment/${objectId}`);

      setPrepaymentData(res.data.prepayment_data);

    }else{
      setPrepaymentData({'prepayment_sum':0, 'np_prepayment_sum':0});
    }
  }

  const calculateRemainingFot = async () => {
    if (fotCalculation <= 0){
      setRemainingFot(0)
      return
    }else{

      let remain = fotCalculation

      for (let k in masterFotData){

        if (!isNaN(masterFotData[k])){
          remain -= masterFotData[k]
        }
        
      }
      
      setRemainingFot(remain)

    }
  }

  const calculateMaxDiscount = (e) => {

    let ee = e ? e : '0'

    let mw = parseFloat(ee.replace(',', '.'))

    setObjectMaxDiscount(Math.ceil((estimatorDiscount / 100)*(mainWorksSum + mw)));
    setActDiscount(Math.ceil((estimatorDiscount / 100) * mw))

  }

  const handleDiscount = (e) => {

    let ee = e ? e : ''
    let v = parseFloat(ee.replace(',', '.'))

    if (v > objectMaxDiscount){
      setCurrentDiscount(objectMaxDiscount)
      handleChange(objectMaxDiscount, 'terminal_fot_discount')
    }else{
      setCurrentDiscount(ee)
      handleChange(ee.replace(',', '.'), 'terminal_fot_discount')
    }


  }

  const loadResponsibleUser = async (objectId) => {

    let FDCopy = {...formData}

    if (objectData.includes(objectId)){

      let res = await axios.get(`/redesk/nu/responsible/${objectId}`);
      
      if(responsibleUserData.includes(res.data.responsible)){
        setResponsibleUser(res.data.responsible);
        FDCopy['terminal_fot_responsible_user'] = res.data.responsible
        FDCopy['terminal_fot_object_id'] = objectId
      }else{
        setResponsibleUser('Ответственный');
        delete FDCopy['terminal_fot_responsible_user']
        FDCopy['terminal_fot_object_id'] = objectId
      }
    }else{
      setResponsibleUser('Ответственный');
      delete FDCopy['terminal_fot_responsible_user']
      FDCopy['terminal_fot_object_id'] = objectId
    }
    updateFormData(FDCopy)
    setResponsiblekey(resposibleKey + 1)
  }

  const loadCompanyName = async (objectId) => {

    if (objectData.includes(objectId)){

      let res = await axios.get(`/redesk/nu/company/${objectId}`);

      setCompanyName(res.data.company_name);
      
    }else{

      setCompanyName('');

    }
  }

  const loadNUHelpers = async (objectId) => {

    let conCopy = nContext.helpersData

    if (objectData.includes(objectId)){

        let res = await axios.get(`/redesk/nu/helpers/${objectId}`);

        conCopy.main_helper = res.data.helpers_data.main_helper
        conCopy.np_helper = res.data.helpers_data.np_helper
        conCopy.output_helper = res.data.helpers_data.output_helper
        conCopy.output_all_helper = res.data.helpers_data.output_all_helper

    }else{
      conCopy.main_helper = 0
      conCopy.np_helper = 0
      conCopy.output_helper = 0
      conCopy.output_all_helper = 0
    }

    nContext.setHelpersData(conCopy);
  }


  const loadMasterData = async (objectId) => {
    if (objectData.indexOf(objectId) > -1){
      let res = await axios.get(`/redesk/terminal/fot/master-info/${objectId}`);
      
      let mData = res.data.masters
      mData.push('Сам прораб')
      mData.push('Временный мастер')

      setMasterData(res.data.masters);
    }else{
      setMasterData([]);
    }
  }


  const loadFotCoefficient = async (objectId) => {
    if (objectData.indexOf(objectId) > -1){
      let res = await axios.get(`/redesk/terminal/fot/fot-coefficient-data/${objectId}`);

      let fotCoefficientData = res.data.fot_coefficient_data;

      let c1 = 0;
      let c2 = 0;

      fotCoefficientData.work_type === 'Дизайн чужой'? c1 = 1.1: c1 = 1

      if (fotCoefficientData.smeta_coefficient >= 1.3){c2 = 1.2} else
      if (1.1 < fotCoefficientData.smeta_coefficient && fotCoefficientData.smeta_coefficient < 1.3){c2 = 1.1} else {
        c2 = 1
      }

      let finalFotСoefficient = c1 * c2 * fotCoefficientData.fot_coefficient;

      setFotCoefficient(finalFotСoefficient > 0 ? finalFotСoefficient.toFixed(3) : finalFotСoefficient);

    }else{
      setFotCoefficient(0);
      setFotCalculation(0);
    }
  }

  const calculateFinalFot = (mainWorks) => {

    if (!mainWorks) {
      setFotCalculation(0);
      setRemainingFot(0)
      return
    }

    let finalFotCalculation = Math.ceil(fotCoefficient * parseFloat(mainWorks.replace(',', '.')))

    setFotCalculation(finalFotCalculation);
    setRemainingFot(finalFotCalculation);

  }

  const updateFotData = (k, v) => {
    let fd = masterFotData;
    fd[k] = parseInt(v);
    setMasterFotData(fd);
  }


  const updateDraftData = (newData) => {

    let oldContext = uContext.userData

    oldContext.draft_data = newData.draft_data

    uContext.setUserData(oldContext);

  }

  const setFormData = (k, v) => {
    updateFormData({...formData, [k]: v})
  }

  const setFormDataDict = (data) => {
    updateFormData({...formData, ...data})
  }

  const removeMasterFot = (e) => {
    let newList = masterFotECount.filter((uuid) => {
      return uuid !== e.target.getAttribute('list-key')
    })
    setMasterFotECount(newList)
  }

  const removeAddon = (e) => {
    let newList = addonComponents.filter((name) => {
      return name !== e
    })
    setAddonComponents(newList)
  }

  const addDropdownAddon = (e) => {
    setAddonDropdown([...addonDropdown, e])
  }

  const loadObjectDiscount = async (objectId) => {
    if (objectData.indexOf(objectId) > -1){
      let res = await axios.get(`/redesk/terminal/fot/object-max-discount/${objectId}`);
      
      let mw

      if(mainWork !== ''){
        mw = parseInt(mainWork)
      }else{
        mw = 0
      }

      setObjectMaxDiscount(Math.ceil((res.data.estimator_discount / 100)*(res.data.main_works_sum + mw)));
      setEstimatorDiscount(res.data.estimator_discount);
      setMainWorksSum(res.data.main_works_sum)
    }else{
      setObjectMaxDiscount(0);
      setEstimatorDiscount(0);
      setMainWorksSum(0)
      //discountRef.current.value = 0;
      setFormDataDict({'terminal_fot_discount': 0});
    }
  }

  const loadObjectAddress = async (object) => {
    if (object) {
      let res = await axios.get('/redesk/terminal/fot/object-address', {params: {object_id:object}});
      setSelectedObjectAddress(res.data.object_address)
    }
  }

  const loadTerminalDropdown = async () => {
    let res = await axios.get('/redesk/terminal/main-dropdowns');
    setDropdownData(res.data.dropdowns_data);
    updateFormData({...formData, 'terminal_fot_item_type': 'Приход', 'terminal_fot_payment_type': 'Нал'});
  }

  const loadResponsibleUserData = async () => {
    let res = await axios.get('/redesk/terminal/responsible-users');
    setResponsibleUserData(res.data.responsible_users);
  }

  const loadTerminalObject = async () => {
    let res = await axios.get('/redesk/nu/objects');
    setObjectData(res.data.objects_data);
  }

  const handleChange = (e, k=null) => {
    if (k !== null){
      updateFormData({...formData, [k]: e})
    }else{
      updateFormData({...formData, [e.target.name]: e.target.value})
    }
  }

  const compareDraft = () => {

    let draftCopy = [...uContext.userData.draft_data]
    
    let has_duplicate = false
    draftCopy.forEach((x) => {
      if(
        Math.abs(x.sum) === Number(formData.terminal_fot_main_sum)
        && x.responsible_user === formData.terminal_fot_responsible_user
        && x.object_id === formData.terminal_fot_object_id
        && x.payment_type === formData.terminal_fot_payment_type
        ){
        has_duplicate = true
      }
    })

    return has_duplicate
  }

  const handleSubmitWithConfirm = (e) => {

    if (objectData.indexOf(formData.terminal_fot_object_id) < 0){
      alert('ИД объекта указан неправильно!')
      return
    }

    if (!responsibleUserData.includes(formData.terminal_fot_responsible_user)){
      alert('Ответственный указан неправильно!')
      return
    }

    if (!formData.terminal_fot_main_sum & !formData.terminal_fot_smp & !formData.terminal_fot_door & !formData.terminal_fot_prepaymentnp & !formData.terminal_fot_prepayment & !formData.terminal_fot_toolw){
      alert('Введите сумму основных работ!')
      return
    }

    let fotSum = 0
    for (let k in masterFotData){
      fotSum += masterFotData[k]
    }

    if (fotSum !== parseInt(fotCalculation)){
      alert('Сумма ФОТов мастеров должна совпадать с ФОТом основных работ!')
      return
    }

    if (compareDraft()){
      setConfirmModalText('Похожая запись уже есть в черновике. Создать еще одну?')
      setShowConfirmModal(true);
      return
    }

    handleSubmit(e)
  }

  const handleSubmit = async (e) => {

    e.preventDefault();

    let draftSendData = {...formData}
    draftSendData['terminal_fot_company_name'] = companyName;

    if (draftSendData.terminal_fot_sub_item === 'Подстатья'){
      delete draftSendData.terminal_fot_sub_item
    }


    let emptyMasterFot = Object.keys(draftSendData).filter(x => x.startsWith('terminal_fot_master_fot_'))

    if (!draftSendData.terminal_fot_master_fot_0 || draftSendData.terminal_fot_master_fot_0 === null){
      delete draftSendData.terminal_fot_master_fot_0
      delete draftSendData.terminal_fot_master_0
    }

    console.log(draftSendData)

    let res = await axios.post('/redesk/draft/fot/send', draftSendData, {headers:{'X-CSRF-TOKEN': cookies.get("csrf_access_token")}});
    updateDraftData(res.data);

    setMainWork('');
    setCurrentDiscount('');


    let fmCopy = {...formData}
    delete fmCopy['terminal_fot_main_sum']
    delete fmCopy['terminal_fot_discount']
    updateFormData(fmCopy)
    /*props.changeKey(Math.random());*/
  }

  useEffect(() => {
    loadTerminalDropdown();
    loadTerminalObject();
    loadResponsibleUserData();

    return () => {}
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return(
    <>
    <Form id='terminal_fot_form' className='mt-2'>
      <Row>
        <Row>
          <Form.Group as={Col} className="mb-3">
            <Form.Label>Ид объекта</Form.Label>
            <Typeahead
              id='terminal_fot_object_id'
              name='terminal_fot_object_id'
              ref = {objectRef}
              labelKey='value'
              placeholder='Ид объекта'
              maxResults={7}
              emptyLabel='Нет совпадений'
              paginationText='Показать больше'
              highlightClassName='font-weight-bold'
              onInputChange={(text) => {handleChange(text, 'terminal_fot_object_id'); objectData.indexOf(text) > -1? loadObjectAddress(text): setSelectedObjectAddress(''); loadObjectDiscount(text); loadMasterData(text); loadFotCoefficient(text); loadNUHelpers(text); loadCompanyName(text); loadResponsibleUser(text); loadprepaymentData(text)}}
              onChange={(selected) => {loadObjectAddress(selected[0]); handleChange(selected[0], 'terminal_fot_object_id'); loadObjectDiscount(selected[0]); loadMasterData(selected[0]); loadFotCoefficient(selected[0]); loadNUHelpers(selected[0]); loadCompanyName(selected[0]); loadResponsibleUser(selected[0]); loadprepaymentData(selected[0])}}
              options={objectData}
              size='sm'
              renderMenuItemChildren={(option, props, idx) => (
                <Highlighter search={props.text} highlightClassName='fw-bold mx-0 px-0 bg-transparent'>
                  {option}
                </Highlighter>
              )}
             />
          </Form.Group>
          <Form.Group as={Col} className="mb-3" controlId="t_f_o_a">
            <Form.Label>Адрес</Form.Label>
            <Form.Control size="sm" defaultValue='' name="terminal_fot_object_address" placeholder={selectedObjectAddress} disabled />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} className="mb-3">
            <Form.Label>Ответственный</Form.Label>
            <Typeahead
              key={resposibleKey}
              id='terminal_fot_responsible_user'
              name='terminal_fot_responsible_user'
              ref = {responsibleRef}
              labelKey='value'
              placeholder='Ответственный'
              maxResults={7}
              emptyLabel='Нет совпадений'
              paginationText='Показать больше'
              highlightClassName='font-weight-bold'
              onChange={(selected) => {handleChange(selected[0], 'terminal_fot_responsible_user')}}
              onInputChange={(text) => {handleChange(text, 'terminal_fot_responsible_user')}}
              options={responsibleUserData}
              size='sm'
              defaultSelected={responsibleUser === 'Ответственный' || responsibleUser === '' ? '' : [responsibleUser]}
              renderMenuItemChildren={(option, props, idx) => (
                <Highlighter search={props.text} highlightClassName='fw-bold mx-0 px-0 bg-transparent'>
                  {option}
                </Highlighter>
              )}
             />
          </Form.Group>
          <Form.Group as={Col} className="mb-3" controlId="t_f_c_n">
            <Form.Label>Юрлицо</Form.Label>
            <Form.Control size="sm" defaultValue='' name="terminal_fot_company_name" placeholder={companyName} disabled />
          </Form.Group>
        </Row>
        <Row>
          <Col xs={4}>
            <Form.Group className="mb-3" controlId="t_f_m_s">
              <Form.Label>Основные работы</Form.Label>
              <CurrencyInput
              autoComplete="off"
              id="terminal_fot_main_sum"
              name="terminal_fot_main_sum"
              className='form-control'
              style={{maxHeight: '30px'}}
              value={mainWork}
              placeholder="Сумма"
              decimalsLimit={2}
              allowNegativeValue={false}
              groupSeparator={' '}
              onValueChange={(e) => {calculateMaxDiscount(e); setMainWork(e); calculateFinalFot(e); handleChange(e, 'terminal_fot_main_sum')}}
              disabled={objectData.indexOf(formData['terminal_fot_object_id']) > -1 ? false : true}
            />
            </Form.Group>
          </Col>
          <OverlayTrigger
            trigger="click"
            placement='top'
            rootClose='true'
            overlay={
              <Tooltip>
                  Скидка сметчика {estimatorDiscount}% <br/>
                  Скидка по акту {actDiscount}
              </Tooltip>
            }
          >
          <Col xs = {2} >
            <Form.Group className="mb-3" controlId="t_f_d">
              <Form.Label style={{ cursor:'pointer' }}>{objectMaxDiscount > 0? `Скидка (до ${objectMaxDiscount})`: 'Скидка'}</Form.Label>
              <CurrencyInput
              autoComplete="off"
              id="terminal_fot_discount"
              name="terminal_fot_discount"
              className='form-control'
              style={{maxHeight: '30px'}}
              value={currentDiscount}
              placeholder="Скидка"
              decimalsLimit={2}
              allowNegativeValue={false}
              groupSeparator={' '}
              onValueChange={(e) => {handleDiscount(e)}}
              disabled={objectMaxDiscount > 0? false : true}
              />
            </Form.Group>
          </Col>
          </OverlayTrigger>
          <OverlayTrigger
            trigger="click"
            placement='top'
            rootClose='true'
            overlay={
              <Tooltip>
                  Коэффициент {fotCoefficient}
              </Tooltip>
            }
          >
            <Form.Group as={Col} className="mb-3" controlId="t_f_c_f" style={{ cursor:'pointer' }}>
              <Form.Label style={{ cursor:'pointer' }}>ФОТ мастеров</Form.Label>
              <Form.Control size="sm" defaultValue='' name="terminal_fot_calc_fot" placeholder={fotCalculation} style={{ cursor:'pointer' }} disabled />
            </Form.Group>
        </OverlayTrigger>
        </Row>
        <Row>
          <Form.Group as={Col} className="mb-3" controlId="t_s_i">
          <Form.Label>Подстатья</Form.Label>
          <Form.Select size="sm" defaultValue='Подстатья' name="terminal_fot_sub_item" aria-label="terminal_fot_sub_item" onChange={(e) => {handleChange(e)}}>
            <option key="Подстатья">Подстатья</option>
            <option key="Мехстяжка" value="Мехстяжка">Мехстяжка</option>
            <option key="Мехштукатурка" value="Мехштукатурка">Мехштукатурка</option>
          </Form.Select>
          </Form.Group>
          <Form.Group as={Col} className="mb-3" controlId="t_c">
          <Form.Label>Примечание</Form.Label>
          <Form.Control size="sm" autoComplete="off" name="terminal_fot_comment" placeholder="Примечание" type="text" onChange={handleChange} />
          </Form.Group>
        </Row>
        <Row>
          <Col xs={6}>
          <Form.Group className="mb-3" controlId="t_f_p_t">
            <Form.Label>Тип платежа поэтапки</Form.Label>
            <Form.Select size="sm" name="terminal_fot_payment_type" aria-label="terminal_fot_payment_type" value={paymentTypeValue} onChange={(e) => {setPaymentTypeValue(e.target.value); handleChange(e)}}>
              {dropdownData.map(({value, label, dropdown_name}, index) => {if(dropdown_name === 'terminal_payment_type'){
                return <option key={value} value={value}>{label}</option>
              }return null})}
            </Form.Select>
          </Form.Group>
          </Col>
          <Col xs={3} style={{'paddingRight': 0}}>
            <Form.Group className="mb-3" controlId="t_f_f_r">
              <Form.Label>Осталось расписать</Form.Label>
              <Form.Control size="sm" defaultValue='' name="terminal_fot_fot_remaining" placeholder={remainingFot} disabled />
            </Form.Group>
          </Col>
          <Col xs={3} className="pt-4" style={{'textAlign': 'right'}}>
            <ButtonGroup>
              <Button variant="primary" className="mb-3 mt-2" size="sm" onClick={() => setMasterFotECount([...masterFotECount, uuidV4()])}>
                + ФОТ
              </Button>
              <DropdownButton as={ButtonGroup} className="mb-3 mt-2" size="sm" id="terminal-fot-additional-dropdown" title="Допы">
                {addonDropdown.map((name) => {
                  return(
                    <Dropdown.Item key={name} onClick={(e) => {setAddonComponents([...addonComponents, e.target.text]); let newList = addonDropdown.filter((name) => {return name !== e.target.text}); setAddonDropdown(newList)}}>{name}</Dropdown.Item>
                  )
                })}
              </DropdownButton>
            </ButtonGroup>

          </Col>
        </Row>
        </Row>
        <Modal centered show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
      <Modal.Body>
        <p>{confirmModalText}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick = {() => setShowConfirmModal(false)}>
          Нет
        </Button>
        <Button variant="danger" style={{minWidth: "12%"}} onClick={(e) => {handleSubmit(e); setShowConfirmModal(false)}}>
          Да
        </Button>
      </Modal.Footer>
      </Modal>
      <Row>
        <MasterFot
          formData = {formData}
          setFormData = {updateFormData}
          fotList={masterFotECount}
          onChange={handleChange}
          onInputChange={handleChange}
          options={masterData}
          onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
          fotOnChange={handleChange}
          updateFotData={updateFotData}
          delOnClick={removeMasterFot}
          calculateRemainingFot={calculateRemainingFot}
        />
      </Row>
    <Row>
        <FoTAddon
          onChange={handleChange}
          options={masterData}
          dropdownData={dropdownData}
          addonComponents={addonComponents}
          removeAddon={removeAddon}
          addDropdownAddon={addDropdownAddon}
          updateFormData={setFormData}
          formData={formData}
          prepaymentData={prepaymentData}
          handleChange={handleChange}
          setFormDataDict={setFormDataDict}
          setFormData={updateFormData}
        />
      </Row>
    </Form>
    <Row className="mb-2">
      <Stack gap={2}>
        <Button variant="primary" className="mb-3 mt-2 mx-auto" onClick={handleSubmitWithConfirm}>
          Отправить
        </Button>
      </Stack>
    </Row>
    </>
  )
}


export default FotTerminal;
