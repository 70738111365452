import React, {useState, useEffect, useContext} from 'react';
import "bootstrap/dist/css/bootstrap.css";
import { Typeahead, Highlighter } from 'react-bootstrap-typeahead';
import {Row, Col, Button, Stack, Form, Modal} from "react-bootstrap";
import { UserContext } from '../UserContextManager';
import { NUContext } from '../NUHelpersManager';
import { NuSpbContext } from '../NuSpbContextManager';
import axios from 'axios';
import cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";
import CurrencyInput from 'react-currency-input-field';

function NuSpbMainTerminal(){

  const navigate = useNavigate();

  const uContext = useContext(UserContext);
  const nContext = useContext(NUContext);
  const nspbContext = useContext(NuSpbContext);

  const[resposibleKey, setResponsiblekey] = useState(0);

  const [dropdownData, setDropdownData] = useState([]);
  const [objectData, setObjectData] = useState([]);
  const [responsibleUserData, setResponsibleUserData] = useState([]);
  const [defaultResponsibles, setDefaultResponsibles] = useState([]);
  const [reasOptions, setReasOptions] = useState([]);
  const [paymentType, setPaymentType] = useState('Нал');
  const [fotPaymentType, setFotPaymentType] = useState('Нал');
  const [workType, setWorkType] = useState('Вид работы');
  const [subitem, setSubItem] = useState('Подстатья');
  const [sum, setSum] = useState('');
  const [fotSum, setFotSum] = useState('');

  const [companyName, setCompanyName] = useState('');
  const [responsibleUser, setResponsibleUser] = useState('Ответственный')

  const [itemType, setItemType] = useState('Приход');
  const [item, setItem] = useState('Статья');
  const [formData, updateFormData] = useState({});

  const [masterData, setMasterData] = useState([]);

  const [loanRemainder, setLoanRemainder] = useState(0)

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmModalText, setConfirmModalText] = useState('');

  const objectRef = React.createRef();
  const responsibleRef = React.createRef();

  const loadResponsibleUser = async (objectId) => {

    if (objectData.includes(objectId)){

      let res = await axios.get(`/redesk/nu/responsible/${objectId}`);

      if(responsibleUserData.includes(res.data.responsible) && item !== 'Переброска'){
        setResponsibleUser(res.data.responsible);
        let draftSendData = {...formData}
        draftSendData['terminal_responsible_user'] = res.data.responsible
        draftSendData['terminal_object_id'] = objectId
        updateFormData(draftSendData);
      }else{
        setResponsibleUser('Ответственный');
        let draftSendData = {...formData}
        delete draftSendData['terminal_responsible_user']
        draftSendData['terminal_object_id'] = objectId
        updateFormData(draftSendData)
      }
      
    }else{
      let draftSendData = {...formData}
      delete draftSendData['terminal_responsible_user']
      draftSendData['terminal_object_id'] = objectId
      updateFormData(draftSendData)
      setResponsibleUser('Ответственный');

    }

    setResponsiblekey(resposibleKey + 1)

  }

  const loadCompanyName = async (objectId) => {

    if (objectData.includes(objectId)){

      let res = await axios.get(`/redesk/nu/company/${objectId}`);

      setCompanyName(res.data.company_name);
      
    }else{

      setCompanyName('');

    }
  }

  const loadNUHelpers = async (objectId) => {

    let conCopy = nContext.helpersData

    if (objectData.includes(objectId)){

        let res = await axios.get(`/redesk/nu/helpers/${objectId}`);

        conCopy.main_helper = res.data.helpers_data.main_helper
        conCopy.np_helper = res.data.helpers_data.np_helper
        conCopy.output_helper = res.data.helpers_data.output_helper

    }else{
      conCopy.main_helper = 0
      conCopy.np_helper = 0
      conCopy.output_helper = 0
    }

    nContext.setHelpersData(conCopy);
  }

  const filterReasOptions = (dd_data) => {
    let newROptions = [];
    for (let x of dd_data){
      if (x.spec === 'Переброска'){
        newROptions.push(x.value);
      }
    }
    setReasOptions(newROptions);
  }

  const handleWTSI = (e) => {

    let formDataCopy = {...formData}
    formDataCopy['terminal_work_type'] = e.target.value

    if (e.target.value !== 'Основные работы'){
      delete formDataCopy['terminal_sub_item']
      setSubItem('Подстатья')
    }

    setWorkType(e.target.value);
    updateFormData(formDataCopy);
  }

  const handleReasItem = (item) => {

    if (item === 'Переброска'){
      setItem(item);
      setPaymentType('Нал');
      updateFormData({...formData, 'terminal_payment_type': 'Нал', 'terminal_item': item});
    }else{
      setItem(item);
      updateFormData({...formData, 'terminal_item': item});
    }

  }

  const checkLoanRemainder = async (objectId) => {
    if (objectData.indexOf(objectId) > -1){
      let res = await axios.get('/redesk/terminal/object-loan', {params:{object_id:objectId}});
      setLoanRemainder(res.data.loan_remainder);
    }else{
      setLoanRemainder(0);
    }
  }

  const loadMasterData = async () => {
    
    let res = await axios.get(`/redesk/nu/master`);
    setMasterData(res.data.master_data);
  }

  const loadTerminalDropdown = async () => {

    try{
      let dropdownsRes = await axios.get('/redesk/terminal/main-dropdowns', {params:{desk_type:'nu_spb'}})

      setDropdownData(dropdownsRes.data.dropdowns_data);
      filterReasOptions(dropdownsRes.data.dropdowns_data);
    } catch (error){
      navigate('/');
    }
  }

  const loadResponsibleUserData = async () => {
    let res = await axios.get('/redesk/nu_spb/responsible-users');
    setResponsibleUserData(res.data.responsible_users);
    setDefaultResponsibles(res.data.default_responsibles)
  }

  const loadTerminalObject = async () => {
    let res = await axios.get('/redesk/nu_spb/objects');
    setObjectData(res.data.objects_data);
  }

  const loadObjectInfo = async (objectId) => {

    if (objectData.includes(objectId)){
      let res = await axios.get(`/redesk/nu_spb/object/${objectId}`);
      nspbContext.setHelpersData(res.data.object_data)
    }else{
      nspbContext.setHelpersData({
        foreman: '',
        revenue: 0
      })
    }
    
  }

  const handleChange = (e, k=null) => {
    if (k !== null){
      updateFormData({...formData, [k]: e})
    }else{
      updateFormData({...formData, [e.target.name]: e.target.value})
    }
  }

  const compareDraft = () => {

    let draftCopy = [...uContext.userData.draft_data]
    
    let has_duplicate = false
    draftCopy.forEach((x) => {
      if(
        Math.abs(x.sum) === Number(formData.terminal_sum)
        && x.responsible_user === formData.terminal_responsible_user
        && x.item === item
        && x.object_id === formData.terminal_object_id
        && x.payment_type === paymentType
        ){
        has_duplicate = true
      }
    })

    return has_duplicate
  }

  const handleSubmitWithConfirm = (e) => {
    if (objectData.indexOf(formData.terminal_object_id) < 0 
    && item !== 'Переброска' 
    && item != 'Компенсации сотрудникам за проезд (ГСМ)' 
    && item != 'Оплата поставщикам рабочих инструментов'){
      alert('ИД объекта указан неправильно!')
      return
    }

    if (item !== 'Переброска' 
    && defaultResponsibles.concat(responsibleUserData).indexOf(formData.terminal_responsible_user) < 0 
    && !defaultResponsibles.concat(responsibleUserData).includes(responsibleUser)){
      alert('Ответственный указан неправильно!')
      return
    }

    if (item === 'Переброска' 
    && defaultResponsibles.indexOf(formData.terminal_responsible_user) < 0){
      alert('Ответственный указан неправильно!')
      return
    }

    if (!formData.terminal_sum){
      alert('Введите сумму!')
      return
    }

    if (!item || item === 'Статья'){
      alert('Укажите статью!')
      return
    }

    if (!formData.terminal_work_type && item !== 'Переброска'){
      alert('Укажите вид работ!')
      return
    }

    if (compareDraft()){
      setConfirmModalText('Похожая запись уже есть в черновике. Создать еще одну?')
      setShowConfirmModal(true);
      return
    }

    handleSubmit(e)
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    let draftSendData = formData
    draftSendData['cfo'] = 'СПб_НУ'
    draftSendData['terminal_company_name'] = companyName;
    draftSendData['terminal_item_type'] = itemType
    draftSendData['terminal_item'] = item
    draftSendData['terminal_payment_type'] = paymentType
    
    console.log(draftSendData)

    if (!draftSendData.terminal_responsible_user | !responsibleUserData.includes(draftSendData.terminal_responsible_user)){

      if(responsibleUserData.includes(responsibleUser) && item !== 'Переброска'){
        draftSendData['terminal_responsible_user'] = responsibleUser;
      }
    }


    /*objectRef.current.clear();
    responsibleRef.current.clear();*/

    let res = await axios.post('/redesk/draft/send', draftSendData, {headers:{'X-CSRF-TOKEN': cookies.get("csrf_access_token")}})

    if (fotSum) {
      let draftSendData_2 = {...draftSendData}

       draftSendData_2['fot_sum'] = fotSum
       draftSendData_2['fot_payment_type'] = fotPaymentType

      res = await axios.post('/redesk/nu_spb/send_fot_draft', draftSendData_2, {headers:{'X-CSRF-TOKEN': cookies.get("csrf_access_token")}})
    }

    let contextData = uContext.userData;
    contextData.draft_data = res.data.draft_data;
    uContext.setUserData(contextData);



    /*document.getElementById("terminal_main_form").reset();
    setLoanRemainder(0);
    setItemType('Приход');
    setItem('Статья');
    setPaymentType('Нал');
    updateFormData({...{}, 'terminal_payment_type': 'Нал', 'terminal_item_type': 'Приход'});
    setSubItem('Подстатья');
    setWorkType('Вид работы');
    setMasterData([]);
    setCompanyName('');
    setResponsibleUser('Ответственный');
    setSum('');
    let conCopy = nContext.helpersData
    conCopy.main_helper = 0
    conCopy.np_helper = 0
    conCopy.output_helper = 0
    nContext.setHelpersData(conCopy);*/
  }

  useEffect(() => {
    loadTerminalDropdown();
    loadTerminalObject();
    loadResponsibleUserData();
    loadMasterData();

    return () => {}
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return(
    <Form id='terminal_main_form' className='mt-2'>
      <Row>
        <Form.Group as={Col} className="mb-3" controlId="t_i_t" >
          <Form.Label>Тип статьи</Form.Label>
          <Form.Select size="sm" name="terminal_item_type" value={itemType} aria-label="terminal_item_type" onChange={(e) => {setItemType(e.target.value); setItem('Статья')}}>
            <option key='Приход' value='Приход'>Приход</option>
            <option key='Расход' value='Расход'>Расход</option>
          </Form.Select>
        </Form.Group>
        <Form.Group as={Col} className="mb-3" controlId="t_i">
          <Form.Label>Статья</Form.Label>
          <Form.Select size="sm" name="terminal_item" value={item} aria-label="terminal_item" onChange={(e) => {handleReasItem(e.target.value);}}>
            <option key="Статья" disabled>Статья</option>
            {dropdownData.map(({value, label, dropdown_name, spec}, index) => {if(dropdown_name === 'nu_spb_item' && spec === itemType){return <option key={value} value={value}>{label}</option>}return null})}
          </Form.Select>
        </Form.Group>
      </Row>
      <Row>
        <Col xs={6}>
          <Form.Group className="mb-3" controlId="t_s">
            <Form.Label>Сумма</Form.Label>
            <CurrencyInput
              autoComplete='off'
              id="terminal_sum"
              name="terminal_sum"
              className='form-control'
              style={{maxHeight: '30px'}}
              value={sum}
              placeholder="Сумма"
              decimalsLimit={2}
              allowNegativeValue={false}
              groupSeparator={' '}
              onValueChange={(e) => {setSum(e); handleChange(e, 'terminal_sum')}}
            />
          </Form.Group>
        </Col>
        <Form.Group as={Col} className="mb-3" controlId="t_p_t">
          <Form.Label>Тип платежа</Form.Label>
          <Form.Select size="sm" name="terminal_payment_type" aria-label="terminal_payment_type" value={paymentType} onChange={(e) => {handleChange(e); setPaymentType(e.target.value)}} disabled={item === 'Переброска' ? true : false}>
            <option key="Нал" value='Нал'>Нал</option>
            <option key="Безнал" value='Безнал'>Безнал</option>
          </Form.Select>
        </Form.Group>
      </Row>
      <Row>
        <Col xs={4}>
          <Form.Group className="mb-3" controlId="t_o_id">
            <Form.Label>Ид объекта</Form.Label>
            <Typeahead
              id='terminal_object_id'
              name='terminal_object_id'
              ref = {objectRef}
              placeholder='Ид объекта'
              maxResults={7}
              emptyLabel='Нет совпадений'
              paginationText='Показать больше'
              highlightClassName='font-weight-bold'
              onInputChange={(text) => {handleChange(text, 'terminal_object_id'); checkLoanRemainder(text); loadNUHelpers(text); loadCompanyName(text); loadResponsibleUser(text); loadObjectInfo(text)}}
              onChange={(selected) => {handleChange(selected[0], 'terminal_object_id'); checkLoanRemainder(selected[0]); loadNUHelpers(selected[0]); loadCompanyName(selected[0]); loadResponsibleUser(selected[0]); loadObjectInfo(selected[0])}}
              options={objectData}
              size='sm'
              renderMenuItemChildren={(option, props, idx) => (
                <Highlighter search={props.text} highlightClassName='fw-bold mx-0 px-0 bg-transparent'>
                  {option}
                </Highlighter>
              )}
             />
          </Form.Group>
        </Col>
        <Col xs={2}>
          <Form.Group className="mb-3" controlId="t_l">
            <Form.Label>Кредит</Form.Label>
            <Form.Control size="sm" defaultValue='' name="terminal_loan_remainder" placeholder={loanRemainder} disabled />
          </Form.Group>
        </Col>
        <Form.Group as={Col} className="mb-3" controlId="t_w_t">
          <Form.Label>Вид работы</Form.Label>
          <Form.Select size="sm" value={workType} name="terminal_work_type" aria-label="terminal_work_type" onChange={(e) => {handleWTSI(e)}}>
            <option key="Вид работы" disabled>Вид работы</option>
            {dropdownData.map(({value, label, dropdown_name}, index) => {if(dropdown_name === 'nu_spb_work_type'){return <option key={value} value={label}>{label}</option>}return null})}
          </Form.Select>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} className="mb-3" controlId="t_s_i">
        <Form.Label>Подстатья</Form.Label>
        <Form.Select size="sm" value={subitem} name="terminal_sub_item" aria-label="terminal_sub_item" onChange={(e) => {handleChange(e); setSubItem(e.target.value)}} disabled={workType === 'Основные работы' ? false : true}>
          <option key="Подстатья" disabled>Подстатья</option>
          <option key="Мехстяжка" value="Мехстяжка">Мехстяжка</option>
          <option key="Мехштукатурка" value="Мехштукатурка">Мехштукатурка</option>
        </Form.Select>
        </Form.Group>
        <Form.Group as={Col} className="mb-3" controlId="t_c_n">
            <Form.Label>Юрлицо</Form.Label>
            <Form.Control size="sm" defaultValue='' name="terminal_company_name" placeholder={companyName} disabled />
        </Form.Group>
      </Row>
      <Row>
      <Form.Group as={Col} className="mb-3" controlId="t_r_u">
        <Form.Label>Ответственный</Form.Label>
        <Typeahead
          key={resposibleKey}
          id='terminal_responsible_user'
          name='terminal_responsible_user'
          ref = {responsibleRef}
          labelKey={'value'}
          placeholder='Ответственный'
          maxResults={7}
          emptyLabel='Нет совпадений'
          paginationText='Показать больше'
          highlightClassName='font-weight-bold'
          onChange={(selected) => {handleChange(selected[0], 'terminal_responsible_user')}}
          onInputChange={(text) => {handleChange(text, 'terminal_responsible_user')}}
          options={item === 'Переброска' ? defaultResponsibles : defaultResponsibles.concat(responsibleUserData)}
          size='sm'
          defaultSelected={responsibleUser === 'Ответственный' ? '' : [responsibleUser]}
          renderMenuItemChildren={(option, props, idx) => (
            <Highlighter search={props.text} highlightClassName='fw-bold mx-0 px-0 bg-transparent'>
              {option}
            </Highlighter>
          )}
         />
      </Form.Group>
      <Form.Group as={Col} className="mb-3" controlId="t_c">
          <Form.Label>Примечание</Form.Label>
          <Form.Control size="sm" name="terminal_comment" placeholder="Примечание" autoComplete="off" type="text" onChange={handleChange} />
        </Form.Group>
      </Row>
      <Row>
        <Col xs={12}><hr /></Col>
        <Col lg={6}>
        <Form.Group className="mb-3" controlId="t_s">
            <Form.Label>ФОТ мастеров</Form.Label>
            <CurrencyInput
              autoComplete='off'
              id="terminal_fot_sum"
              name="terminal_fot_sum"
              className='form-control'
              style={{maxHeight: '30px'}}
              value={fotSum}
              placeholder="Сумма"
              decimalsLimit={2}
              allowNegativeValue={false}
              groupSeparator={' '}
              onValueChange={(e) => {setFotSum(e);}}
            />
          </Form.Group>
        </Col>
        <Col lg={6}>
        <Form.Group as={Col} className="mb-3" controlId="t_p_t">
          <Form.Label>Тип платежа</Form.Label>
          <Form.Select size="sm" name="terminal_fot_payment_type" aria-label="terminal_fot_payment_type" value={fotPaymentType} onChange={(e) => {setFotPaymentType(e.target.value)}} >
            <option key="Нал" value='Нал'>Нал</option>
            <option key="Безнал" value='Безнал'>Безнал</option>
          </Form.Select>
        </Form.Group>
        </Col>
      </Row>
      <Row className="mb-2">
        <Stack gap={2}>
          <Button variant="primary" className="mb-3 mt-2 mx-auto" onClick={handleSubmitWithConfirm}>
            Отправить
          </Button>
        </Stack>
      </Row>
      <Modal centered show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
      <Modal.Body>
        <p>{confirmModalText}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick = {() => setShowConfirmModal(false)}>
          Нет
        </Button>
        <Button variant="danger" style={{minWidth: "12%"}} onClick={(e) => {handleSubmit(e); setShowConfirmModal(false)}}>
          Да
        </Button>
      </Modal.Footer>
    </Modal>
    </Form>
  )
}


export default NuSpbMainTerminal;
