import React, { useState } from 'react';
import "bootstrap/dist/css/bootstrap.css";
import ManagementSpbTerminal from './ManagementSpbTerminal';
import IncomingItems from '../general/IncomingItems';
import {Tabs, Tab} from "react-bootstrap";
import ManagementSpbHistory from './ManagementSpbHistory';


function ManagementNav() {

  const [state, setState] = useState('terminal');

  return(
    <Tabs
      justify
      id="controlled-tab-example"
      activeKey={state}
      onSelect={(k) => setState(k)}
      //className="mb-3"
      unmountOnExit={true}
    >
      <Tab eventKey="terminal" title="Терминал" unmountOnExit={false}>
        <ManagementSpbTerminal />
      </Tab>
      <Tab eventKey="profile" title="Переброски">
        <IncomingItems />
      </Tab>
      <Tab eventKey="contact" title="История">
        <ManagementSpbHistory />
      </Tab>
    </Tabs>
  )

}

export default ManagementNav
