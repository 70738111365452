import React, {useEffect, useContext} from 'react'
import "bootstrap/dist/css/bootstrap.css";
import {Container, Row, Col, Table, Spinner, Button} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Logout from '../login/Logout';
import UserInfo from '../general/UserInfo';
import { DeskChangeBtn } from '../general/DeskChangeBtn';
import { UserContext } from '../UserContextManager';
import { AccountingContext } from '../AccountingContextManager';
import AccountingNav from './AccountingNav';
import axios from 'axios';
import Sticky from 'react-stickynode';
import { useState } from 'react';

function AccountingDesk(){

  const uContext = useContext(UserContext);
  const aContext = useContext(AccountingContext);
  
  const [tableVariant, setTableVariant] = useState('white')

  const navigate = useNavigate();

  const visibleStyle = {border: 0, backgroundColor: '#dc3545', visibility: 'visible', color: 'white'}
  const invisibleStyle = {border: 0, backgroundColor: 'white', visibility: 'hidden'}

  const loadUserData = async () => {

    let userDataRes = await axios.get('/redesk/general/remainder');

    let data = await userDataRes.data;

    let draftRes = await axios.get('/redesk/draft');

    let draftData = await draftRes.data;

    data.user_data['draft_data'] = draftData.draft_data;

    uContext.setUserData(data.user_data);

    let roles = data.user_data.role.split(';')
    if (roles.includes('accounting')){
      navigate('/accounting')    
    }else{
      navigate('/');
    }
  }

  const handleSticky = (status) => {
    if(status.status === Sticky.STATUS_FIXED){
      setTableVariant('dark')
    }else{
      setTableVariant('white')
    }
  }

  const superAction = (param) =>
  {
    console.log(param);
  }
  
  useEffect(() => {
    loadUserData();
    return () => {}
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
    <Container fluid>
      <Row className="justify-content-center" style={{'marginTop': '5px'}} >
        <Col xs={6} style={{textAlign: "left"}}>
          <UserInfo />
        </Col>
        <Col xs={6} style={{textAlign: "right"}}>
          {uContext.userData.role.split(';').length > 1 ? 
          (<DeskChangeBtn />) : (<></>)
          }
          <Logout />
        </Col>
      </Row>
      <Sticky enabled={false} innerZ={10} onStateChange={handleSticky}>
      <Row className="justify-content-center">
        <Col xs={12} lg={10} style={{fontSize: '14px'}}>
        {aContext.helpersData.helpers.length > 0 ? (
            
          <Table variant={tableVariant} id='ast' className="accnt-desk-toptable" striped bordered size="sm" responsive >
            <thead>
              <tr>
                <th className='col-priority-0'>*</th>
                <th className='col-priority-2'>ИП СБ Дизайн</th>
                <th className='col-priority-2'>ИП Альфа Дизайн</th>
                <th className='col-priority-1'>Точка МСК-Ремонт</th>
                <th className='col-priority-1'>Райф РР</th>
                <th className='col-priority-1'>Сбер РР</th>
                <th className='col-priority-1'>Сбер МР</th>
                <th className='col-priority-1'>Райф ТСС</th>
                <th className='col-priority-1'>Альфа ТСС</th>
                <th className='col-priority-2'>Райф Флагман</th>
                <th className='col-priority-1'>МСК-Ремонт</th>
                <th className='col-priority-2'>Фаворит групп</th>
                <th className='col-priority-2'>ИП Бахарев</th>
                <th className='col-priority-2'>Альфа ИП Бахарев</th>
              </tr>
            </thead>
            <tbody>
              {
                aContext.helpersData.helpers.map((item) => (
                  <tr key={item.metric}>
                    <td className='col-metric col-priority-0'>{item.metric}</td>
                    <td className='col-value col-priority-2'>{window.redesk_app.common.fmtFinValue.format(item.ip_sb_design)}</td>
                    <td className='col-value col-priority-2'>{window.redesk_app.common.fmtFinValue.format(item.ip_alfa_design)}</td>
                    <td className='col-value col-priority-1'>{window.redesk_app.common.fmtFinValue.format(item.t_msc_remont)}</td>
                    <td className='col-value col-priority-1'>{window.redesk_app.common.fmtFinValue.format(item.raif_rr)}</td>
                    <td className='col-value col-priority-1'>{window.redesk_app.common.fmtFinValue.format(item.sber_rr)}</td>
                    <td className='col-value col-priority-1'>{window.redesk_app.common.fmtFinValue.format(item.sber_mr)}</td>
                    <td className='col-value col-priority-1'>{window.redesk_app.common.fmtFinValue.format(item.raif_tss)}</td>
                    <td className='col-value col-priority-1'>{window.redesk_app.common.fmtFinValue.format(item.alfa_tss)}</td>
                    <td className='col-value col-priority-2'>{window.redesk_app.common.fmtFinValue.format(item.raif_flagman)}</td>
                    <td className='col-value col-priority-1'>{window.redesk_app.common.fmtFinValue.format(item.msc_remont)}</td>
                    <td className='col-value col-priority-2'>{window.redesk_app.common.fmtFinValue.format(item.favorite_group)}</td>
                    <td className='col-value col-priority-2'>{window.redesk_app.common.fmtFinValue.format(item.ip_baharev)}</td>
                    <td className='col-value col-priority-2'>{window.redesk_app.common.fmtFinValue.format(item.ip_baharev_alfa)}</td>
                  </tr>
                ))
              }
              {
                aContext.helpersData.excerptCheck.map((item) => (
                <tr style={{border: 0}} key={item.metric}>
                  <td className='col-value col-priority-0' style={invisibleStyle}></td>
                  <td className='col-value col-priority-2' style={Math.abs(item.ip_sb_design.toFixed(2)) > 1 ? visibleStyle : invisibleStyle}>
                    {window.redesk_app.common.fmtFinValue.format(item.ip_sb_design)}
                  </td>
                  <td className='col-value col-priority-2' style={Math.abs(item.ip_alfa_design.toFixed(2)) > 1 ? visibleStyle : invisibleStyle}>
                    {window.redesk_app.common.fmtFinValue.format(item.ip_alfa_design)}
                  </td>
                  <td className='col-value col-priority-1' style={Math.abs(item.t_msc_remont.toFixed(2)) > 1 ? visibleStyle : invisibleStyle}>
                    {window.redesk_app.common.fmtFinValue.format(item.t_msc_remont)}
                  </td>
                  <td className='col-value col-priority-1' style={Math.abs(item.raif_rr.toFixed(2)) > 1 ? visibleStyle : invisibleStyle}>
                    {window.redesk_app.common.fmtFinValue.format(item.raif_rr)}
                  </td>
                  <td className='col-value col-priority-1' style={Math.abs(item.sber_rr.toFixed(2)) > 1 ? visibleStyle : invisibleStyle}>
                    {window.redesk_app.common.fmtFinValue.format(item.sber_rr)}
                  </td>
                  <td className='col-value col-priority-1' style={Math.abs(item.sber_mr.toFixed(2)) > 1 ? visibleStyle : invisibleStyle}>
                    {window.redesk_app.common.fmtFinValue.format(item.sber_mr)}
                  </td>
                  <td className='col-value col-priority-1' style={Math.abs(item.raif_tss.toFixed(2)) > 1 ? visibleStyle : invisibleStyle}>
                    {window.redesk_app.common.fmtFinValue.format(item.raif_tss)}
                  </td>
                  <td className='col-value col-priority-1' style={Math.abs(item.alfa_tss.toFixed(2)) > 1 ? visibleStyle : invisibleStyle}>
                    {window.redesk_app.common.fmtFinValue.format(item.alfa_tss)}
                  </td>
                  <td className='col-value col-priority-2' style={Math.abs(item.raif_flagman.toFixed(2)) > 1 ? visibleStyle : invisibleStyle}>
                    {window.redesk_app.common.fmtFinValue.format(item.raif_flagman)}
                  </td>
                  <td className='col-value col-priority-1' style={Math.abs(item.msc_remont.toFixed(2)) > 1 ? visibleStyle : invisibleStyle}>
                    {window.redesk_app.common.fmtFinValue.format(item.msc_remont)}
                  </td>
                  <td className='col-value col-priority-2' style={Math.abs(item.favorite_group.toFixed(2)) > 1 ? visibleStyle : invisibleStyle}>
                    {window.redesk_app.common.fmtFinValue.format(item.favorite_group)}
                  </td>
                  <td className='col-value col-priority-2' style={Math.abs(item.ip_baharev.toFixed(2)) > 1 ? visibleStyle : invisibleStyle}>
                    {window.redesk_app.common.fmtFinValue.format(item.ip_baharev)}
                  </td>
                  <td className='col-value col-priority-2' style={Math.abs(item.ip_baharev_alfa.toFixed(2)) > 1 ? visibleStyle : invisibleStyle}>
                    {window.redesk_app.common.fmtFinValue.format(item.ip_baharev_alfa)}
                  </td>
                </tr>
              ))
            }
            </tbody>
          </Table>
          
        ) : (<Col lg={12} style={{textAlign: "center"}}><div><Spinner animation="border" variant="primary"/></div></Col>)}

        </Col>
      </Row>
      </Sticky>
      <Row className="justify-content-center" >
        <Col lg={12} className='p-0 mb-0'>
          <AccountingNav />
        </Col>
      </Row>
    </Container>
    </>
  );
}

export default AccountingDesk
