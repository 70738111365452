import React, { useState, useEffect, useContext } from 'react';
import "bootstrap/dist/css/bootstrap.css";
import Image from 'react-bootstrap/Image'
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import {Col, OverlayTrigger, Tooltip, Form} from "react-bootstrap";
import NoUserIcon from '../../no_user_icon.jpeg';
import { UserContext } from '../UserContextManager';
import { useNavigate } from "react-router-dom";
import sha256 from 'js-sha256';
import axios from 'axios';
import cookies from 'js-cookie';

function UserInfo(){

  const navigate = useNavigate();

  const uContext = useContext(UserContext);

  const fileInputRef = React.createRef();

  const [profileImagePath, setProfileImagePath] = useState(NoUserIcon);

  const [hoverStatus, setHoverStatus] = useState(false);
  const [focusStatus, setFocusStatus] = useState(false);

  const [showImageChangeModal, setShowImageChangeModal] = useState(false);
  const [showPasswordChangeModal, setShowPasswordChangeModal] = useState(false);

  const [passChangeData, setPassChangeData] = useState({'current_password':'', 'new_password':'', 'new_password_confirm':''});

  const imageStyle = hoverStatus ?
    { width: '32px', marginRight: '10px', outline: 'solid', outlineWidth: '2px', outlineColor: '#0d6efd'} :
    focusStatus ?
    { width: '32px', marginRight: '10px', outline: 'solid', outlineWidth: '2px', outlineColor: '#0d6efd'} :
    { width: '32px', marginRight: '10px'}

  const nameStyle = hoverStatus ?
  { display: 'inline', color: '#0d6efd' } :
  focusStatus ?
  { display: 'inline', color: '#0d6efd' } :
  { display: 'inline', color: 'black' }

  const getProfilePicture = async () => {
    try {
      let res = await axios.get(`redesk/profile/user-image?cache=${Math.random()}`, {responseType: 'blob'});
      setProfileImagePath(URL.createObjectURL(res.data));
    }catch {
      setProfileImagePath(NoUserIcon);
    }
  }

  const uploadNewProfilePicture = async (e) => {

    let formData = new FormData();
    formData.append('profile_picture', e.target.files[0]);
    e.target.value=null;
    await axios.post('/redesk/profile/user-image', formData, {headers:{'X-CSRF-TOKEN': cookies.get("csrf_access_token")}});

    getProfilePicture();

  }

  const deleteProfilePicture = async () => {
    await axios.delete('/redesk/profile/user-image', {headers:{'X-CSRF-TOKEN': cookies.get("csrf_access_token")}});
    getProfilePicture();
  }

  const updatePassChangeData = (e) => {
    setPassChangeData({...passChangeData, [e.target.name]: e.target.value});
  }

  const changePassword = async () => {

    if (passChangeData['current_password'].length < 1){
      alert('Введите текущий пароль');
      return
    }

    if (passChangeData['new_password'].length < 6 || passChangeData['new_password_confirm'] < 6){
      alert('Новый пароль не может быть короче 6-ти символов');
      return
    }

    if (passChangeData['new_password'] !== passChangeData['new_password_confirm']){
      alert('Новые пароли не совпадают');
      return
    }

    let currentPassData = {...passChangeData}
    currentPassData['current_password'] = sha256(passChangeData['current_password'])
    currentPassData['new_password'] = sha256(passChangeData['new_password'])
    currentPassData['new_password_confirm'] = sha256(passChangeData['new_password_confirm'])

    try{
      await axios.post('/redesk/profile/change-password', currentPassData, {headers:{'X-CSRF-TOKEN': cookies.get("csrf_access_token")}});
      setShowPasswordChangeModal(false);
      navigate('/');
    }catch{
      alert('Неверно введен текущий пароль');
      return
    }
  }

  useEffect(() => {
    getProfilePicture();
    return () => {}
  }, [])

  return(
    <>
    <Dropdown
      style={{maxWidth: '100px'}}
      onClick={() => {setFocusStatus(true);}}
      onBlur={() => setFocusStatus(false)}
    >
      <Dropdown.Toggle
        onToggle={() => alert('fsd')}
        style={{ width : '100px', cursor: 'pointer', backgroundColor: 'white', border: 'none', boxShadow: 'none' }}
        onMouseEnter={() => {setHoverStatus(true);}}
        onMouseLeave={() => {setHoverStatus(false);}}
      >
        <Image
         src={profileImagePath}
         roundedCircle={true}
         style={imageStyle}
        />
        <p style={nameStyle}>
          {uContext.userData.abbreviation}
        </p>

      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item onClick={() => {setShowImageChangeModal(true); setFocusStatus(false);}}>Загрузить изображение</Dropdown.Item>
        <Dropdown.Item onClick={() => {setShowPasswordChangeModal(true); setFocusStatus(false);}}>Изменить пароль</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>

    <Modal style={{textAlign: 'center'}} show={showImageChangeModal} onHide={() => {setShowImageChangeModal(false); setFocusStatus(false);}}>
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body>
        <Col xs={12}>
          <Image
            src={profileImagePath}
            roundedCircle={true}
            style={{ width: '200px'}}
          />
        </Col>
        <Col xs={12} style={{marginTop: '30px'}}>
          <input ref={fileInputRef} onChange={uploadNewProfilePicture} className="d-none" type="file" accept='.jpg, .jpeg, .png'/>
          <OverlayTrigger
            trigger={['hover', 'focus']}
            placement='top'
            rootClose='true'
            overlay={
              <Tooltip>
                jpg, png
              </Tooltip>
            }
          >
            <Button onClick={() => {fileInputRef.current?.click();}} variant="primary">Выбрать новое изображение</Button>
          </OverlayTrigger>
        </Col>
        <Col xs={12} className="mt-3">
          <Button onClick={deleteProfilePicture} variant="danger">Удалить</Button>
        </Col>
      </Modal.Body>
    </Modal>
    <Modal style={{textAlign: 'center'}} show={showPasswordChangeModal} onHide={() => {setShowPasswordChangeModal(false); setFocusStatus(false);}}>
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body>
        <Form.Group as={Col} xs='12' className="mb-3" controlId="p_ch_c_p">
          <Form.Control size="sm" defaultValue='' name="current_password" placeholder="Текущий пароль" type='password' onInput={updatePassChangeData}/>
        </Form.Group>
        <Form.Group as={Col} xs='12' className="mb-3" controlId="p_ch_n_p">
          <Form.Control size="sm" defaultValue='' name="new_password" placeholder="Новый пароль" type='password' onInput={updatePassChangeData}/>
        </Form.Group>
        <Form.Group as={Col} xs='12' className="mb-3" controlId="p_ch_n_p_c">
          <Form.Control size="sm" defaultValue='' name="new_password_confirm" placeholder="Подтвердите новый пароль" type='password' onInput={updatePassChangeData}/>
        </Form.Group>
        <Button variant="primary" className="mb-3 mt-2 mx-auto" onClick={changePassword}>
          Изменить
        </Button>
      </Modal.Body>
    </Modal>
    </>
  );
}

export default UserInfo
