const CalcByClick = class {
    constructor(setSum, itemsSelector) {
        this.itemsSelector = itemsSelector;
        this.sum = 0;
        this.items = [];
        this.itemClass = 'calc-by-click';
        this.setSum = setSum;
    };

    onItemClick = (event) => {
        if (event.ctrlKey | event.metaKey)
        {
            this.toggleItem(event.target);
        }
    }

    onResultClick = (event) => {
        if (event.ctrlKey | event.metaKey)
        {
            this.clear();
        }
    }

    toggleItem = (item) => {
        item.classList.toggle( this.itemClass );
        this.recalc();
    }

    recalc = () => {

        let items = document.querySelectorAll(this.itemsSelector + '.' + this.itemClass);
        this.items = [];
    
        items.forEach(item => {
            if( item.dataset.hasOwnProperty('calcbyclickvalue') )
            {
                this.items.push( item.dataset['calcbyclickvalue'] );
            }
            else
            {
                console.error( 'Clicked item does not have calcbyclickvalue in dataset!' );
                console.error( item );
            }
            
        });
    
        this.sum = 0;
    
        for (let index = 0; index < this.items.length; index++) {
          if( Number( this.items[index] ) )
          {
            this.sum = this.sum + Number( this.items[index] );
          }
        }
    
        this.setSum( this.sum );
    }

    clear = () => {
      
        document.querySelectorAll( this.itemsSelector + '.' + this.itemClass ).forEach(item => {
            this.toggleItem(item);
        });

        this.sum = 0;
        this.items = [];
        this.setSum( this.sum );
    }
    
};

export {CalcByClick};