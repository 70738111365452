import React, {useEffect, useState} from 'react';
import "bootstrap/dist/css/bootstrap.css";
import Draft from '../general/Draft';
import {Container, Row, Col, ToggleButton, ToggleButtonGroup} from "react-bootstrap";
import MechMainTerminal from './MechMainTerminal'

function MechTerminal(){


  useEffect(() => {
    return () => {}
  }, [])


  return(
    <Container fluid className="shadow pt-2">
      <Row>
        <Col xs={12}>
            <MechMainTerminal />
        </Col>
        <Col xs={12}>
          <Draft />
        </Col>
      </Row>
    </Container>
  )

}

export default MechTerminal;
