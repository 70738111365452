import React, { useState } from 'react'

export const NPContext = React.createContext({
  helpersData: {
    remainder_main: 0,
    incoming: 0,
    outcoming: 0,
    main_object_helper: 0,
    np_object_helper: 0
  },
  setHelpersData: () => {},
})

export const NPContextProvider = (props) => {
  const setHelpersData = (helpersData) => {
    setState({...state, helpersData: helpersData})
  }


  const initState = {
    helpersData: {
        remainder_main: 0,
        incoming: 0,
        outcoming: 0,
        main_object_helper: 0,
        np_object_helper: 0
    },
    setHelpersData: setHelpersData
  }

  const [state, setState] = useState(initState)

  return (
    <NPContext.Provider value={state}>
      {props.children}
    </NPContext.Provider>
  )
}
