import React, {useState, useEffect, useContext} from 'react';
import "bootstrap/dist/css/bootstrap.css";
import {Row, Col, Button, Table, Modal, Form, Container, ToggleButtonGroup, ToggleButton, Spinner, InputGroup} from "react-bootstrap";
import { UserContext } from '../UserContextManager'
import axios from 'axios';
import cookies from 'js-cookie';

function AccountingDepositIP(){


  const [isLoading, setIsLoading] = useState(false)

  const [depositData, setDepositData] = useState([]);

  const loadDepositData = async () => {

    setIsLoading(true)

    let res = await axios.get('/redesk/accounting/deposit')

    setDepositData(res.data.deposit_data)
    setIsLoading(false)
  }

  const handleTransfer = async (deposit, value) => {
    
    let dpCopy = [...depositData]
    let di = dpCopy.findIndex(x => x.deposit === deposit)

    dpCopy[di].transfer = value

    let floatValue = 0
    if(value){
        try{
            floatValue = parseFloat(value.replace(',','.'))
        }catch{
            floatValue = 0
        }
    }
    
    //console.log(floatValue)
    //console.log(value)

    let new_remainder = dpCopy[di].deposited - dpCopy[di].withdrawn - floatValue

    dpCopy[di].remainder = new_remainder.toFixed(2)

    setDepositData(dpCopy)

    await axios.patch('/redesk/accounting/deposit/save-transfer', {deposit: deposit, value: value}, {headers:{'X-CSRF-TOKEN': cookies.get("csrf_access_token")}});
  }

  useEffect(() => {
    loadDepositData();
    return () => {}
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  return(
    <Container fluid className="pt-2">
      <Row>
        <Col lg={2}></Col>
        {depositData.length > 0 && !isLoading ? 
        (
        <Col lg={8}>
            <Table striped bordered size="sm" responsive className='rd-accounting'>
              <thead class="align-middle">
                <tr>
                  <th>ИП</th>
                  <th>Зачислено на депозит</th>
                  <th>Списано с депозита</th>
                  <th>Остаток</th>
                  <th>Перевод в нал</th>
                </tr>
              </thead>
              <tbody>
              {
                depositData.map((item) => (
                <tr key={item.deposit} class="align-middle">
                    <td className='col-centred'>{item.deposit}</td>
                    <td className='col-value'>{window.redesk_app.common.fmtFinValue.format(item.deposited)}</td>
                    <td className='col-value'>{window.redesk_app.common.fmtFinValue.format(item.withdrawn)}</td>
                    <td className='col-value'>{window.redesk_app.common.fmtFinValue.format(item.remainder)}</td>
                    <td>
                      <InputGroup>
                        <Form.Control
                            size="sm" name="comment" data-deposit-id={item.deposit} defaultValue={item.transfer}
                        />
                        <Button
                          variant="outline-primary"
                          id="button-addon2"
                          size="sm"
                          onClick={(e) => handleTransfer(e.target.previousSibling.dataset.depositId, e.target.previousSibling.value)}
                        > ✓ </Button>
                      </InputGroup>
                    </td>
                </tr>
                  ))
                }
              </tbody>
            </Table>
        </Col>
        ) : 
        (
        <Col lg={8} style={{textAlign: "center"}}>
            <div style={{marginTop: "15%", marginBottom: "15%"}}>
                <Spinner animation="border" variant="primary"/>
            </div>
        </Col>
        )
        }
        <Col lg={2}></Col>
      </Row>
    </Container>
  )
}

export default AccountingDepositIP;
