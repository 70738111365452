import React, {useEffect, useState} from 'react';
import "bootstrap/dist/css/bootstrap.css";
import Draft from '../general/Draft';
import {Container, Row, Col, ToggleButton, ToggleButtonGroup} from "react-bootstrap";
import FundMainTerminal from './FundMainTerminal'
import FotStatement from '../general/FotStatement';

function FundTerminal(){

  const [terminalType, setTerminalType] = useState('terminal-main')

  useEffect(() => {
    return () => {}
  }, [])


  return(
    <Container fluid className="shadow pt-2">
      <Row>
      <Col xs={6} style={{textAlign: "left"}}>
        </Col>
        <Col xs={12}>
          <FundMainTerminal />
        </Col>
        <Col xs={12}>
          <Draft />
        </Col>
      </Row>
    </Container>
  )

}

export default FundTerminal;
