import React, {useState} from 'react'
import { useNavigate } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.css";
import {Button, Modal} from "react-bootstrap";
import cookies from 'js-cookie';
import axios from 'axios';


function Logout(){

  const [logOutModal, setLogOutModal] = useState(false)

  const navigate = useNavigate();

  const onSuccess = async () => {

    try{
      await axios.post('/redesk/profile/logout', {}, {headers:{'X-CSRF-TOKEN': cookies.get("csrf_access_token")}});
      cookies.remove('csrf_access_token');
      navigate("/");
    }catch{
      alert('Не удалось выйти из аккаунта');
    }

  };


  return (

    <>
    <Button variant="outline-warning" size="sm" onClick = {() => setLogOutModal(true)}>
    Выход
    </Button>

      <Modal centered show={logOutModal} onHide={() => setLogOutModal(false)}>
        <Modal.Body>
          <p>Вы действительно хотите выйти?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick = {() => setLogOutModal(false)}>
            Нет
          </Button>
          <Button variant="danger" style={{minWidth: "12%"}} onClick = {onSuccess}>
            Да
          </Button>
        </Modal.Footer>
      </Modal>
    </>

  );

}

export default Logout;
