import React, {useState, useEffect} from 'react';
import "bootstrap/dist/css/bootstrap.css";
import {Row, Col, Button, Form} from "react-bootstrap";
import CurrencyInput from 'react-currency-input-field';

function DoorInstalation(props){

  const [paymentTypeValue, setPaymentTypeValue] = useState('Нал');
  const [fotDoor, setFotDoor] = useState(0)


  const updateFormData = (k, v) => {
    props.updateFormData(k, v);
  }

  const setFormDataDict = (data) => {
    props.setFormDataDict(data)
  }

  const setFormData = (data) => {
    props.setFormData(data)
  }

  const removeAddon = (e) => {
    props.removeAddon(e);
  }

  const addDropdownAddon = (e) => {
    props.addDropdownAddon(e);
  }

  const calculateFSMP = (e) => {

    if (e === undefined){
      
      setFotDoor(0);
      setFormDataDict({'terminal_fot_door_fot':0, 'terminal_fot_door':0})
      return
    }

    let fsmp = parseFloat(e.replace(',', '.')) > 0 ? (Math.ceil(parseFloat(e.replace(',', '.')) * 0.5)): 0; 
    setFotDoor(fsmp);
    setFormDataDict({'terminal_fot_door_fot':fsmp, 'terminal_fot_door':e})
  }

  useEffect(() => {
    setFormDataDict({
      'terminal_fot_door_item_type':'Приход',
      'terminal_fot_door_payment_type':'Нал',
    })

    return () => {}
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return(
    <>
      <Row className='border border-primary rounded-4' style={{boxShadow: '0px 5px 10px 2px rgba(34, 60, 80, 0.2)', padding:0, paddingTop: '5px', marginLeft: '1px', marginBottom: '15px'}}>
        <Col xs={6}>
          <Form.Group className="mb-3">
            <Form.Label>Установка дверей</Form.Label>
            <CurrencyInput
              id="terminal_fot_door"
              name="terminal_fot_door"
              className='form-control'
              style={{maxHeight: '30px'}}
              placeholder="Сумма"
              decimalsLimit={2}
              allowNegativeValue={false}
              groupSeparator={' '}
              onValueChange={(e) => {calculateFSMP(e)}}
            />
          </Form.Group>
        </Col>
        <Col xs={6}>
          <Form.Group className="mb-3">
            <Form.Label>ФОТ установка дверей</Form.Label>
            <Form.Control size="sm" value={fotDoor} name="terminal_fot_door_fot" placeholder={fotDoor} disabled />
          </Form.Group>
        </Col>
        <Col xs={6}>
          <Form.Group className="mb-3">
            <Form.Label>Тип платежа ФОТ</Form.Label>
            <Form.Select size="sm" name="terminal_fot_door_payment_type" value={paymentTypeValue} onChange={(e) => {setPaymentTypeValue(e.target.value); updateFormData('terminal_fot_door_payment_type', e.target.value)}} >
              {props.dropdownData.map(({value, label, dropdown_name}, index) => {if(dropdown_name === 'terminal_payment_type'){
                if(value === 'Нал'){
                return <option selected value={value}>{label}</option>
              }else{
                return <option value={value}>{label}</option>
              }}return null})}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col xs={5}>
          <Form.Group className="mb-3" controlId="t_c">
              <Form.Label>Примечание</Form.Label>
              <Form.Control size="sm" name={`terminal_fot_door_comment`} placeholder="Примечание" type="text" onChange={(e) => {updateFormData('terminal_fot_door_comment', e.target.value)}} />
          </Form.Group>
        </Col>
        <Col xs={1} className="pt-4 justify-content-center">
          <Button variant="danger" list-key="Установка дверей" className="mb-3 mt-2" size="sm" onClick={(e) => {let oldData = {...props.formData}; delete oldData['terminal_fot_door']; delete oldData['terminal_fot_door_fot']; delete oldData['terminal_fot_door_item_type']; delete oldData['terminal_fot_door_payment_type']; setFormData(oldData); removeAddon(e); addDropdownAddon(e)}}>
            -
          </Button>
        </Col>
      </Row>
    </>
  );
}

export default DoorInstalation
