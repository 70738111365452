import axios from "axios";
import cookies from "js-cookie";

const OutlaysBlock = async (outlays) => {

  const allowedMetrics = [
    "Основные работы",
    "СМП",
    "Установка дверей",
    "Монтаж НП",
    "Клининг",
  ];

  let collection = outlays.filter(
    x =>
      x.item == "Выручка" &&
      x.item_type == "Приход" &&
      x.sub_item !== "Акции" &&
      x.metric_4 !== '' &&
      x.metric_4 !== null &&
      allowedMetrics.includes(x.work_type)
  );

  if (collection.length === 0) {
    return;
  }

  await axios.post(
    "/redesk/amega/block-outlays",
    {
      outlays: collection,
    },
    { headers: { "X-CSRF-TOKEN": cookies.get("csrf_access_token") } }
  );

};

export { OutlaysBlock };
