import React, {useEffect} from 'react';
import "bootstrap/dist/css/bootstrap.css";
import { Typeahead, Highlighter } from 'react-bootstrap-typeahead';
import {Row, Col, Button, Form} from "react-bootstrap";

function ToolWithholding(props){

  const setFormDataDict = (data) => {
    props.setFormDataDict(data)
  }

  const setFormData = (data) => {
    props.setFormData(data)
  }

  const removeAddon = (e) => {
    props.removeAddon(e);
  }

  const addDropdownAddon = (e) => {
    props.addDropdownAddon(e);
  }

  useEffect(() => {
    setFormDataDict({
      'terminal_fot_toolw_item_type':'Приход',
    })

    return () => {}
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return(
    <>
      <Row className='border border-primary rounded-4' style={{boxShadow: '0px 5px 10px 2px rgba(34, 60, 80, 0.2)', padding:0, paddingTop: '5px', marginLeft: '1px', marginBottom: '15px'}}>
        <Col xs={6}>
          <Form.Group className="mb-3">
            <Form.Label>Удержание за инструмент</Form.Label>
            <Form.Control size="sm" onKeyPress={(e) => {!/[0-9]/.test(e.key) && e.preventDefault()}} onInput={(e) => {setFormDataDict({'terminal_fot_toolw':e.target.value});}} name='terminal_fot_toolw' placeholder="Сумма"/>
          </Form.Group>
        </Col>
        <Col xs={6}>
          <Form.Group as={Col} className="mb-3" controlId='t_f_tw'>
            <Form.Label>ФИО мастера</Form.Label>
            <Typeahead
              id='terminal_fot_toolw_master'
              name='terminal_fot_toolw_master'
              labelKey='value'
              placeholder='ФИО мастера'
              maxResults={7}
              emptyLabel='Нет совпадений'
              paginationText='Показать больше'
              highlightClassName='font-weight-bold'
              onChange={(selected) => props.onChange(selected[0], `terminal_fot_toolw_master`)}
              onInputChange={(text) => props.onChange(text, `terminal_fot_toolw_master`)}
              options={props.options}
              size='sm'
              renderMenuItemChildren={(option, props, idx) => (
                <Highlighter search={props.text} highlightClassName='fw-bold mx-0 px-0 bg-transparent'>
                  {option}
                </Highlighter>
              )}
            />
          </Form.Group>
        </Col>
        <Col xs={6}>
        </Col>
        <Col xs={5}>
        <Form.Group className="mb-3" controlId="t_c">
            <Form.Label>Примечание</Form.Label>
            <Form.Control size="sm" name={`terminal_fot_toolw_comment`} placeholder="Примечание" type="text" onChange={(e) => {setFormDataDict({'terminal_fot_toolw_comment': e.target.value})}} />
        </Form.Group>
        </Col>
        <Col xs={1} className="pt-4 justify-content-center">
          <Button variant="danger" list-key="Удержание за инструмент" className="mb-3 mt-2" size="sm" onClick={(e) => {let oldData = {...props.formData}; delete oldData['terminal_fot_toolw']; delete oldData['terminal_fot_toolw_master'];  delete oldData['terminal_fot_toolw_item_type']; setFormData(oldData); removeAddon(e); addDropdownAddon(e)}}>
            -
          </Button>
        </Col>
      </Row>
    </>
  );
}

export default ToolWithholding
