import "bootstrap/dist/css/bootstrap.css";
import {Container, Row, Col, Card} from "react-bootstrap";

function NoMatch(){

  return(
    <Container fluid>
      <Row className="justify-content-center" style={{'marginTop': '15%'}}>
        <Col xs={12} sm={10} lg={5}>
          <Card style={{'textAlign': 'center'}}>
            <Card.Header>
              <h4>Ошибка</h4>
            </Card.Header>
            <Card.Body>
              <h2>Такой страницы не существует</h2>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}


export default NoMatch
