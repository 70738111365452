import React, {useState, useEffect, useContext} from 'react';
import "bootstrap/dist/css/bootstrap.css";
import { Typeahead, Highlighter } from 'react-bootstrap-typeahead';
import {Row, Col, Button, Stack, Form, Modal} from "react-bootstrap";
import { UserContext } from '../UserContextManager';
import { NUContext } from '../NUHelpersManager';
import axios from 'axios';
import cookies from 'js-cookie';
import CurrencyInput from 'react-currency-input-field';

function TransitionPremium(){

  const uContext = useContext(UserContext);
  const nContext = useContext(NUContext);

  const[resposibleKey, setResponsiblekey] = useState(0);

  const [dropdownData, setDropdownData] = useState([]);
  const [objectData, setObjectData] = useState([]);
  const [responsibleUsersList, setResponsibleUsersList] = useState([]);
  const [reasOptions, setReasOptions] = useState([]);
  const [paymentType, setPaymentType] = useState('Нал');
  const [workType, setWorkType] = useState('Вид работы');
  const [subitem, setSubItem] = useState('Подстатья');
  const [sum, setSum] = useState('');
  const [itemType, setItemType] = useState('Приход')
  const [item, setItem] = useState('Метрика 1')

  const [maxSum, setMaxSum] = useState(0);
  const [responsibleUser, setResponsibleUser] = useState('')
  const [selectedMaster, setSelectedMaster] = useState('')

  const [objectDetails, setObjectDetails] = useState({})
  const [masterDetails, setMasterDetails] = useState({})

  const [formData, updateFormData] = useState({});

  const [masterData, setMasterData] = useState([]);


  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmModalText, setConfirmModalText] = useState('');

  const objectRef = React.createRef();
  const responsibleRef = React.createRef();

  const loadResponsibleUsersList = async () => {
    let res = await axios.get('/redesk/terminal/responsible-users');
    setResponsibleUsersList(res.data.responsible_users);
  }

  const loadObjectList = async () => {
    let res = await axios.get('/redesk/nu/transfer-premium/objects');
    setObjectData(res.data.objects_data);
  }

  const loadObjectDetails = async (objectId) => {

    updateFormData({...formData, ['terminal_object_id']: objectId})

    if (!objectData.includes(objectId)){
      setObjectDetails({});
      setMasterDetails({});
      setSelectedMaster('');
      setMaxSum(0)
      setSum('')
      return
    }

    let res = await axios.get(`/redesk/nu/transfer-premium/objects/${objectId}`);
    setObjectDetails(res.data.object_data)

    responsibleUsersList.includes(res.data.object_data.responsible) ? setResponsibleUser(res.data.object_data.responsible) : setResponsibleUser('')

    
  }

  const handleMasterInput = (objectId, master) => {
    loadMasterDetails(objectId, master)
  }

  const handleMasterSelect = (objectId, master) => {
    if(!master){
      return
    }

    loadMasterDetails(objectId, master)
  }

  const loadMasterDetails = async (objectId, master) => {

    setSelectedMaster(master);

    if (!objectData.includes(objectId) || !objectDetails.masters || !objectDetails.masters.includes(master)){
      setMasterDetails({})
      setMaxSum(0)
      setSum('')
      return
    }

    let res = await axios.get(`/redesk/nu/transfer-premium/master-details`, {params: {master: master, object_id: objectId}});
    
    setMasterDetails(res.data.master_details)

    let maxSum = Math.round((Math.abs(res.data.master_details.whole_bonus) - Math.abs(res.data.master_details.previous_bonus))/10)*10
    setMaxSum(maxSum)
  }

  const handleSum = (e) => {

    if (parseFloat(e) > maxSum){
      setSum(maxSum)
      updateFormData({...formData, ['terminal_sum']: maxSum.toString()})
    }else{
      e === undefined ? setSum('') : setSum(e)
      updateFormData({...formData, ['terminal_sum']: e})
    }

  }

  const handleResponsibleSelect = (responsible) => {
    if(!responsible){
      return
    }
    setResponsibleUser(responsible);
    updateFormData({...formData, ['terminal_responsible_user']: responsible})
  }

  const handleResponsibleInput = (responsible) => {

    setResponsibleUser(responsible);
    updateFormData({...formData, ['terminal_responsible_user']: responsible})
  }

  const handleChange = (e, k=null) => {
    if (k !== null){
      updateFormData({...formData, [k]: e})
    }else{
      updateFormData({...formData, [e.target.name]: e.target.value})
    }
  }

  const compareDraft = () => {

    let draftCopy = [...uContext.userData.draft_data]
    
    let has_duplicate = false
    draftCopy.forEach((x) => {
      if(
        Math.abs(x.sum) === Number(sum)
        && x.responsible_user === responsibleUser
        && x.object_id === formData.terminal_object_id
        && x.payment_type === paymentType
        && x.metric_4 === selectedMaster
        && x.work_type === item
        && x.item_type === itemType
        ){
        has_duplicate = true
      }
    })

    return has_duplicate
  }

  const handleSubmitWithConfirm = (e) => {
    if (objectData.indexOf(formData.terminal_object_id) < 0 && item !== 'Переброска'){
      alert('ИД объекта указан неправильно!')
      return
    }

    if (!responsibleUsersList.includes(responsibleUser)){
      alert('Ответственный указан неправильно!')
      return
    }

    if (!sum || sum === 0 || sum === '0'){
      alert('Введите сумму!')
      return
    }

    if (!selectedMaster || (selectedMaster && objectDetails && !objectDetails.masters.includes(selectedMaster))){
      alert('Мастер указан неверно!')
      return
    }

    if (item === 'Метрика 1'){
      alert('Укажите метрику!')
      return
    }

    if (compareDraft()){
      setConfirmModalText('Похожая запись уже есть в черновике. Создать еще одну?')
      setShowConfirmModal(true);
      return
    }

    handleSubmit(e)
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    let draftSendData = formData
    draftSendData['cfo'] = 'НУ'
    draftSendData['terminal_item'] = 'ФОТ Мастеров'
    draftSendData['terminal_item_type'] = itemType
    draftSendData['terminal_payment_type'] = paymentType
    draftSendData['terminal_work_type'] = item
    draftSendData['terminal_company_name'] = objectDetails.company_name
    draftSendData['terminal_responsible_user'] = responsibleUser
    draftSendData['terminal_metric_4'] = selectedMaster
    draftSendData['terminal_sum'] = sum.toString()
    

    if (!draftSendData.terminal_responsible_user | !responsibleUsersList.includes(draftSendData.terminal_responsible_user)){

      if(responsibleUsersList.includes(responsibleUser) && item !== 'Переброска'){
        draftSendData['terminal_responsible_user'] = responsibleUser;
      }
    }

    if(draftSendData.temp_master === 'Временный мастер'){
      delete draftSendData['temp_master']
    }

    /*objectRef.current.clear();
    responsibleRef.current.clear();*/

    let res = await axios.post('/redesk/draft/send', draftSendData, {headers:{'X-CSRF-TOKEN': cookies.get("csrf_access_token")}})

    let contextData = uContext.userData;
    contextData.draft_data = res.data.draft_data;
    uContext.setUserData(contextData);

    /*document.getElementById("terminal_main_form").reset();
    setLoanRemainder(0);
    setItemType('Приход');
    setItem('Статья');
    setPaymentType('Нал');
    updateFormData({...{}, 'terminal_payment_type': 'Нал', 'terminal_item_type': 'Приход'});
    setSubItem('Подстатья');
    setWorkType('Вид работы');
    setMasterData([]);
    setCompanyName('');
    setResponsibleUser('Ответственный');
    setSum('');
    let conCopy = nContext.helpersData
    conCopy.main_helper = 0
    conCopy.np_helper = 0
    conCopy.output_helper = 0
    nContext.setHelpersData(conCopy);*/
  }

  useEffect(() => {
    loadObjectList();
    loadResponsibleUsersList();

    return () => {}
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return(
    <React.Fragment>
    <Form id='terminal_main_form' className='mt-2 p-2'>
    <Row>
        <Col lg={4}>
            <Form.Group className="mb-3" controlId="t_o_id">
            <Form.Label>Ид объекта</Form.Label>
            <Typeahead
              id='terminal_object_id'
              name='terminal_object_id'
              ref = {objectRef}
              placeholder='Ид объекта'
              maxResults={7}
              emptyLabel='Нет совпадений'
              paginationText='Показать больше'
              highlightClassName='font-weight-bold'
              onInputChange={(text) => {loadObjectDetails(text);}}
              onChange={(selected) => {loadObjectDetails(selected[0]);}}
              options={objectData}
              size='sm'
              renderMenuItemChildren={(option, props, idx) => (
                <Highlighter search={props.text} highlightClassName='fw-bold mx-0 px-0 bg-transparent'>
                  {option}
                </Highlighter>
              )}
             />
            </Form.Group>
        </Col>
        <Col lg={4}>
            <Form.Group className="mb-3" controlId="t_f_o_a">
            <Form.Label>Адрес</Form.Label>
            <Form.Control size="sm" placeholder={objectDetails.address || ''} name="terminal_object_address" disabled />
            </Form.Group>
        </Col>
        <Col lg={4}>
            <Form.Group className="mb-3" controlId="t_c_n">
            <Form.Label>Юрлицо</Form.Label>
            <Form.Control size="sm" placeholder={objectDetails.company_name || ''} name="terminal_company_name" disabled />
            </Form.Group>
        </Col>
    </Row>
    <Row>
        <Col lg={4}>
            <Form.Group className="mb-3" controlId="t_o_id">
            <Form.Label>ФИО мастера</Form.Label>
            <Typeahead
                id='terminal_master_fio'
                name='terminal_master_fio'
                placeholder='ФИО мастера'
                maxResults={7}
                emptyLabel='Нет совпадений'
                paginationText='Показать больше'
                highlightClassName='font-weight-bold'
                onInputChange={(text) => {handleMasterInput(formData.terminal_object_id, text)}}
                onChange={(selected) => {handleMasterSelect(formData.terminal_object_id, selected[0])}}
                options={objectDetails.masters || []}
                selected={[selectedMaster]}
                size='sm'
                disabled={objectData.includes(formData.terminal_object_id) ? false : true}
                renderMenuItemChildren={(option, props, idx) => (
                <Highlighter search={props.text} highlightClassName='fw-bold mx-0 px-0 bg-transparent'>
                    {option}
                </Highlighter>
                )}
                />
            </Form.Group>
        </Col>
        <Col lg={4}>
        <Form.Group className="mb-3" controlId="t_i_t" >
          <Form.Label>Тип статьи</Form.Label>
          <Form.Select size="sm" name="terminal_item_type" value={itemType} aria-label="terminal_item_type" onChange={(e) => {setItemType(e.target.value); setItem('Метрика 1')}}>
            <option key='Приход' value='Приход'>Приход</option>
            <option key='Расход' value='Расход'>Расход</option>
          </Form.Select>
        </Form.Group>
        </Col>
        <Col lg={4}>
        <Form.Group className="mb-3" controlId="t_m_1" >
          <Form.Label>Метрика 1</Form.Label>
          <Form.Select size="sm" name="terminal_metric_1" value={item} aria-label="terminal_metric_1" onChange={(e) => {setItem(e.target.value)}}>
            <option key='Метрика 1' value='Метрика 1' disabled>Метрика 1</option>
            <option key='Премия за переход' value='Премия за переход'>Премия за переход</option>
            {itemType === 'Расход' ? ( <option key='Удержание премии за переход' value='Удержание премии за переход'>Удержание премии за переход</option>) : null}
          </Form.Select>
        </Form.Group>
        </Col>
    </Row>
    <Row>
        <Col lg={4}>
          <Form.Group as={Col} className="mb-3" controlId="t_r_u">
            <Form.Label>Ответственный</Form.Label>
            <Typeahead
            key={resposibleKey}
            id='terminal_responsible_user'
            name='terminal_responsible_user'
            ref = {responsibleRef}
            labelKey={'value'}
            placeholder='Ответственный'
            maxResults={7}
            emptyLabel='Нет совпадений'
            paginationText='Показать больше'
            highlightClassName='font-weight-bold'
            onChange={(selected) => {handleResponsibleSelect(selected[0])}}
            onInputChange={(text) => {handleResponsibleInput(text)}}
            options={responsibleUsersList}
            size='sm'
            selected={[responsibleUser]}
            renderMenuItemChildren={(option, props, idx) => (
                <Highlighter search={props.text} highlightClassName='fw-bold mx-0 px-0 bg-transparent'>
                {option}
                </Highlighter>
            )}
            />
            </Form.Group>
        </Col>
        <Col lg={4}>
            <Form.Group className="mb-3" controlId="t_s">
            <Form.Label>Сумма премии{(maxSum > 0) ? ` (не более ${maxSum})` : ''}</Form.Label>
            <CurrencyInput
                autoComplete='off'
                id="terminal_sum"
                name="terminal_sum"
                className='form-control'
                style={{maxHeight: '30px'}}
                value={sum}
                placeholder="Сумма премии"
                decimalsLimit={2}
                allowNegativeValue={false}
                groupSeparator={' '}
                onValueChange={(e) => {handleSum(e)}}
                disabled = {maxSum > 0 ? false : true}
            />
            </Form.Group>
        </Col>
        <Col lg={4}>
            <Form.Group className="mb-3" controlId="t_p_t">
            <Form.Label>Тип платежа</Form.Label>
            <Form.Select size="sm" name="terminal_payment_type" aria-label="terminal_payment_type" value={paymentType} onChange={(e) => {handleChange(e); setPaymentType(e.target.value)}} disabled={item === 'Переброска' ? true : false}>
                <option key='Нал' value='Нал'>Нал</option>
                <option key='Безнал' value='Безнал'>Безнал</option>
            </Form.Select>
            </Form.Group>
        </Col>
    </Row>
    <Row>
      <Col lg={4}>
            <Form.Group className="mb-3" controlId="t_c">
            <Form.Label>Примечание</Form.Label>
            <Form.Control size="sm" name="terminal_comment" placeholder="Примечание" autoComplete="off" type="text" onChange={handleChange} />
            </Form.Group>
      </Col>
      <Col xs={4}/>
      <Col xs={4}/>
    </Row>
    <Row>
        <Col lg={4}>
            <Form.Group className="mb-3" controlId="t_i">
            <Form.Label>Сумма ФОТ за поэтапные работы</Form.Label>
            <Form.Control size="sm" defaultValue='' name="terminal_item" placeholder={masterDetails.fot_sum || '0'} disabled />
            </Form.Group>
        </Col>
        <Col lg={4}>
            <Form.Group className="mb-3" controlId="t_i">
            <Form.Label>Общая сумма премии</Form.Label>
            <Form.Control size="sm" defaultValue='' name="terminal_item" placeholder={masterDetails.whole_bonus || '0'} disabled />
            </Form.Group>
        </Col>
        <Col lg={4}>
            <Form.Group className="mb-3" controlId="t_i">
            <Form.Label>Выданная ранее премия</Form.Label>
            <Form.Control size="sm" defaultValue='' name="terminal_item" placeholder={masterDetails.previous_bonus || '0'} disabled />
            </Form.Group>
        </Col>
    </Row>
    <Row className="mt-2">
        <Col lg={12} style={{ textAlign: "center" }}>
            <Button variant="primary" className='mb-3' size='sm' onClick={handleSubmitWithConfirm}>Отправить</Button>
        </Col>
    </Row>  
    </Form>
    <Modal centered show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
      <Modal.Body>
        <p>{confirmModalText}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick = {() => setShowConfirmModal(false)}>
          Нет
        </Button>
        <Button variant="danger" style={{minWidth: "12%"}} onClick={(e) => {handleSubmit(e); setShowConfirmModal(false)}}>
          Да
        </Button>
      </Modal.Footer>
    </Modal>
    </React.Fragment>
  )
}


export default TransitionPremium;
