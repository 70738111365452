import React, {useState, useEffect, useContext} from 'react';
import "bootstrap/dist/css/bootstrap.css";
import {Row, Col, Button, Stack, Form, Modal} from "react-bootstrap";
import { UserContext } from '../UserContextManager';
import axios from 'axios';
import cookies from 'js-cookie';
import CurrencyInput from 'react-currency-input-field';
import { Typeahead, Highlighter } from 'react-bootstrap-typeahead';

function DKMainTerminal(){

  const uContext = useContext(UserContext);

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmModalText, setConfirmModalText] = useState('');

  const [responsibleData, setResponsibleData] = useState([]);
  const [formData, updateFormData] = useState({});

  const [paymentType, setPaymentType] = useState('Тип платежа');
  const [item, setItem] = useState('Статья');
  const [itemType, setItemType] = useState('Тип статьи');
  const [companyName, setCompanyName] = useState('Юрлицо');
  const [objectId, setObjectId] = useState('');
  const [objectData, setObjectData] = useState([]);

  const [metricList, setMetricList] = useState([]);
  const [itemList, setItemList] = useState([])

  const [cfo, setCfo] = useState('ЦФО')
  const [sum, setSum] = useState('');
  const [metric1, setMetric1] = useState('Метрика 1')

  const [masterData, setMasterData] = useState([]);

  const responsibleRef = React.createRef();

  const loadObjectList = async () => {
    let res = await axios.get('/redesk/dk/transfer-premium/objects');
    setObjectData(res.data.objects_data);
  }

  const loadMetric1 = async () => {
    let res = await axios.get(`/redesk/dk/metric_1`);
    setMetricList(res.data.metric_data)
  }

  const loadItems = async () => {
    let res = await axios.get(`/redesk/dk/items`);
    setItemList(res.data.item_data)
  }

  const handleChange = (e, k=null) => {
    if (k !== null){
      updateFormData({...formData, [k]: e});
    }else{
      updateFormData({...formData, [e.target.name]: e.target.value});
    }
  }
  
  const loadResponsibleData = async () => {
    let res = await axios.get('/redesk/dk/responsible');
    setResponsibleData(res.data.responsible_data);
  }

  const loadMasterData = async () => {
    
    let res = await axios.get(`/redesk/nu/master`);

    let masterAr = res.data.master_data

    let forDeletion = ['Временный мастер', 'Сам прораб']

    masterAr = masterAr.filter(item => !forDeletion.includes(item))

    setMasterData(masterAr);
  }

  const compareDraft = () => {

    let draftCopy = [...uContext.userData.draft_data]
    
    let has_duplicate = false
    draftCopy.forEach((x) => {
      if(
        Math.abs(x.sum) === Number(formData.terminal_sum)
        && x.responsible_user === formData.terminal_responsible_user
        && x.item === item
        && x.object_id === objectId
        && x.payment_type === paymentType
        ){
        has_duplicate = true
      }
    })

    return has_duplicate
  }

  const handleSubmitWithConfirm = (e) => {

    if (!objectData.includes(objectId) && item === 'ФОТ Мастеров' && metric1 === 'Премия за переход'){
      alert('ИД объекта указан неправильно!')
      return
    }

    if (objectId && !objectData.includes(objectId)){
      alert('ИД объекта указан неправильно!')
      return
    }

    if (cfo === 'ЦФО'){
      alert('Укажите ЦФО!')
      return
    }

    if (itemType === 'Тип статьи'){
      alert('Укажите тип статьи!')
      return
    }

    if (item === 'Статья'){
      alert('Укажите статью!')
      return
    }

    if (sum === ''){
      alert('Введите сумму!')
      return
    }

    if (paymentType === 'Тип платежа'){
      alert('Укажите тип платежа!')
      return
    }

    if (!formData['terminal_responsible_user']){
      alert('Укажите ответственного!')
      return
    }

    if (!responsibleData.includes(formData['terminal_responsible_user'])){
      alert('Ответственный указан неправильно!')
      return
    }

    if (metric1 === 'Метрика 1'){
      alert('Укажите метрику!')
      return
    }

    if (item === 'Переброска' && paymentType === 'Безнал'){
      alert('Нельзя делать переброску с таким типом платежа!')
      return
    }

    if (compareDraft()){
      setConfirmModalText('Похожая запись уже есть в черновике. Создать еще одну?')
      setShowConfirmModal(true);
      return
    }

    handleSubmit(e)
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    let draftSendData = {...formData}
    draftSendData['cfo'] = cfo
    draftSendData['terminal_item_type'] = itemType
    draftSendData['terminal_item'] = item
    draftSendData['terminal_payment_type'] = paymentType
    draftSendData['terminal_company_name'] = companyName === 'Юрлицо' ? null : companyName
    draftSendData['terminal_object_id'] = objectId
    draftSendData['terminal_work_type'] = metric1
    draftSendData['desk_owner'] = 'ДК'

    if (draftSendData['terminal_object_id'] === ''){
      delete draftSendData['terminal_object_id']
    }

    if (draftSendData['terminal_work_type'] === 'Метрика 1'){
      delete draftSendData['terminal_work_type']
    }

    //responsibleRef.current.clear();

    let res = await axios.post('/redesk/draft/send', draftSendData, {headers:{'X-CSRF-TOKEN': cookies.get("csrf_access_token")}});

    let contextData = uContext.userData;
    contextData.draft_data = res.data.draft_data;
    uContext.setUserData(contextData);

    /*document.getElementById("terminal_main_form").reset();
    setItemType('Тип статьи');
    updateFormData({});
    setItem('Статья');
    setPaymentType('Тип платежа');
    setSum('');
    setCompanyName('Юрлицо');
    setObjectId('');*/
  }


  useEffect(() => {
    loadItems();
    loadObjectList();
    loadResponsibleData();
    loadMasterData();
    loadMetric1();
    return () => {}
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return(
    <Form id='terminal_main_form' className='mt-2'>
      <Row>
        <Col lg={6}>
          <Form.Group className="mb-3" controlId="t_fs_cfo" >
            <Form.Label>ЦФО</Form.Label>
            <Form.Select size="sm" name="terminal_fs_cfo" value={cfo} aria-label="terminal_fs_cfo" onChange={(e) => {setCfo(e.target.value)}}>
            <option key='ЦФО' value='ЦФО' disabled={true}>ЦФО</option>
            <option key='ДК' value='ДК'>ДК</option>
            <option key='НУ' value='НУ'>НУ</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={6}></Col>
        <Col xs={6}>
        <Form.Group className="mb-3" controlId="t_o_id">
            <Form.Label>Ид объекта</Form.Label>
            <Typeahead
              id='terminal_object_id'
              name='terminal_object_id'
              placeholder='Ид объекта'
              maxResults={7}
              emptyLabel='Нет совпадений'
              paginationText='Показать больше'
              highlightClassName='font-weight-bold'
              onInputChange={(text) => {setObjectId(text);}}
              onChange={(selected) => {setObjectId(selected[0]);}}
              options={objectData}
              size='sm'
              renderMenuItemChildren={(option, props, idx) => (
                <Highlighter search={props.text} highlightClassName='fw-bold mx-0 px-0 bg-transparent'>
                  {option}
                </Highlighter>
              )}
             />
            </Form.Group>
        </Col>
        <Form.Group as={Col} className="mb-3" controlId="t_i_t" >
          <Form.Label>Тип статьи</Form.Label>
          <Form.Select size="sm" name="terminal_item_type" value={itemType} aria-label="terminal_item_type" onChange={(e) => {updateFormData(() => {let nd = {...formData}; delete nd.terminal_responsible_user; updateFormData(nd)}); responsibleRef.current.clear(); setItemType(e.target.value); setItem('Статья')}}>
            <option key='Тип статьи' value='Тип статьи' disabled>Тип статьи</option>
            <option key='Приход' value='Приход'>Приход</option>
            <option key='Расход' value='Расход'>Расход</option>
          </Form.Select>
        </Form.Group>
      </Row>
      <Row>
        <Col xs={6}>
          <Form.Group className="mb-3" controlId="t_s">
            <Form.Label>Сумма</Form.Label>
            <CurrencyInput
              autoComplete='off'
              id="terminal_sum"
              name="terminal_sum"
              className='form-control'
              style={{maxHeight: '30px'}}
              value={sum}
              placeholder="Сумма"
              decimalsLimit={2}
              allowNegativeValue={false}
              groupSeparator={' '}
              onValueChange={(e) => {setSum(e); handleChange(e, 'terminal_sum')}}
            />
          </Form.Group>
        </Col>
        <Form.Group as={Col} className="mb-3" controlId="t_i">
          <Form.Label>Статья</Form.Label>
          <Form.Select size="sm" name="terminal_item" value={item} aria-label="terminal_item" disabled={itemType === 'Тип статьи' ? true : false} onChange={(e) => {setItem(e.target.value); updateFormData(() => {let nd = {...formData}; delete nd.terminal_responsible_user; updateFormData(nd)}); responsibleRef.current.clear();}}>
            <option key="Статья" disabled>Статья</option>
            {itemList.map(({value, spec}, index) => {if(spec === itemType){return <option key={value} value={value}>{value}</option>}return null})}
          </Form.Select>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} className="mb-3" controlId="t_c_n">
          <Form.Label>Юрлицо</Form.Label>
          <Form.Select size="sm" name="terminal_company_name" value={companyName} aria-label="terminal_company_name" onChange={(e) => {handleChange(e); setCompanyName(e.target.value);}}>
            <option key="Юрлицо" disabled>Юрлицо</option>
            <option key="ЛЛ">ЛЛ</option>
            <option key="МР">МР</option>
            <option key="РР">РР</option>
            <option key="РЭ">РЭ</option>
            <option key="ТСС">ТСС</option>
            <option key="ФР">ФР</option>
          </Form.Select>
        </Form.Group>
        <Form.Group as={Col} className="mb-3" controlId="t_p_t">
          <Form.Label>Тип платежа</Form.Label>
          <Form.Select size="sm" name="terminal_payment_type" value={paymentType} aria-label="terminal_payment_type" onChange={(e) => {handleChange(e); setPaymentType(e.target.value);}}>
            <option key="Тип платежа" disabled>Тип платежа</option>
            <option key="Нал">Нал</option>
            <option key="Безнал">Безнал</option>
          </Form.Select>
        </Form.Group>
      </Row>
      <Row>
      <Form.Group as={Col} className="mb-3" controlId="t_r_u">
        <Form.Label>Ответственный</Form.Label>
        <Typeahead
          key='responsible_user'
          id='terminal_responsible_user'
          name='terminal_responsible_user'
          ref = {responsibleRef}
          labelKey={'value'}
          placeholder='Ответственный'
          maxResults={7}
          emptyLabel='Нет совпадений'
          paginationText='Показать больше'
          highlightClassName='font-weight-bold'
          onChange={(selected) => handleChange(selected[0], 'terminal_responsible_user')}
          onInputChange={(text) => handleChange(text, 'terminal_responsible_user')}
          options={responsibleData}
          size='sm'
          renderMenuItemChildren={(option, props, idx) => (
            <Highlighter search={props.text} highlightClassName='fw-bold mx-0 px-0 bg-transparent'>
              {option}
            </Highlighter>
          )}
         />
      </Form.Group>
      <Col lg={6}>
      <Form.Group className="mb-3" controlId="t_o_id">
        <Form.Label>Мастер</Form.Label>
        <Typeahead
          id='terminal_metric_4'
          name='terminal_metric_4'
          placeholder='Мастер'
          maxResults={7}
          emptyLabel='Нет совпадений'
          paginationText='Показать больше'
          highlightClassName='font-weight-bold'
          onInputChange={(text) => {handleChange(text, 'terminal_metric_4')}}
          onChange={(selected) => {handleChange(selected[0], 'terminal_metric_4')}}
          options={masterData}
          size='sm'
          renderMenuItemChildren={(option, props, idx) => (
            <Highlighter search={props.text} highlightClassName='fw-bold mx-0 px-0 bg-transparent'>
              {option}
            </Highlighter>
          )}
          />
      </Form.Group>
      </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <Form.Group className="mb-3" controlId="t_m1" >
              <Form.Label>Метрика 1</Form.Label>
              <Form.Select size="sm" name="terminal_work_type" value={metric1} aria-label="terminal_work_type" onChange={(e) => {setMetric1(e.target.value)}}>
              <option key='Метрика 1' value='Метрика 1'>Метрика 1</option>
              {metricList.map((x) => {return <option key={x} value={x}>{x}</option>})}
              </Form.Select>
            </Form.Group>
        </Col>
        <Col lg={6}>
        <Form.Group className="mb-3" controlId="t_c">
          <Form.Label>Примечание</Form.Label>
          <Form.Control size="sm" autoComplete='off' name="terminal_comment" placeholder="Примечание" type="text" onChange={handleChange} />
        </Form.Group>
        </Col>
      </Row>
      <Row className="mb-2">
        <Stack gap={2}>
          <Button variant="primary" className="mb-3 mt-2 mx-auto" onClick={handleSubmitWithConfirm}>
            Отправить
          </Button>
        </Stack>
      </Row>
      <Modal centered show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
      <Modal.Body>
        <p>{confirmModalText}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick = {() => setShowConfirmModal(false)}>
          Нет
        </Button>
        <Button variant="danger" style={{minWidth: "12%"}} onClick={(e) => {handleSubmit(e); setShowConfirmModal(false)}}>
          Да
        </Button>
      </Modal.Footer>
    </Modal>
    </Form>
  )
}


export default DKMainTerminal;
