import React, { useState } from 'react'

export const AccountingContext = React.createContext({
  helpersData: {
    helpers:[],
    excerptCheck: [],
  },
  setHelpersData: () => [],
})

export const AccountingContextProvider = (props) => {
  const setHelpersData = (helpersData) => {
    setState({...state, helpersData: helpersData})
  }


  const initState = {
    helpersData: {
      helpers:[],
      excerptCheck: [],
    },
    setHelpersData: setHelpersData
  }

  const [state, setState] = useState(initState)

  return (
    <AccountingContext.Provider value={state}>
      {props.children}
    </AccountingContext.Provider>
  )
}
