import React, {useState, useEffect} from 'react';
import "bootstrap/dist/css/bootstrap.css";
import {Row, Col, ToggleButtonGroup, ToggleButton, Container} from "react-bootstrap";
import AccountingExcerptParser from './AccountingExcerptParser';
import AccountingExcerptList from './AccountingExcerptList';
import AccountingExcerptHistory from './AccountingExcerptHistory';



function AccountingExcerpt(){

  const [tabState, setTabState] = useState('excerpts');

  useEffect(() => {
    return () => {}
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return(
    <Container fluid className="container-fluid pt-2">
        <Row style={{paddingRight: '0px'}}>
          <Col lg={2} className='pe-0'>
          <ToggleButtonGroup
            type="radio"
            name="ex_tap"
            value={tabState}
            style={{marginLeft: '0px', marginRight: '5px', width: '100%'}}
            size='sm'
            onChange = {(val) => {setTabState(val)}}
          >
            <ToggleButton id='tgbtn-excerpts'  variant='outline-primary' value='excerpts'>
              Выписки
            </ToggleButton>
            <ToggleButton id='tgbtn-parser'  variant='outline-primary' value='parser'>
              Парсер
            </ToggleButton>
            <ToggleButton id='tgbtn-history'  variant='outline-primary' value='history'>
              История
            </ToggleButton>
          </ToggleButtonGroup>
          </Col>
      {(tabState === 'excerpts') ? (<AccountingExcerptList />) : (tabState === 'parser') ? (<AccountingExcerptParser />) : (<AccountingExcerptHistory />)}
      </Row>
      </Container>
  );
}


export default AccountingExcerpt;
