import React, { useState } from 'react'

export const SupplyContext = React.createContext({
  helpersData: {
    prepayment: 0
  },
  setHelpersData: () => {},
})

export const SupplyContextProvider = (props) => {
  const setHelpersData = (helpersData) => {
    setState({...state, helpersData: helpersData})
  }

  const initState = {
    helpersData: {
        prepayment: 0
    },
    setHelpersData: setHelpersData
  }

  const [state, setState] = useState(initState)

  return (
    <SupplyContext.Provider value={state}>
      {props.children}
    </SupplyContext.Provider>
  )
}