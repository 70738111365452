import React from 'react'
import {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import {Button, Form, Row, Col} from "react-bootstrap";
import sha256 from 'js-sha256';
import axios from 'axios';

function LoginSignIn(){

  axios.defaults.withCredentials = true;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const navigate = useNavigate();

  const signIn = (res) => {
    if (email.length < 1){
      alert('Введите почту')
    }
    else if (password.length < 6){
      alert('Пароль должен состоять как минимум из 6-ти символов!')
    } else {
      axios.post('/redesk/profile/sign-in', {email: email, pass_hash: sha256(password)})
      .then((res) => {
          navigate('/');
      }).catch((error) => {
        if (error.response && error.response.status === 403) {
          alert(error.response.data.error_message);
        }else{
          alert('Ошибка соединения, сервер не отвечает')
        }
      })
    };
  }

  const onKeyPress_pwd = (e) => {
    var key=e.key || e.which;
    if (key == 'Enter') { setPassword(e.target.value); signIn(); }
    if (key == 'Escape') { e.target.value = ""; }
  }

  const onKeyPress_login = (e) => {
    var key=e.key || e.which;
    if (key == 'Enter') { signIn(); }
  }

  return(
    <>
      <Form id='signIn_form'>
        <Row>
          <Form.Group as={Col} xs='12' className="mb-3" controlId="si_e">
            <Form.Label>E-mail</Form.Label>
            <Form.Control size="sm" defaultValue='' name="signIn_email" placeholder="Логин" onInput={(e) => setEmail(e.target.value)} onKeyUp={onKeyPress_login}/>
          </Form.Group>
          <Form.Group as={Col} xs='12' className="mb-3" controlId="si_p">
            <Form.Label>Пароль</Form.Label>
            <Form.Control size="sm" defaultValue='' name="signIn_password" placeholder="Пароль" type='password' onInput={(e) => setPassword(e.target.value)} onKeyUp={onKeyPress_pwd}/>
          </Form.Group>
        </Row>
      </Form>
      <Col style={{textAlign: "center"}}>
        <Button variant="primary" onClick={signIn}>Войти</Button>
      </Col>
    </>
  )
}

export default LoginSignIn;
