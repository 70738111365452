import React, { useState } from 'react'

export const NsdoContext = React.createContext({
  helpersData: {
    remainder_cash: 0,
    remainder_card: 0,
    remainder_main: 0,
    incoming: 0,
    outcoming: 0
  },
  setHelpersData: () => {},
})

export const NsdoContextProvider = (props) => {
  const setHelpersData = (helpersData) => {
    setState({...state, helpersData: helpersData})
  }


  const initState = {
    helpersData: {
        remainder_cash: 0,
        remainder_card: 0,
        remainder_main: 0,
        incoming: 0,
        outcoming: 0
    },
    setHelpersData: setHelpersData
  }

  const [state, setState] = useState(initState)

  return (
    <NsdoContext.Provider value={state}>
      {props.children}
    </NsdoContext.Provider>
  )
}
