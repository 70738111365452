import React, {useState, useEffect, useContext} from 'react';
import "bootstrap/dist/css/bootstrap.css";
import {Row, Col, Button, Stack, Form, Modal, Container} from "react-bootstrap";
import { UserContext } from '../UserContextManager';
import { AccountingContext } from '../AccountingContextManager';
import axios from 'axios';
import cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";
import CurrencyInput from 'react-currency-input-field';

function AccountingMainTerminal(){

  const navigate = useNavigate();

  const uContext = useContext(UserContext);
  const aContext = useContext(AccountingContext);

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const [dropdownData, setDropdownData] = useState([]);

  const [itemType, setItemType] = useState('Приход');
  const [item, setItem] = useState('Статья');
  const [paymentType, setPaymentType] = useState('Безнал')
  const [formData, updateFormData] = useState({});

  const [sum, setSum] = useState('');

  const loadAccountingHelpers = async () => {
    let res = await axios.get('/redesk/accounting/helpers');
    aContext.setHelpersData(res.data.helpers_data);
  }

  const preSelectItem = (e) => {

    let fDCopy = {...formData}

    if (formData.terminal_item){
      delete fDCopy.terminal_item
    }

    setItem('Статья')
    setItemType(e.target.value)
    updateFormData({...fDCopy, [e.target.name]: e.target.value})

  }

  const loadTerminalDropdown = async () => {
    try{
      let dropdownsRes = await axios.get('/redesk/accounting/dropdowns');
      setDropdownData(dropdownsRes.data.accounting_dropdowns);
      updateFormData({
        ...formData,
        terminal_item_type: 'Приход',
        terminal_payment_type: 'Безнал',
        terminal_metric_4: 'Технопарк'
      })
    } catch (error){
      navigate('/');
    }
  }

  const handleChange = (e, k=null) => {

    let newFormData = {}

    if (k !== null){
      newFormData = {...formData, [k]: e}
    }else{
      newFormData = {...formData, [e.target.name]: e.target.value}
    }

    {/*if (('terminal_responsible_user' in newFormData & (newFormData['terminal_responsible_user'] === 'БМЗ' || newFormData['terminal_responsible_user'] === 'РАР')) &
         'terminal_item' in newFormData & newFormData['terminal_item'] === 'Переброска'){
      newFormData['terminal_payment_type'] = 'Безнал';
      setPaymentType('Безнал');
      setLockPayment(true);
    }else{
      setLockPayment(false);
    }*/}

    updateFormData(newFormData);
  }

  const compareDraft = () => {

    let draftCopy = [...uContext.userData.draft_data]
    
    let has_duplicate = false
    draftCopy.forEach((x) => {
      if(x.metric_5 === formData.terminal_metric_5 
        && Math.abs(x.sum) === Number(formData.terminal_sum)
        && x.responsible_user === formData.terminal_responsible_user
        && x.item === formData.terminal_item
        && x.payment_type === paymentType
        && x.metric_4 === formData.terminal_metric_4
        ){
        has_duplicate = true
      }
    })

    return has_duplicate
  }

  const handleSubmitWithConfirm = (e) => {

    if (!formData.terminal_sum){
      alert('Введите сумму!')
      return
    }

    if (!formData.terminal_metric_5){
      alert('Укажите счет!')
      return
    }

    if (!formData.terminal_item){
      alert('Укажите статью!')
      return
    }

    if (!formData.terminal_sub_item && formData.terminal_item === 'Налоги и сборы'){
      alert('Укажите подстатью!')
      return
    }


    if (!formData.terminal_responsible_user){
      alert('Укажите ответственного!')
      return
    }

    if (compareDraft()){
      setShowConfirmModal(true);
      return
    }

    handleSubmit(e)
  }

  const handleSubmit = async (e) => {
    e.preventDefault();


    let draftSendData = {...formData}
    draftSendData['cfo'] = 'Бухгалтерия'
    draftSendData['desk_owner'] = 'Бухгалтерия'
    draftSendData['terminal_item_type'] = itemType
    draftSendData['terminal_payment_type'] = paymentType
    
    if (!draftSendData['terminal_metric_4']){
      draftSendData['terminal_metric_4'] = 'Технопарк'
    }

    if (draftSendData['terminal_sub_item'] && draftSendData['terminal_sub_item'] === 'Подстатья'){
      delete draftSendData['terminal_sub_item']
    }

    if (draftSendData['terminal_work_type'] && draftSendData['terminal_work_type'] === 'ИП'){
      delete draftSendData['terminal_work_type']
    }

    let res = await axios.post('/redesk/draft/send', draftSendData, {headers:{'X-CSRF-TOKEN': cookies.get("csrf_access_token")}});

    let contextData = uContext.userData;
    contextData.draft_data = res.data.draft_data;
    uContext.setUserData(contextData);

    /*document.getElementById("terminal_main_form").reset();
    setItemType('Приход');
    updateFormData({});
    setItem('Статья');
    setPaymentType('Безнал');
    loadAccountingHelpers();
    setSum('');*/
  }

  useEffect(() => {
    loadTerminalDropdown();
    loadAccountingHelpers();
    return () => {}
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
 // eslint-disable-line react-hooks/exhaustive-deps

  return(
    <React.Fragment>
      <Form id='terminal_main_form' className='p-2 border'>
        <Row>
          <Col lg={4}>
            <Form.Group as={Col} className="mb-3" controlId="t_i_t" >
              <Form.Label>Тип статьи</Form.Label>
              <Form.Select size="sm" name="terminal_item_type" value={itemType} aria-label="terminal_item_type" onChange={preSelectItem}>
                <option key="Приход" value="Приход">Приход</option>
                <option key="Расход" value="Расход">Расход</option>
              </Form.Select>
            </Form.Group>
            
          </Col>

          <Col lg={4}>
            <Form.Group className="mb-3" controlId="t_b_a">
              <Form.Label>Счет</Form.Label>
              <Form.Select size="sm" name="terminal_metric_5" defaultValue='Счет' aria-label="terminal_metric_5" onChange={handleChange}>
                <option key="Счет" disabled>Счет</option>
                {dropdownData.map(({value, label, dropdown_name}, index) => {if(dropdown_name === 'accounting_account'){return <option key={value} value={label}>{label}</option>}return null})}
              </Form.Select>
            </Form.Group>
          </Col>

          <Col lg={4}>
            <Form.Group as={Col} className="mb-3" controlId="t_p_t">
              <Form.Label>Тип платежа</Form.Label>
                <Form.Select size="sm"
                  name="terminal_payment_type"
                  value={paymentType}
                  aria-label="terminal_payment_type"
                  onChange={(e) => {handleChange(e); setPaymentType(e.target.value)}}
                >
                  <option key="Безнал" value="Безнал">Безнал</option>
                  <option key="Нал" value="Нал">Нал</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col lg={4}>
            <Form.Group as={Col} className="mb-3" controlId="t_i">
              <Form.Label>Статья</Form.Label>
              <Form.Select size="sm" name="terminal_item" value={item} aria-label="terminal_item" onChange={(e) => {handleChange(e); setItem(e.target.value)}}>
                <option key="Статья" disabled>Статья</option>
                {dropdownData.map(({value, label, dropdown_name, spec}, index) => {if(dropdown_name === 'accounting_item' && spec === itemType){return <option key={value} value={value}>{label}</option>}return null})}
              </Form.Select>
            </Form.Group>
          </Col>

          <Col lg={4}>
            <Form.Group as={Col} className="mb-3" controlId="t_s_i">
              <Form.Label>Подстатья</Form.Label>
              <Form.Select size="sm" name="terminal_sub_item" defaultValue="Подстатья" aria-label="terminal_sub_item" onChange={handleChange}>
                <option key="Подстатья">Подстатья</option>
                {dropdownData.map(({value, label, dropdown_name}, index) => {if(dropdown_name === 'accounting_subitem'){return <option key={value} value={value}>{label}</option>}return null})}
              </Form.Select>
            </Form.Group>
          </Col>

          <Col lg={4}>
            <Form.Group as={Col} className="mb-3" controlId="t_o">
              <Form.Label>Офис</Form.Label>
              <Form.Select size="sm" name="terminal_metric_4" defaultValue="Технопарк" aria-label="terminal_metric_4" onChange={handleChange}>
                <option key="Технопарк" value="Технопарк">Технопарк</option>
                {dropdownData.map(({value, label, dropdown_name}, index) => {if(dropdown_name === 'accounting_office' && value !== 'Технопарк'){return <option key={value} value={value}>{label}</option>}return null})}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col lg={4}>
          <Form.Group className="mb-3" controlId="t_s">
              <Form.Label>Сумма</Form.Label>
              <CurrencyInput
                autoComplete='off'
                id="terminal_sum"
                name="terminal_sum"
                className='form-control'
                style={{maxHeight: '30px'}}
                value={sum}
                placeholder="0,00"
                decimalsLimit={2}
                allowNegativeValue={false}
                groupSeparator={' '}
                onValueChange={(e) => {setSum(e); handleChange(e, 'terminal_sum')}}
              />
            </Form.Group>
          </Col>

          <Col lg={4}>
            <Form.Group as={Col} className="mb-3" controlId="t_w_t">
              <Form.Label>ИП</Form.Label>
              <Form.Select size="sm" name="terminal_work_type" defaultValue="ИП" aria-label="terminal_work_type" onChange={handleChange}>
                <option key="ИП">ИП</option>
                {dropdownData.map(({value, label, dropdown_name}, index) => {if(dropdown_name === 'accounting_ie'){return <option key={value} value={value}>{label}</option>}return null})}
              </Form.Select>
            </Form.Group>
          </Col>

          <Col lg={4}>
          </Col>

        </Row>
        <Row>

          <Col lg={4}>
            <Form.Group as={Col} className="mb-3" controlId="t_r_u">
                <Form.Label>Ответственный</Form.Label>
                <Form.Select size="sm" name="terminal_responsible_user" defaultValue="Ответственный" aria-label="terminal_responsible_user" onChange={handleChange}>
                  <option key="Ответственный" disabled>Ответственный</option>
                  {dropdownData.map(({value, label, dropdown_name}, index) => {if(dropdown_name === 'accounting_responsible'){return <option key={value} value={value}>{label}</option>}return null})}
                </Form.Select>
            </Form.Group>
          </Col>

          <Col lg={4}>
            <Form.Group as={Col} className="mb-3" controlId="t_c">
              <Form.Label>Примечание</Form.Label>
              <Form.Control size="sm" autoComplete='off' name="terminal_comment" placeholder="Примечание" type="text" onChange={handleChange} />
            </Form.Group>
          </Col>

          <Col lg={4}>
          </Col>
          
        </Row>

        <Row className="mt-2">
          <Col lg={10}></Col>
          <Col lg={2} style={{ textAlign: "right" }}>
            <Button variant="primary" size="sm" onClick={handleSubmitWithConfirm}>Отправить</Button>
          </Col>
        </Row>
      </Form>

      <Modal centered show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
        <Modal.Body>
          <p>{'Похожая запись уже есть в черновике. Создать еще одну?'}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" size="sm" onClick = {() => setShowConfirmModal(false)}>Нет</Button>
          <Button variant="danger" size="sm" style={{minWidth: "12%"}} onClick={(e) => {handleSubmit(e); setShowConfirmModal(false)}}>Да</Button>
        </Modal.Footer>
      </Modal>
      
    </React.Fragment>
  )
}


export default AccountingMainTerminal;
