import React, { useState } from 'react';
import "bootstrap/dist/css/bootstrap.css";
import NuSpbTerminal from './NuSpbTerminal';
import IncomingItems from '../general/IncomingItems';
import NuSpbHistory from './NuSpbHistory';
import {Tabs, Tab} from "react-bootstrap";


function NavTabs() {

  const [state, setState] = useState('terminal');

  return(
    <Tabs
      justify
      id="controlled-tab-example"
      activeKey={state}
      onSelect={(k) => setState(k)}
      //className="mb-3"
      unmountOnExit={true}
    >
      <Tab eventKey="terminal" title="Терминал" unmountOnExit={false}>
        <NuSpbTerminal />
      </Tab>
      <Tab eventKey="profile" title="Переброски">
        <IncomingItems />
      </Tab>
      <Tab eventKey="contact" title="История">
        <NuSpbHistory />
      </Tab>
    </Tabs>
  )

}

export default NavTabs;
