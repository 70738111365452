import React, {useEffect, useContext} from 'react';
import "bootstrap/dist/css/bootstrap.css";
import { useNavigate } from "react-router-dom";
import { UserContext } from '../UserContextManager';
import axios from 'axios';
import cookies from 'js-cookie';


function Dash(){

  const uContext = useContext(UserContext);

  const navigate = useNavigate();

  useEffect(() => {

    const loadUserData = async () => {
      try{
        let userDataRes = await axios.get('/redesk/general/remainder', {withCredentials: true});
        let data = await userDataRes.data;

        let draftRes = await axios.get('/redesk/draft');

        let draftData = await draftRes.data;

        data.user_data['draft_data'] = draftData.draft_data;

        uContext.setUserData(data.user_data);

        let roleUrl
        let roles = data.user_data.role.split(';')
        if (roles.length > 1){
          roleUrl = `/desk-selection`;
        }else{
          roleUrl = `/${data.user_data.role}`;
        }
        

        navigate(roleUrl);
      } catch (error){
        alert('Ошибка соединения, сервер не отвечает');
      }
    }

    cookies.get("csrf_access_token") ? loadUserData() : navigate('/login');
    return () => {}
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return(
    <></>
  )
}


export default Dash
