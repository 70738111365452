import React, { useState } from 'react'

export const ManagementContext = React.createContext({
  helpersData: {
    cash: 0,
    noncash: 0,
    remainder: 0,
    in_desk: 0,
    incoming: 0,
    outcoming: 0,
    fot_sber: 0
  },
  setHelpersData: () => {},
})

export const ManagementContextProvider = (props) => {
  const setHelpersData = (helpersData) => {
    setState({...state, helpersData: helpersData})
  }


  const initState = {
    helpersData: {
        cash: 0,
        noncash: 0,
        remainder: 0,
        in_desk: 0,
        incoming: 0,
        outcoming: 0,
        fot_sber: 0
    },
    setHelpersData: setHelpersData
  }

  const [state, setState] = useState(initState)

  return (
    <ManagementContext.Provider value={state}>
      {props.children}
    </ManagementContext.Provider>
  )
}
