import React, {useState, useEffect, useContext} from 'react';
import "bootstrap/dist/css/bootstrap.css";
import { Typeahead, Highlighter } from 'react-bootstrap-typeahead';
import {Row, Col, Button, Stack, Form, Modal} from "react-bootstrap";
import { UserContext } from '../UserContextManager';
import axios from 'axios';
import cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";
import CurrencyInput from 'react-currency-input-field';

function SMPMainTerminal(){

  const defaultResponsibles = [
    'БМЗ',
    'СИС',
    'РАР',
    'КДЮ',
    'ВАС',
    'ЕСВ',
    'ПНВ',
    'ЕОМ',
    'МВМ',
    'АДВ',
    'ДАСп',
    'ААВ',
    'ВДКн',
    'УВЮн'
  ]

  const navigate = useNavigate();

  const uContext = useContext(UserContext)

  const[orderData, setOrderData] = useState({});
  const[ordersList, setOrdersList] = useState([]);

  const[orderType, setOrderType] = useState('Тип заказа');

  const [dropdownData, setDropdownData] = useState([]);
  const [responsibleUserData, setResponsibleUserData] = useState(defaultResponsibles);
  const [reasOptions, setReasOptions] = useState([]);

  const [selectedSum, setSelectedSum] = useState('');

  const [maxOrderSum, setMaxOrderSum] = useState(false); 

  const [itemType, setItemType] = useState('Тип статьи');
  const [item, setItem] = useState('Статья');
  const [formData, updateFormData] = useState({});
  const [loadedData, updateLoadedData] = useState({});
  const [orderTypes, setOrderTypes] = useState([]);
  const [finalOrderData, setFinalOrderData] = useState({});

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmModalText, setConfirmModalText] = useState('');

  const [sum, setSum] = useState('');

  const responsibleRef = React.createRef();
  const orderRef = React.createRef();


  const loadOrderTypeData = async (e) => {
    let res = await axios.get('/redesk/smp/order-type/data', {params: {order_type: e, order_number: formData.metric_4}});
    setFinalOrderData(res.data.final_order_data);

    if(itemType !== 'Тип статьи'){
      setSelectedSum(res.data.final_order_data[itemType]['sum']);
    }
    
  }

  const filterReasOptions = (dd_data) => {
    let newROptions = [];
    for (let x of dd_data){
      if (x.spec === 'Переброска'){
        newROptions.push(x.value);
      }
    }
    setReasOptions(newROptions);
  }

  const loadTerminalDropdown = async () => {

    try{
      let dropdownsRes = await axios.get('/redesk/terminal/main-dropdowns', {params: {desk_type: 'supply'}});

      setDropdownData(dropdownsRes.data.dropdowns_data);
      filterReasOptions(dropdownsRes.data.dropdowns_data);
      updateFormData({...formData, 'terminal_item_type': 'Приход'})
    } catch (error){
      navigate('/');
    }
  }

  const loadOrderData = async (orderNumber) => {
    if (ordersList.indexOf(orderNumber) > -1){
      let res = await axios.get(`/redesk/smp/order/${orderNumber}`);

      setOrderData(res.data.order_data);

      let resposibleUsers = [
        res.data.order_data.foreman,
        res.data.order_data.estimator,
        res.data.order_data.designer,
      ].filter(n => n);

      setResponsibleUserData(resposibleUsers);

      updateLoadedData(
        {
          terminal_company_name: res.data.order_data.company_name,
          terminal_sub_item: res.data.order_data.shipper,
          terminal_object_id: res.data.order_data.object_id,
        }
      )

      setOrderTypes(res.data.order_types)

    }else{
      setFinalOrderData({});
      setItem('Статья');
      setItemType('Тип статьи')
      setOrderType('Тип заказа');
      setOrderData({});
      updateLoadedData({});
      setOrderTypes([]);
      setSelectedSum('');
    }
  }

  const loadOrdersList = async () => {
    try{
      let res = await axios.get('/redesk/smp/order');
      setOrdersList(res.data.orders_list);
    }catch{
        return
    }
  }


  const handleChange = (e, k=null) => {
    if (k !== null){
      updateFormData({...formData, [k]: e})
    }else{
      updateFormData({...formData, [e.target.name]: e.target.value})
    }
  }

  const compareDraft = () => {

    let draftCopy = [...uContext.userData.draft_data]
    
    let has_duplicate = false
    draftCopy.forEach((x) => {
      if(
        Math.abs(x.sum) === Number(sum)
        && x.responsible_user === formData.terminal_responsible_user
        && x.item === item
        && x.payment_type === formData.terminal_payment_type
        && x.metric_4 === formData.metric_4
        ){
        has_duplicate = true
      }
    })

    return has_duplicate
  }

  const handleSubmitWithConfirm = (e) => {
    if (item !== 'Переброска' && responsibleUserData.indexOf(formData.terminal_responsible_user) < 0){
      alert('Ответственный указан неправильно!')
      return
    }

    if (item === 'Переброска' && reasOptions.indexOf(formData.terminal_responsible_user) < 0){
      alert('Ответственный указан неправильно!')
      return
    }

    if (itemType === 'Тип статьи'){
      alert('Укажите тип статьи!')
      return
    }

    if (!sum || sum === '' || sum === '0' || sum === 0){
      alert('Введите сумму!')
      return
    }

    if (item === 'Статья'){
      alert('Укажите статью!')
      return
    }

    if (!formData.terminal_work_type & formData.metric_4){
      alert('Укажите тип заказа!')
      return
    }

    if (!formData.terminal_payment_type){
      alert('Укажите тип платежа!')
      return
    }

    if ((!loadedData || !loadedData.terminal_company_name) & formData.metric_4){
      alert('Укажите юрлицо!')
      return
    }

    if (compareDraft()){
      setConfirmModalText('Похожая запись уже есть в черновике. Создать еще одну?')
      setShowConfirmModal(true);
      return
    }

    handleSubmit(e)
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    

    let draftSendData = {...formData, ...loadedData}
    draftSendData['cfo'] = 'НУ'
    draftSendData['desk_owner'] = 'НУ_СМП'
    draftSendData['terminal_sum'] = sum
    draftSendData['terminal_item_type'] = itemType
    draftSendData['terminal_item'] = item
    draftSendData['terminal_sub_item'] = 'Материалы'

    if (draftSendData.terminal_item === 'Переброска'){
      delete draftSendData.metric_4
      delete draftSendData.terminal_work_type
    }

    if (!draftSendData.metric_4){
      draftSendData['terminal_item_type'] = 'Расход'
      draftSendData['terminal_item'] = 'Переброска'
      draftSendData['terminal_payment_type'] = 'Нал'
    }

    //orderRef.current.clear();
    //responsibleRef.current.clear();
    let res = await axios.post('/redesk/draft/send', draftSendData, {headers:{'X-CSRF-TOKEN': cookies.get("csrf_access_token")}});
    let contextData = uContext.userData;
    contextData.draft_data = res.data.draft_data;
    uContext.setUserData(contextData);

    /*document.getElementById("terminal_main_form").reset();
    updateFormData({});
    updateLoadedData({});
    setOrderData({});
    setResponsibleUserData(defaultResponsibles);
    setItem(null);
    setFinalOrderData({});
    setSelectedSum('');
    setSum('');
    setItem('Статья');
    setItemType('Тип статьи')
    setOrderType('Тип заказа');
    setOrderTypes([]);*/
  }

  useEffect(() => {
    loadTerminalDropdown();
    loadOrdersList();

    return () => {}
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {}
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return(
    <Form id='terminal_main_form' className='mt-2'>
      <Row>
        <Col xs={3}>
          <Form.Group className="mb-3" controlId="t_o_o_n">
            <Form.Label>Номер заказа</Form.Label>
            <Typeahead
              id='terminal_order_number'
              name='metric_4'
              ref = {orderRef}
              labelKey='value'
              placeholder='Номер заказа'
              maxResults={7}
              emptyLabel='Нет совпадений'
              paginationText='Показать больше'
              highlightClassName='font-weight-bold'
              onInputChange={(text) => {handleChange(text, 'metric_4'); loadOrderData(text); setOrderType('Тип заказа'); setItemType('Тип статьи'); setItem('Статья');}}
              onChange={(selected) => {handleChange(selected[0], 'metric_4'); loadOrderData(selected[0]); setOrderType('Тип заказа'); setItemType('Тип статьи'); setItem('Статья');}}
              options={ordersList}
              size='sm'
              renderMenuItemChildren={(option, props, idx) => (
                <Highlighter search={props.text} highlightClassName='fw-bold mx-0 px-0 bg-transparent'>
                  {option}
                </Highlighter>
              )}
             />
          </Form.Group>
        </Col>
        <Col xs={3}>
          <Form.Group className="mb-3" controlId="t_o_id">
            <Form.Label>Ид объекта</Form.Label>
            <Form.Control size="sm" name="terminal_object_id" value={orderData.object_id || ''} disabled />
          </Form.Group>
        </Col>
        <Col xs={6}>
          <Form.Group className="mb-3" controlId="t_w_t">
            <Form.Label>Тип заказа</Form.Label>
            <Form.Select size="sm" name="terminal_work_type" value={orderType} aria-label="terminal_work_type" onChange={(e) => {handleChange(e); setOrderType(e.target.value); loadOrderTypeData(e.target.value); setItemType('Тип статьи'); setItem('Статья');}} disabled={orderTypes.length > 0 ? false : true}>
              <option key="Тип заказа" value="Тип заказа" disabled>Тип заказа</option>
              {orderTypes.map((x) => {return <option key={x} value={x}>{x}</option>})}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Form.Group as={Col} className="mb-3" controlId="t_i_t" >
          <Form.Label>Тип статьи</Form.Label>
          <Form.Select size="sm" name="terminal_item_type" value={itemType} aria-label="terminal_item_type" disabled={Object.keys(finalOrderData).length > 0 || !formData.metric_4 ? false : true} onChange={(e) => {handleChange(e); setItemType(e.target.value); setItem('Статья'); setSelectedSum(!formData.metric_4 ? '' : finalOrderData[e.target.value]['sum'])}}>
            <option key="Тип статьи" disabled>Тип статьи</option>
            {formData.metric_4 ? (<option key="Приход">Приход</option>) : null}
            <option key="Расход">Расход</option>
          </Form.Select>
        </Form.Group>
        <Form.Group as={Col} className="mb-3" controlId="t_i">
          <Form.Label>Статья</Form.Label>
          <Form.Select size="sm" name="terminal_item" value={item} disabled={(Object.keys(finalOrderData).length > 0 & itemType !== 'Тип статьи') || !formData.metric_4 ? false : true} aria-label="terminal_item" onChange={(e) => {handleChange(e); setItem(e.target.value);}}>
            <option key="Статья" disabled>Статья</option>
            {!formData.metric_4 ? (<option key="Переброска">Переброска</option>) : null}
            {Object.keys(finalOrderData).length > 0 & itemType !== 'Тип статьи' ? (finalOrderData[itemType]['item'].map((x) => {return <option key={x} value={x}>{x}</option>})) : null}
          </Form.Select>
        </Form.Group>
      </Row>
      <Row>
        <Col xs={6}>
          <Form.Group className="mb-3" controlId="t_s">
            <Form.Label>Сумма</Form.Label>
            <CurrencyInput
              autoComplete='off'
              id="terminal_sum"
              name="terminal_sum"
              className='form-control'
              style={{maxHeight: '30px'}}
              value={sum}
              placeholder="Сумма"
              decimalsLimit={2}
              allowNegativeValue={false}
              groupSeparator={' '}
              onValueChange={(e) => {setSum(e); handleChange(e, 'terminal_sum')}}
            />
          </Form.Group>
        </Col>
        <Form.Group as={Col} className="mb-3" controlId="t_p_t">
          <Form.Label>Тип платежа</Form.Label>
          <Form.Select size="sm" name="terminal_payment_type" defaultValue="Тип платежа" aria-label="terminal_payment_type" onChange={(e) => {handleChange(e)}}>
            <option key="Тип платежа" disabled>Тип платежа</option>
            <option key="Нал">Нал</option>
            {formData.metric_4 ? (<option key="Безнал">Безнал</option>) : null}
          </Form.Select>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} className="mb-3" controlId="t_s_i">
          <Form.Label>Поставщик/Рынок</Form.Label>
          <Form.Control size="sm" name="terminal_sub_item" value={'Материалы'} disabled />
        </Form.Group>
        <Form.Group as={Col} className="mb-3" controlId="t_c_n">
          <Form.Label>Юрлицо</Form.Label>
          <Form.Control size="sm" value={orderData.company_name || ''} name="terminal_company_name" disabled />

        </Form.Group>
      </Row>
      <Row>
      <Form.Group as={Col} className="mb-3" controlId="t_r_u">
        <Form.Label>Ответственный</Form.Label>
        <Typeahead
          id='terminal_responsible_user'
          name='terminal_responsible_user'
          ref = {responsibleRef}
          labelKey='value'
          placeholder='Ответственный'
          maxResults={7}
          emptyLabel='Нет совпадений'
          paginationText='Показать больше'
          highlightClassName='font-weight-bold'
          onChange={(selected) => handleChange(selected[0], 'terminal_responsible_user')}
          onInputChange={(text) => handleChange(text, 'terminal_responsible_user')}
          options={item === 'Переброска'? reasOptions: responsibleUserData}
          size='sm'
          renderMenuItemChildren={(option, props, idx) => (
            <Highlighter search={props.text} highlightClassName='fw-bold mx-0 px-0 bg-transparent'>
              {option}
            </Highlighter>
          )}
         />
      </Form.Group>
      <Form.Group as={Col} className="mb-3" controlId="t_c">
        <Form.Label>Примечание</Form.Label>
        <Form.Control size="sm" autoComplete='off' name="terminal_comment" placeholder="Примечание" type="text" onChange={handleChange} />
      </Form.Group>
      </Row>
      <Row className="mb-2">
        <Stack gap={2}>
          <Button variant="primary" className="mb-3 mt-2 mx-auto" onClick={handleSubmitWithConfirm}>
            Отправить
          </Button>
        </Stack>
      </Row>
      <Modal centered show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
      <Modal.Body>
        <p>{confirmModalText}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick = {() => setShowConfirmModal(false)}>
          Нет
        </Button>
        <Button variant="danger" style={{minWidth: "12%"}} onClick={(e) => {handleSubmit(e); setShowConfirmModal(false)}}>
          Да
        </Button>
      </Modal.Footer>
    </Modal>
    </Form>
  )
}


export default SMPMainTerminal;
