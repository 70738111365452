import React, { useState } from 'react'

export const CCContext = React.createContext({
  helpersData: {
    remainder: 0,
    incoming: 0,
    outcoming: 0
  },
  setHelpersData: () => {},
})

export const CCContextProvider = (props) => {
  const setHelpersData = (helpersData) => {
    setState({...state, helpersData: helpersData})
  }


  const initState = {
    helpersData: {
        remainder: 0,
        incoming: 0,
        outcoming: 0
    },
    setHelpersData: setHelpersData
  }

  const [state, setState] = useState(initState)

  return (
    <CCContext.Provider value={state}>
      {props.children}
    </CCContext.Provider>
  )
}
