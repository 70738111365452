import React, { useEffect, useContext } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../UserContextManager";
import { Card, Row, Col, Container, Button } from "react-bootstrap";
import UserInfo from "../general/UserInfo";
import Logout from "../login/Logout";
import axios from "axios";
import cookies from "js-cookie";

function DeskSelection() {
  const uContext = useContext(UserContext);

  const navigate = useNavigate();

  const desks = {
    nu: "НУ",
    np: "Натяжные потолки",
    supply: "Снабжение",
    cc: "КЦ",
    cc_partners: "КЦ Партнеры",
    op: "HR",
    accounting: "Бухгалтерия",
    design: "Дизайн",
    management: "Дирекция",
    marketing: "Маркетинг",
    nsdo: "СДО",
    dk: "Департамент качества",
    mech: "Мехстяжка",
    fund: "Кассир",
    smp: "Спецмонтаж",
    nu_spb: "НУ Спб",
    management_spb: "Дирекция Спб",
    supply_spb: "Снабжение Спб",
    des: "Элитный сегмент",
  };

  const loadUserData = async (mounted) => {
    let userDataRes = await axios.get("/redesk/general/remainder");

    let data = await userDataRes.data;

    let draftRes = await axios.get("/redesk/draft");

    let draftData = await draftRes.data;

    data.user_data["draft_data"] = draftData.draft_data;

    if (mounted) {
      uContext.setUserData(data.user_data);
    }

    let roles = data.user_data.role.split(";");
    if (roles.length < 2) {
      navigate("/");
    } else {
    }
  };

  useEffect(() => {
    let mounted = true;
    loadUserData(mounted);

    return () => (mounted = false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container fluid>
      <Row className="justify-content-center" style={{ marginTop: "15px" }}>
        <Col xs={6} style={{ textAlign: "left" }}>
          <UserInfo />
        </Col>
        <Col xs={6} style={{ textAlign: "right" }}>
          <Logout />
        </Col>
      </Row>
      <Row className="justify-content-center" style={{ marginTop: "25px" }}>
        <Col xs={12} sm={10} lg={4} className="mb-5">
          <Card>
            <Card.Header style={{ textAlign: "center" }}>
              <h4>Выбор кассы</h4>
            </Card.Header>
            <Card.Body>
              {uContext.userData.role.split(";").map((x) => {
                if (x !== "MM") {
                  return (
                    <Button
                      key={x}
                      onClick={() => {
                        navigate(`/${x}`);
                      }}
                      variant="outline-primary"
                      className="mb-1 mt-1"
                      style={{ width: "100%" }}
                    >
                      {desks[x]}
                    </Button>
                  );
                }
              })}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default DeskSelection;
