import React, {useState} from 'react';
import "bootstrap/dist/css/bootstrap.css";
import AccountingIncoming from './AccountingIncoming';
import AccountingOutcoming from './AccountingOutcoming';
import {Tabs, Tab} from "react-bootstrap";
import AccountingTerminal from './AccountingTerminal';
import AccountingExcerpt from './AccountingExcerpt';
import AccountingHistory from './AccountingHistory';
import AccountingDepositIP from './AccountingDepositIP';

function AccountingNav() {

  const [state, setState] = useState('incoming');

  return(
    <Tabs
      justify
      id="controlled-tab-example"
      activeKey={state}
      onSelect={(k) => setState(k)}
      //className="mb-3"
      unmountOnExit={true}
    >
      <Tab eventKey="incoming" title="Входящие">
        <AccountingIncoming />
      </Tab>
      {/* <Tab eventKey="outcoming" title="Исходящие">
        <AccountingOutcoming />
      </Tab> */}
      <Tab eventKey="deposits" title="Депозиты ИП">
        <AccountingDepositIP />
      </Tab>
      <Tab eventKey="excerpt" title="Выписки">
        <AccountingExcerpt />
      </Tab>
      <Tab eventKey="terminal" title="Терминал" unmountOnExit={false}>
        <AccountingTerminal />
      </Tab>
      <Tab eventKey="history" title="История">
        <AccountingHistory />
      </Tab>
    </Tabs>
  )

}

export default AccountingNav;
