import React, {useEffect, useContext} from 'react'
import "bootstrap/dist/css/bootstrap.css";
import {Container, Row, Col, Button} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Logout from '../login/Logout';
import SMPNav from './SMPNav';
import RemainderCard from '../general/RemainderCard';
import UserInfo from '../general/UserInfo';
import { UserContext } from '../UserContextManager';
import { SMPContext } from '../SMPContextManager';
import axios from 'axios';
import { DeskChangeBtn } from '../general/DeskChangeBtn';

function SMPDesk(){

  const uContext = useContext(UserContext);
  const smpContext = useContext(SMPContext);

  const navigate = useNavigate();

  const loadUserData = async () => {

    let userDataRes = await axios.get('/redesk/general/remainder',{params:{cfo: 'nu'}});

    let data = await userDataRes.data;

    let draftRes = await axios.get('/redesk/draft');

    let draftData = await draftRes.data;

    data.user_data['draft_data'] = draftData.draft_data;

    uContext.setUserData(data.user_data);

    let roles = data.user_data.role.split(';')
    if (roles.includes('smp')){
      navigate('/smp')    
    }else{
      navigate('/');
    }
  }

  const loadSMPHelpers = async () => {

    let res = await axios.get('/redesk/smp/helpers');
    smpContext.setHelpersData(res.data.helpers_data);
    
  }

  useEffect(() => {
    loadUserData();
    loadSMPHelpers();
    return () => {}
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container fluid>
      <Row className="justify-content-center" style={{'marginTop': '15px'}}>
        <Col xs={6} style={{textAlign: "left"}}>
          <UserInfo />
        </Col>
        <Col xs={6} style={{textAlign: "right"}}>
          {uContext.userData.role.split(';').length > 1 ? 
          (<DeskChangeBtn />) : (<></>)
          }
          <Logout />
        </Col>
      </Row>
      <Row className="justify-content-center mb-5" style={{'marginTop': '100px'}}>
        <Col sm={12} lg={2}>
          <RemainderCard
            bgColor='primary'
            keyProp='nu_main_remainder'
            textColor='white'
            title='Общий остаток'
            value={smpContext.helpersData.remainder_main.toFixed(2)}
            tooltipText='Разница между наличными приходами и наличными расходами'
          />
          <RemainderCard
            bgColor='light'
            keyProp='nu_outcoming_reas_remainder'
            textColor='black'
            title='Исходящие переброски'
            value={smpContext.helpersData.outcoming.toFixed(2)}
            tooltipText='Сумма всех исходящих наличных неподтвержденных перебросок'
          />
          <RemainderCard
            bgColor='light'
            keyProp='nu_incoming_reas_remainder'
            textColor='black'
            title='Входящие переброски'
            value={smpContext.helpersData.incoming.toFixed(2)}
            tooltipText='Сумма всех входящих наличных неподтвержденных перебросок'
          />
        </Col>
        <Col sm={12} lg={8} className='p-0 mb-5'>
          <SMPNav />
        </Col>
        <Col sm={12} lg={2} className='p-0'>
        </Col>
      </Row>
    </Container>
  );
}

export default SMPDesk
