import React, {useState, useEffect} from 'react';
import "bootstrap/dist/css/bootstrap.css";
import {Button} from "react-bootstrap";
import Smp from './Smp';
import DoorInstalation from './DoorInstalation';
import PrepaymentNP from './PrepaymentNP';
import Prepayment from './Prepayment';
import ToolWithholding from './ToolWithholding';

function FoTAddon(props){

  const [addonComponents, setAddonComponents] = useState(props.addonComponents)

  const updateFormData = (k, v) => {
    props.updateFormData(k, v);
  }

  const setFormDataDict = (data) => {
    props.setFormDataDict(data)
  }

  const setFormData = (data) => {
    props.setFormData(data)
  }

  const removeAddon = (e) => {
    props.removeAddon(e.target.getAttribute('list-key'));
  }

  const addDropdownAddon = (e) => {
    props.addDropdownAddon(e.target.getAttribute('list-key'))
  }

  const handleChange = (e, k=null) => {
    props.handleChange(e, k=null);
  }

  const renderSwitch = (param) => {
    switch(param){
      case 'СМП':
        return(
          <Smp
            removeAddon={removeAddon}
            addDropdownAddon={addDropdownAddon}
            dropdownData={props.dropdownData}
            updateFormData={updateFormData}
            formData={props.formData}
            handleChange={handleChange}
            setFormDataDict={setFormDataDict}
            setFormData={setFormData}
          />
        )

      case 'Установка дверей':
        return(
          <DoorInstalation
            removeAddon={removeAddon}
            addDropdownAddon={addDropdownAddon}
            dropdownData={props.dropdownData}
            updateFormData={updateFormData}
            formData={props.formData}
            handleChange={handleChange}
            setFormDataDict={setFormDataDict}
            setFormData={setFormData}
          />
        )

      case 'Предоплата НП':
        return(
          <PrepaymentNP
            removeAddon={removeAddon}
            addDropdownAddon={addDropdownAddon}
            dropdownData={props.dropdownData}
            updateFormData={updateFormData}
            formData={props.formData}
            prepaymentData={props.prepaymentData}
            handleChange={handleChange}
            setFormDataDict={setFormDataDict}
            setFormData={setFormData}
          />
        )

      case 'Предоплата':
        return(
          <Prepayment
            removeAddon={removeAddon}
            addDropdownAddon={addDropdownAddon}
            dropdownData={props.dropdownData}
            updateFormData={updateFormData}
            formData={props.formData}
            prepaymentData={props.prepaymentData}
            handleChange={handleChange}
            setFormDataDict={setFormDataDict}
            setFormData={setFormData}
          />
        )

      case 'Удержание за инструмент':
        return(
          <ToolWithholding
            onChange={props.onChange}
            options={props.options}
            removeAddon={removeAddon}
            addDropdownAddon={addDropdownAddon}
            dropdownData={props.dropdownData}
            updateFormData={updateFormData}
            formData={props.formData}
            handleChange={handleChange}
            setFormDataDict={setFormDataDict}
            setFormData={setFormData}
          />
        )

      default:
        return(
          <div key={Math.random()}>
            <div>{param}</div>
            <Button variant="danger" list-key={param} className="mb-3 mt-2" size="sm" onClick={(e) => {removeAddon(e); addDropdownAddon(e)}}>
              -
            </Button>
          </div>
        )
    }
  }

  useEffect(() => {
    setAddonComponents(props.addonComponents)
    return () => {}
  }, [props.addonComponents])

  return(
    <>
      {addonComponents.map((name) => {
        return(
          renderSwitch(name)
        )
      })}
    </>
  );
}

export default FoTAddon
