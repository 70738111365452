import React, {useState, useEffect} from 'react';
import "bootstrap/dist/css/bootstrap.css";
import Draft from '../general/Draft';
import {Container, Row, Col, Tabs, Tab} from "react-bootstrap";
import NuSOIncoming from './NuSOIncoming'
import NuSOConfirmed from './NuSOConfirmed'
import NuSODeclined from './NuSODeclined';

function NuSOTerminal(){

  const [tabState, setTabState] = useState('terminal-fot')

  useEffect(() => {
    return () => {}
  }, [])


  return(
    <Container fluid className=" shadow pt-2">
      <Row>
        <Col>
            <Tabs
            justify
            id="controlled-inner-tab"
            activeKey={tabState}
            onSelect={(k) => setTabState(k)}
            unmountOnExit={false}
            style={{maxWidth: '450px'}}
            >   
                <Tab eventKey="terminal-fot" title="Входящие" unmountOnExit={true}>
                    <NuSOIncoming />
                </Tab>
                <Tab eventKey="terminal-main" title="Подтвержденные"  unmountOnExit={true}>
                    <NuSOConfirmed />
                </Tab>
                <Tab eventKey="terminal-transition-premium" title="Отмененные"  unmountOnExit={true}>
                    <NuSODeclined />
                </Tab>
            </Tabs>
        </Col>
        <Col xs={12}>
          <Draft />
        </Col>
      </Row>
    </Container>
  )

}

export default NuSOTerminal;
