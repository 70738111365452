import React, {useState, useEffect, useContext} from 'react';
import "bootstrap/dist/css/bootstrap.css";
import {Row, Col, Button, Stack, Form, Modal} from "react-bootstrap";
import { UserContext } from '../UserContextManager';
import { Typeahead, Highlighter } from 'react-bootstrap-typeahead';
import axios from 'axios';
import cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";
import CurrencyInput from 'react-currency-input-field';

function OPMainTerminal(){

  const sourceRef = React.createRef();

  const uContext = useContext(UserContext);
  const navigate = useNavigate();

  const [dropdownData, setDropdownData] = useState([]);
  const [sourceData, setSourceData] = useState([]);
  const [formData, updateFormData] = useState({terminal_payment_type: 'Нал', terminal_item_type: 'Расход'});

  const [itemType, setItemType] = useState('Расход');
  const [item, setItem] = useState('Статья');
  const [subItem, setSubItem] = useState('Подстатья');
  const [paymentType, setPaymentType] = useState('Нал');

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmModalText, setConfirmModalText] = useState('');

  const [sum, setSum] = useState('');

  const loadTerminalDropdown = async () => {
    try{
      let dropdownsRes = await axios.get('/redesk/terminal/main-dropdowns', {params:{desk_type:'op'}});
      setDropdownData(dropdownsRes.data.dropdowns_data);

      let sourceDropdowns = dropdownsRes.data.dropdowns_data.filter(e => e.dropdown_name === 'op_company_name')
      let sourceResult = sourceDropdowns.map(x => x['value'])
      setSourceData(sourceResult);
    } catch (error){
      navigate('/');
    }
  }

  const handleItemType = (e) => {
    
    let formDataCopy = {...formData}
    delete formDataCopy.terminal_item
    formDataCopy.terminal_item_type = e

    setItem('Статья');
    setItemType(e);
    updateFormData(formDataCopy);
    
  }

  const handleItem = (e) => {
    let formDataCopy = {...formData}
    delete formDataCopy.terminal_sub_item
    formDataCopy.terminal_item = e

    setSubItem('Подстатья');
    setItem(e);
    updateFormData(formDataCopy);
  }

  const handlePaymentType = (e) => {

    let formDataCopy = {...formData};
    formDataCopy.terminal_payment_type = e;

    setPaymentType(e);
    updateFormData(formDataCopy);

  }

  const compareDraft = () => {

    let draftCopy = [...uContext.userData.draft_data]
    
    let has_duplicate = false
    draftCopy.forEach((x) => {
      if(
        Math.abs(x.sum) === Number(formData.terminal_sum)
        && x.responsible_user === formData.terminal_responsible_user
        && x.item === formData.terminal_item
        ){
        has_duplicate = true
      }
    })

    return has_duplicate
  }

  const handleChange = (e, k=null) => {

    let newFormData = {}

    if (k !== null){
      newFormData = {...formData, [k]: e}
    }else{
      newFormData = {...formData, [e.target.name]: e.target.value}
    }

    updateFormData(newFormData);
  }

  const handleSubmitWithConfirm = (e) => {

    if (!formData.terminal_item_type){
      alert('Тип статьи не указан!')
      return
    }
    
    if (!formData.terminal_item){
      alert('Статья не указана!')
      return
    }

    if (!formData.terminal_sum || formData.terminal_sum <= 0){
      alert('Сумма не указана!')
      return
    }

    if (!formData.terminal_responsible_user){
      alert('Ответственный не указан!')
      return
    }

    if (formData.terminal_item === 'Поиск сотрудников' && formData.terminal_work_type == 'Оплата объявления' && !sourceData.includes(formData.terminal_company_name)){
      alert('Укажите источник!')
      return
    }

    if (formData.terminal_sum >= 1000000 && compareDraft()){
      setConfirmModalText(`Похожая запись уже есть в черновике, а указанная сумма больше миллиона (${formData.terminal_sum}). Вы уверены?`)
      setShowConfirmModal(true);
      return
    }

    if (formData.terminal_sum >= 1000000){
      setConfirmModalText(`Указанная сумма больше миллиона (${formData.terminal_sum}). Вы уверены?`)
      setShowConfirmModal(true);
      return
    }

    if (compareDraft()){
      setConfirmModalText('Похожая запись уже есть в черновике. Создать еще одну?')
      setShowConfirmModal(true);
      return
    }

    if (item === 'Переброска' && paymentType === 'Безнал'){
      alert('Нельзя делать переброску с таким типом платежа!')
      return
    }
    
    handleSubmit(e)
  }

  const handleSubmit = async (e) => {

    e.preventDefault();

    let draftSendData = formData
    draftSendData['cfo'] = 'ОП'
    draftSendData['desk_owner'] = 'ОП'

    if (draftSendData.terminal_work_type && draftSendData.terminal_work_type  === 'Вид работ'){
      delete draftSendData['terminal_work_type']
    }

    //sourceRef.current.clear();
    let res = await axios.post('/redesk/draft/send', draftSendData, {headers:{'X-CSRF-TOKEN': cookies.get("csrf_access_token")}})
    let contextData = uContext.userData;
    contextData.draft_data = res.data.draft_data;
    uContext.setUserData(contextData);
    
    /*setShowConfirmModal(false);
    document.getElementById("terminal_main_form").reset();
    setItemType('Расход');
    setItem('Статья');
    setPaymentType('Нал');
    setConfirmModalText('');
    updateFormData({terminal_payment_type: 'Нал', terminal_item_type: 'Расход'});
    setSum('');*/
  }

  useEffect(() => {
    loadTerminalDropdown();
    return () => {}
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return(
    <Form id='terminal_main_form' className='mt-2'>
        <Row>
          <Form.Group as={Col} className="mb-3" controlId="t_i_t" >
            <Form.Label>Тип статьи</Form.Label>
            <Form.Select size="sm" name="terminal_item_type" value={itemType} aria-label="terminal_item_type" onChange={(e) => {handleItemType(e.target.value)}}>
              <option key="Приход" value="Приход">Приход</option>
              <option key="Расход" value="Расход">Расход</option>
            </Form.Select>
        </Form.Group>
        <Form.Group as={Col} className="mb-3" controlId="t_i">
            <Form.Label>Статья</Form.Label>
            <Form.Select size="sm" name="terminal_item" value={item} aria-label="terminal_item" onChange={(e) => {handleItem(e.target.value)}}>
              <option key="Статья" disabled>Статья</option>
              {dropdownData.map(({value, label, dropdown_name, spec}, index) => {if(dropdown_name === 'op_item' && spec === itemType){return <option key={value} value={value}>{label}</option>}return null})}
            </Form.Select>
          </Form.Group>
        </Row>
        <Row>
          <Col xs={6}>
            <Form.Group className="mb-3" controlId="t_s">
            <Form.Label>Сумма</Form.Label>
            <CurrencyInput
              autoComplete='off'
              id="terminal_sum"
              name="terminal_sum"
              className='form-control'
              style={{maxHeight: '30px'}}
              value={sum}
              placeholder="Сумма"
              decimalsLimit={2}
              allowNegativeValue={false}
              groupSeparator={' '}
              onValueChange={(e) => {setSum(e); handleChange(e, 'terminal_sum')}}
            />
            </Form.Group>
          </Col>
          <Form.Group as={Col} className="mb-3" controlId="t_m">
            <Form.Label>Подстатья</Form.Label>
            <Form.Select size="sm" name="terminal_sub_item" value={subItem} aria-label="terminal_sub_item" onChange={(e) => {handleChange(e); setSubItem(e.target.value)}}>
              <option key="Подстатья" disabled>Подстатья</option>
              {item === 'ФОТ' 
              ? Array.from({length: 12}, (_, i) => i + 1).map((value, index) => {return <option key={value} value={value}>{value}</option>}) 
              : dropdownData.map(({value, label, dropdown_name}, index) => {if(dropdown_name === 'op_sub_item'){return <option key={value} value={value}>{label}</option>}return null})}
            </Form.Select>
          </Form.Group>
          
        </Row>
        <Row>
          <Form.Group as={Col} className="mb-3" controlId="t_o">
            <Form.Label>Вид работ</Form.Label>
            <Form.Select size="sm" name="terminal_work_type" defaultValue="Вид работ" aria-label="terminal_work_type" onChange={handleChange}>
              <option key="Вид работ" value="Вид работ">Вид работ</option>
              {dropdownData.map(({value, label, dropdown_name}, index) => {if(dropdown_name === 'op_work_type'){return <option key={value} value={value}>{label}</option>}return null})}
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} className="mb-3" controlId="t_c_n">
              <Form.Label>Источник</Form.Label>
              <Typeahead
                id='terminal_company_name'
                name='terminal_company_name'
                ref = {sourceRef}
                placeholder='Источник'
                maxResults={7}
                emptyLabel='Нет совпадений'
                paginationText='Показать больше'
                highlightClassName='font-weight-bold'
                onInputChange={(text) => {handleChange(text, 'terminal_company_name'); }}
                onChange={(selected) => {handleChange(selected[0], 'terminal_company_name');}}
                options={sourceData}
                size='sm'
                renderMenuItemChildren={(option, props, idx) => (
                  <Highlighter search={props.text} highlightClassName='fw-bold mx-0 px-0 bg-transparent'>
                    {option}
                  </Highlighter>
                )}
              />
            </Form.Group>
        </Row>
        <Row>
          
          <Form.Group as={Col} className="mb-3" controlId="t_r_u">
            <Form.Label>Ответственный</Form.Label>
            <Form.Select size="sm" name="terminal_responsible_user" defaultValue="Ответственный" aria-label="terminal_responsible_user" onChange={handleChange}>
              <option key="Ответственный" value="Ответственный">Ответственный</option>
              {dropdownData.map(({value, label, dropdown_name}, index) => {if(dropdown_name === 'op_responsible_user'){return <option key={value} value={value}>{label}</option>}return null})}
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} className="mb-3" controlId="t_p_t">
            <Form.Label>Тип платежа</Form.Label>
            <Form.Select size="sm" name="terminal_payment_type" value={paymentType} aria-label="terminal_payment_type" onChange={(e) => {handlePaymentType(e.target.value)}}>
              <option key="Безнал" value="Безнал">Безнал</option>
              <option key="Нал" value="Нал">Нал</option>
            </Form.Select>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} className="mb-3" controlId="t_c">
            <Form.Label>Примечание</Form.Label>
            <Form.Control size="sm" autoComplete='off' name="terminal_comment" placeholder="Примечание" type="text" onChange={handleChange} />
          </Form.Group>
          <Col xs={6}></Col>
        </Row>
        <Row className="mb-2">
        <Stack gap={2}>
          <Button variant="primary" className="mb-3 mt-2 mx-auto" onClick={handleSubmitWithConfirm}>
            Отправить
          </Button>
        </Stack>
      </Row>
      <Modal centered show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
      <Modal.Body>
        <p>{confirmModalText}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick = {() => setShowConfirmModal(false)}>
          Нет
        </Button>
        <Button variant="danger" style={{minWidth: "12%"}} onClick={(e) => {handleSubmit(e); setShowConfirmModal(false)}}>
          Да
        </Button>
      </Modal.Footer>
    </Modal>
    </Form>
  )
}


export default OPMainTerminal;
