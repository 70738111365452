import React, {useState, useEffect, useContext} from 'react';
import "bootstrap/dist/css/bootstrap.css";
import {Row, Col, Button, Table, Modal, Form, Spinner} from "react-bootstrap";
import axios from 'axios';
import cookies from 'js-cookie';
import ReactPaginate from 'react-paginate';
import { AccountingContext } from '../AccountingContextManager';
import NumberFormat from 'react-number-format';
import { Typeahead, Highlighter } from 'react-bootstrap-typeahead';
import { MultiSelect } from 'primereact/multiselect';


function AccountingExcerptList(){

  const aContext = useContext(AccountingContext);

  const [isLoading, setIsLoading] = useState(false)

  const [excerptList, setExcerptList] = useState([]);
  const [selectedExcerpt, setSelectedExcerpt] = useState([]);
  const [selectedExcerptInitialSum, setSelectedExcerptInitialSum] = useState(0);
  const [selectedExcerptInitialPurpose, setSelectedExcerptInitialPurpose] = useState('');
  const [selectedExcerptInitialSource, setSelectedExcerptInitialSource] = useState('');
  const [selectedExcerptFactSum, setSelectedExcerptFactSum] = useState(0);

  const [uniqueAccounts, setUniqueAccounts] = useState([]);
  const [accountFilter, setAccountFilter] = useState([]);


  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsTotal, setItemsTotal] = useState(0);


  const [splitModalShow, setSplitModalShow] = useState(false)

  const showSelectedExcerpt = (e) => {

    let EListCopy = excerptList.map(item => ({...item}))

    let selectedRow = EListCopy.find((element) => {return element.uuid === e.currentTarget.getAttribute('uuid')})
    setSelectedExcerpt([selectedRow]);
    setSelectedExcerptInitialSum(selectedRow.ex_sum);
    setSelectedExcerptInitialPurpose(selectedRow.payment_purpose);
    setSelectedExcerptInitialSource(selectedRow.source);
    setSelectedExcerptFactSum(selectedRow.ex_sum);
    selectedRow.ex_sum = selectedRow.ex_sum;
    setSplitModalShow(true);
  }

  const countSplittedSum = (e) => {

    let changedSum = (e.target.value === '' || isNaN(e.target.value.replace(',', '.'))) ? 0 :parseFloat(e.target.value.replace(',', '.'))

    let currentSelectedExcerpt = [...selectedExcerpt]
    let index = currentSelectedExcerpt.findIndex(x => x.uuid === e.target.id);
    currentSelectedExcerpt[index].ex_sum = changedSum;
    

    let newSum = currentSelectedExcerpt.map((x) => x.ex_sum).reduce((a, b) => a+b);
    let newSumFormatted = parseFloat(newSum.toFixed(2));

    setSelectedExcerptFactSum(newSumFormatted);
    setSelectedExcerpt(currentSelectedExcerpt)
  }

  const deleteSplittedExcerpt = (e) => {

    let currentSelectedExcerpt = [...selectedExcerpt]
    let newSelectedList = currentSelectedExcerpt.filter(item => item.uuid.toString() !== e.target.id.toString());


    let newSum = newSelectedList.map((x) => parseInt(x.ex_sum)).reduce((a, b) => a+b);

    setSelectedExcerptFactSum(newSum);
    setSelectedExcerpt(newSelectedList);
  }

  const changeSelectedProperty = (e) => {
    let currentSelectedExcerpt = [...selectedExcerpt]
    let index = currentSelectedExcerpt.findIndex(x => x.uuid === e.target.id);

    currentSelectedExcerpt[index][e.target.name] = e.target.value;
    setSelectedExcerpt(currentSelectedExcerpt);
  }

  const splitSelectedExcerpt = () => {
    
    let exListcopy = [...selectedExcerpt]

    let initialRowCopy = {...selectedExcerpt.find((element) => {return isNaN(element.uuid)})};
    let newRow = {...initialRowCopy}

    

    newRow.uuid = (Math.random()).toString();
    newRow.ex_sum = 0;
    newRow.payment_purpose = selectedExcerptInitialPurpose;
    newRow.source = selectedExcerptInitialSource;

    exListcopy.push(newRow)

    setSelectedExcerpt(exListcopy);
    
  };

  const confirmSplit = async () => {

    if (selectedExcerptFactSum !== selectedExcerptInitialSum){
      alert('Суммы записей должны совпадать с изначальной суммой!');
      return
    }
    
    let blankList = selectedExcerpt.filter((x) => {return x.ex_sum === 0 || isNaN(x.ex_sum)})
    if (blankList.length > 0){
      alert('Невозможно разделить выписку: сумма некоторых строк равна нулю или заполнена неправильно!')
      return
    }

    await axios.post('/redesk/accounting/excerpt/post-split', selectedExcerpt, {headers:{'X-CSRF-TOKEN': cookies.get("csrf_access_token")}});

    setSplitModalShow(false);
    loadExcerptList();

  }


  const loadAccountingHelpers = async () => {
    let res = await axios.get('/redesk/accounting/helpers');
    aContext.setHelpersData(res.data.helpers_data);
  }

  const loadExcerptList = async (currentPage) => {

    setIsLoading(true);

    let paginationParams = {
      'page': currentPage,
      'per_page': 1500,
    }

    let res = await axios.get('/redesk/accounting/excerpt', {params: paginationParams});

    setExcerptList(res.data.excerpt_list);

    let uniqueAccounts = [...new Set(res.data.excerpt_list.map(item => item.organization))]
    setUniqueAccounts(uniqueAccounts)

    setIsLoading(false);

    setPageCount(Math.ceil(res.data.total/10))
    setItemsTotal(res.data.total);

    initExcerptListSummarizer_loaded();    
  }

  const handleAccountingFilterChange = (selected) => {
    if (selected === undefined){
      return
    }else{
      setAccountFilter(selected)
    }
  }

  const handlePageClick = async (data) => {

    setCurrentPage(data.selected);

    loadExcerptList(data.selected);

  };


  const saveComment = async (uuid, text) => {
    let currentSelectedExcerpt = [...excerptList]
    let index = currentSelectedExcerpt.findIndex(x => x.uuid === uuid);

    currentSelectedExcerpt[index]['comment'] = text;
    setExcerptList(currentSelectedExcerpt);

    await axios.patch('/redesk/accounting/excerpt/save-comment', {uuid: uuid, comment: text}, {headers:{'X-CSRF-TOKEN': cookies.get("csrf_access_token")}});
  }

  const handleCheckBox = async (e) => {

    let listCopy = [...excerptList]

    let oi = listCopy.findIndex(x => x.uuid === e.target.id)

    if (e.target.checked){
      listCopy[oi].checked = true
    }else{
      listCopy[oi].checked = false
    }

    setExcerptList(listCopy);

    await axios.patch('/redesk/accounting/excerpt/save-checked', {uuid: e.target.id, checked: e.target.checked}, {headers:{'X-CSRF-TOKEN': cookies.get("csrf_access_token")}});

    processExcerptListCheckbox(e.target.id, e.target.dataset.sum, e.target.checked);
    recalcListSelectedSumms();
  }

  const confirmSelected = async (e) => {

    let listCopy = [...excerptList]
    let confirmBatch = []

    listCopy.forEach(function(x){
      if (x.checked){
        confirmBatch.push(x.uuid)
      }
    })

    await axios.patch('/redesk/accounting/excerpt', confirmBatch, {headers:{'X-CSRF-TOKEN': cookies.get("csrf_access_token")}});

    loadExcerptList();
    loadAccountingHelpers();

  }

  const initExcerptListSummarizer = () =>
  {
    if( typeof( window.redesk_app.accounting.loaded_excerpts ) )
    {
      if( ! window.redesk_app.accounting.loaded_excerpts.hasOwnProperty('is_inited') )
      {
        window.redesk_app.accounting.loaded_excerpts = { is_inited: true,  selected_summs: [], selected_items: [], summ: 0 }
      }
      else
      {
        window.redesk_app.accounting.loaded_excerpts.selected_summs = [];
        window.redesk_app.accounting.loaded_excerpts.selected_items = [];
        window.redesk_app.accounting.loaded_excerpts.summ = 0;
      }
    }
  }

  const initExcerptListSummarizer_loaded = () =>
  {
    let items = document.querySelectorAll('div.accnt-exc-list-maincontainer input[type=checkbox]');

    for( let i = 0; i < items.length; i++){ 
    
      if( items[i].checked )
      { 
        processExcerptListCheckbox( items[i].id, items[i].dataset.sum, true );
      }
  
    }
    recalcListSelectedSumms();
  }

  const recalcListSelectedSumms = () =>
  {
    window.redesk_app.accounting.loaded_excerpts.selected_summs = [];
    window.redesk_app.accounting.loaded_excerpts.summ = 0;

    for( let i = 0; i < window.redesk_app.accounting.loaded_excerpts.selected_items.length; i++)
    { 
      let current_sum = parseFloat(window.redesk_app.accounting.loaded_excerpts.selected_items[i].sum);
      window.redesk_app.accounting.loaded_excerpts.selected_summs.push( current_sum );

      window.redesk_app.accounting.loaded_excerpts.summ = window.redesk_app.accounting.loaded_excerpts.summ + current_sum;
    }

    document.getElementById('accnt-exc-list-selected-sum').innerHTML = new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format( window.redesk_app.accounting.loaded_excerpts.summ );
  }

  const processExcerptListCheckbox = (uuid, sum, checked) =>
  {  
    if( checked )
    {
      window.redesk_app.accounting.loaded_excerpts.selected_items.push( {'uuid': uuid, 'sum': sum } );
    }
    else
    {
      for( let i = 0; i < window.redesk_app.accounting.loaded_excerpts.selected_items.length; i++){ 
    
        if ( window.redesk_app.accounting.loaded_excerpts.selected_items[i].uuid == uuid)
        { 
          window.redesk_app.accounting.loaded_excerpts.selected_items.splice(i, 1); 
        }
      }
    }
  }

  useEffect(() => {
    loadExcerptList();
    loadAccountingHelpers();
    initExcerptListSummarizer();
    return () => {}
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  let AccountingExcerptList_AcceptButton = <Button variant="primary" size="sm" onClick={confirmSelected}>Подтвердить</Button> ;

  let AccountingExcerptList_DetailModalDlg = <Modal show={splitModalShow} fullscreen={true} onHide={() => {setSplitModalShow(false)}}>
  <Modal.Header closeButton>
    <Modal.Title>{selectedExcerptInitialPurpose}</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <Table striped bordered size="sm" style={{textAlign: "center"}} responsive>
      <thead>
        <tr>
        <th>Дата</th>
        <th>Сумма</th>
        <th>ИНН</th>
        <th>Счет</th>
        <th>Назначение платежа</th>
        <th>Источник</th>
        <th>Комментарий</th>
        <th></th>
        </tr>
      </thead>
      <tbody>
        {
          selectedExcerpt.map((item) => (
            <tr key={item.uuid} uuid={item.uuid}>
            <td>{item.date}</td>
            <td>
              <Form.Group style={{margin: '0'}}>
                <Form.Control
                  id={item.uuid}
                  size="sm"
                  name="ex_sum"
                  placeholder='0'
                  defaultValue={item.ex_sum == 0 ? '' : item.ex_sum}
                  onInput={(e) => {countSplittedSum(e);}}
                />
              </Form.Group>
            </td>
            <td>{item.inn}</td>
            <td>{item.organization}</td>
            <td>
              <Form.Group style={{margin: '0'}}>
                <Form.Control
                  id={item.uuid}
                  size="sm"
                  name="payment_purpose"
                  defaultValue={item.payment_purpose}
                  onInput={changeSelectedProperty}
                />
              </Form.Group>
            </td>
            <td>
              <Form.Group style={{margin: '0'}}>
                <Form.Control
                  id={item.uuid}
                  size="sm"
                  name="source"
                  defaultValue={item.source}
                  onInput={changeSelectedProperty}
                />
              </Form.Group>
            </td>
            <td>
              <Form.Group style={{margin: '0'}}>
                <Form.Control
                  id={item.uuid}
                  size="sm"
                  name="comment"
                  defaultValue={item.comment}
                  onInput={changeSelectedProperty}
                />
              </Form.Group>
            </td>
            <td>
            {isNaN(item.uuid) ? (
              <></>
            ) : (
              <Button variant="danger" id={item.uuid}
              style={{maxHeight: '30px', paddingTop: '0', paddingBottom: '0'}}
              className="align-items-center"
              onClick={deleteSplittedExcerpt}>
                -
              </Button>
            )}
            </td>
            
            </tr>
          ))
        }
      </tbody>
    </Table>
  </Modal.Body>
  <Modal.Footer>
    <Row style={{minWidth: '100%', textAlign: 'center', margin: '0'}}>
      <Col lg={4} style={{textAlign: 'left', color: selectedExcerptFactSum === selectedExcerptInitialSum ? 'green' : 'red'}}>
        <p style={{margin: '0', paddingTop: '8px'}}>Общая сумма:
          <strong>
            <NumberFormat
              style={{marginLeft: '5px'}}
              value={selectedExcerptInitialSum}
              displayType={'text'}
              thousandSeparator={' '}
              decimalSeparator={'.'}
              suffix={' ₽'}
              />
          </strong>
        </p>
      </Col>
      <Col lg={4}>
        <Button variant="success" onClick={splitSelectedExcerpt}>
          +
        </Button>
      </Col>
      <Col lg={4} style={{textAlign: 'end'}}>
        <Button variant="primary" onClick={confirmSplit} disabled={selectedExcerpt.length === 1 ? true : false}>
          Подтвердить
        </Button>
      </Col>
    </Row>

  </Modal.Footer>
  </Modal>;

  return(
    <>
    {excerptList.length > 0 && isLoading === false ? (
    <Col lg={10} style={{textAlign: "left"}}>
    <Form.Group controlId="a_a_f" >
        <MultiSelect
            value = {accountFilter}
            options={uniqueAccounts}
            placeholder="Счет"
            maxSelectedLabels={1}
            onChange={(e) => {setAccountFilter(e.value)}}
            showSelectAll={true}
            selectedItemsLabel={`${accountFilter.length} Выбрано`}
            scrollHeight='350px'
            className='small'
          />
    </Form.Group>
    </Col>
    ) : (<></>)}
  <React.Fragment>
    <Row style={{minHeight: '200px', paddingRight: '0px'}}>
      <Col lg={12} className="overflow-auto accnt-exc-list-maincontainer">
      {excerptList.length > 0 && isLoading === false ? (
        <Table striped bordered size="sm" style={{textAlign: "center", marginTop: '5px'}} responsive className="accnt-exc-list-maintable">
          <thead >
            <tr>
              <th>Дата</th>
              <th>Сумма</th>
              <th>Назначение платежа</th>
              <th>Счет</th>
              <th>Коммент.</th>
              <th>Подтв.</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {
              excerptList.filter(item => (accountFilter.includes(item.organization) || accountFilter.length < 1)).map((item) => (
                <tr key={item.uuid} uuid={item.uuid} >
                  <td className="align-middle accnt-exc-list-date-col">{item.date}</td>
                  <td className="align-middle accnt-exc-list-sum-col">
                  <p>
                  {window.redesk_app.common.fmtFinValue.format(item.ex_sum)}
                  </p>
                  </td>
                  <td class="align-middle accnt-exc-list-purp-col">
                  <p class="text-start lh-sm">{item.payment_purpose}</p><p class="text-end lh-sm accnt-exc-list-subsource"><span>Контрагент: {item.source}</span></p></td>
                  <td class="align-middle accnt-exc-list-org-col">{item.organization}</td>
                  <td class="align-middle accnt-exc-list-comment-col">
                    <Form.Group style={{margin: '0'}}>
                      <Form.Control
                        as="textarea"
                        key={item.uuid}
                        size="sm"
                        name="comment"
                        rows="1"
                        onChange={(e) => {saveComment(item.uuid, e.target.value)}}
                        defaultValue={item.comment}
                      />
                    </Form.Group>
                  </td>
                  <td class="align-middle " style={{alignItems: 'center'}}>
                  <Form.Check
                      style={{cursor: 'pointer !important'}}
                      type='checkbox'
                      id={item.uuid}
                      key={item.uuid}
                      ex_sum={item.ex_sum}
                      checked={item.checked}
                      onChange={handleCheckBox}
                      data-sum={item.ex_sum}
                    />
                  </td>
                  <td class="align-middle">
                  <Button key={item.uuid} uuid={item.uuid} variant="success" size="sm" onClick={showSelectedExcerpt}>
                    <span class="material-icons">library_add</span>
                  </Button>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </Table>
      ) : (excerptList.length < 1 && isLoading === false) ? (
        <Col sm={12} style={{textAlign: "center"}}>
          <h4 style={{marginTop: "15%", marginBottom: "15%"}}>Нет выписок</h4>
        </Col>
      ) : (
        <Col sm={12} style={{textAlign: "center"}}>
          <div style={{marginTop: "15%", marginBottom: "15%"}}>
            <Spinner animation="border" variant="primary"/>
          </div>
        </Col>
      )}
      </Col>
    </Row>
    <Row>
      <Col lg={2} className="align-middle">
        <span id="accnt-exc-list-selected-sum"> -- </span>
      </Col>
      <Col lg={10} style={{padding: '5px 5px 0px 0px', textAlign: 'right'}} className='justify-content-end'>
        {excerptList.length > 0 ? ( AccountingExcerptList_AcceptButton ) : <></> }
      </Col>
    </Row>

    {AccountingExcerptList_DetailModalDlg}

  </React.Fragment>
  </>
  );
}

export default AccountingExcerptList;
