import React, { useState } from 'react';
import "bootstrap/dist/css/bootstrap.css";
import IncomingItems from '../general/IncomingItems';
import {Tabs, Tab} from "react-bootstrap";
import DKTerminal from './DKTerminal';
import DKHistory from './DKHistory';

function DKNav() {

  const [state, setState] = useState('terminal');

  return(
    <Tabs
      justify
      id="controlled-tab-example"
      activeKey={state}
      onSelect={(k) => setState(k)}
      //className="mb-3"
      unmountOnExit={true}
    >
      <Tab eventKey="terminal" title="Терминал" unmountOnExit={false}>
        <DKTerminal/>
      </Tab>
      <Tab eventKey="profile" title="Переброски">
        <IncomingItems />
      </Tab>
      <Tab eventKey="contact" title="История">
        <DKHistory/>
      </Tab>
    </Tabs>
  )

}

export default DKNav;
