import React, { useState, useEffect, useContext } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Row, Col, Button, Stack, Form, Modal } from "react-bootstrap";
import { UserContext } from "../UserContextManager";
import { ManagementSpbContext } from "../ManagementSpbContextManager";
import axios from "axios";
import cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import CurrencyInput from "react-currency-input-field";
import { Typeahead, Highlighter } from "react-bootstrap-typeahead";

function ManagementSpbMainTerminal() {
  const uContext = useContext(UserContext);
  const navigate = useNavigate();
  const mContext = useContext(ManagementSpbContext);

  const [dropdownData, setDropdownData] = useState([]);
  const [formData, updateFormData] = useState({
    terminal_payment_type: "Нал",
    terminal_item_type: "Расход",
  });

  const [CFOData, setCFOData] = useState([]);
  const [incomeItemData, setIncomeItemData] = useState([]);
  const [outcomeItemData, setOutcomeItemData] = useState([]);
  const [responsibleData, setResponsibleData] = useState([]);
  const [defaultResponsibles, setDefaultResponsibles] = useState([]);
  const [subItemData, setSubItemData] = useState([]);

  const [objectData, setObjectData] = useState([]);

  const [responsible, setResponsible] = useState("");
  const [subItem, setSubItem] = useState("");

  const [CFO, setCFO] = useState("ЦФО");

  const [itemType, setItemType] = useState("Расход");
  const [item, setItem] = useState("Статья");
  const [paymentType, setPaymentType] = useState("Нал");

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmModalText, setConfirmModalText] = useState("");

  const [sum, setSum] = useState("");

  const loadTerminalDropdown = async () => {
    try {
      let dropdownsRes = await axios.get("/redesk/management_spb/dropdowns");
      setDropdownData(dropdownsRes.data.dropdowns_data);

      let cfoList = [];
      dropdownsRes.data.dropdowns_data.forEach(function (x) {
        if (x.dropdown_name === "management_spb_cfo") {
          cfoList.push(x.value);
        }
      });

      let incomeItemList = [];
      dropdownsRes.data.dropdowns_data.forEach(function (x) {
        if (x.dropdown_name === "management_spb_item" && x.spec === "Приход") {
          incomeItemList.push(x.value);
        }
      });

      let outcomeItemList = [];
      dropdownsRes.data.dropdowns_data.forEach(function (x) {
        if (x.dropdown_name === "management_spb_item" && x.spec === "Расход") {
          outcomeItemList.push(x.value);
        }
      });

      setCFOData(cfoList);
      setIncomeItemData(incomeItemList);
      setOutcomeItemData(outcomeItemList);
    } catch (error) {
      navigate("/");
    }
  };

  const loadObjectHelpers = async (objectId) => {
    if (objectData.includes(objectId)) {
      let res = await axios.get(
        `/redesk/management_spb/object_helpers/${objectId}`
      );
      mContext.setHelpersData({
        ...mContext.helpersData,
        ...res.data.object_helpers,
      });
    } else {
      mContext.setHelpersData({
        ...mContext.helpersData,
        loan: 0,
        prepayment: 0,
      });
    }
  };

  const loadObjectData = async () => {
    let res = await axios.get("/redesk/management_spb/objects");
    setObjectData(res.data.objects_data);
  };

  const handleItemType = (e) => {
    let formDataCopy = { ...formData };
    delete formDataCopy.terminal_item;
    formDataCopy.terminal_item_type = e;

    setItem("Статья");
    setItemType(e);
    updateFormData(formDataCopy);
  };

  const loadResponsibleData = async () => {
    let res = await axios.get("/redesk/management_spb/responsible-users");
    setResponsibleData(res.data.responsible_users);
    setDefaultResponsibles(res.data.default_responsibles);
  };

  const handlePaymentType = (e) => {
    let formDataCopy = { ...formData };
    formDataCopy.terminal_payment_type = e;

    setPaymentType(e);
    updateFormData(formDataCopy);
  };

  const handleCFO = (e) => {
    let formDataCopy = { ...formData };
    formDataCopy.terminal_cfo = e;

    setCFO(e);
    updateFormData(formDataCopy);
  };

  const compareDraft = () => {
    let draftCopy = [...uContext.userData.draft_data];

    let has_duplicate = false;
    draftCopy.forEach((x) => {
      if (
        Math.abs(x.sum) === Number(formData.terminal_sum) &&
        x.responsible_user === responsible &&
        x.item === formData.terminal_item &&
        x.object_id === formData.terminal_object_id &&
        x.payment_type === paymentType
      ) {
        has_duplicate = true;
      }
    });

    return has_duplicate;
  };

  const handleChange = (e, k = null) => {
    let newFormData = {};

    if (k !== null) {
      newFormData = { ...formData, [k]: e };
    } else {
      newFormData = { ...formData, [e.target.name]: e.target.value };
    }

    updateFormData(newFormData);
  };

  const handleSubmitWithConfirm = (e) => {
    if (!CFOData.includes(CFO)) {
      alert("ЦФО указан неправильно!");
      return;
    }

    if (
      objectData.indexOf(formData.terminal_object_id) < 0 &&
      (item === "Кредит" ||
        item === "Доп. расходы на объекты (брак работ)" ||
        item === "Доп. расходы на объекты (не брак)" ||
        item === "ФОТ Мастеров" ||
        item === "Предоплата" ||
        item === "Возврат за брак" ||
        item === "Доп. приходы за объекты" ||
        item === "Выручка")
    ) {
      alert("ИД объекта указан неправильно!");
      return;
    }

    if (!formData.terminal_item_type) {
      alert("Тип статьи не указан!");
      return;
    }

    if (!incomeItemData.includes(item) && !outcomeItemData.includes(item)) {
      alert("Статья указана неправильно!");
      return;
    }

    if (!formData.terminal_sum || formData.terminal_sum <= 0) {
      alert("Сумма не указана!");
      return;
    }

    if (
      item !== "Переброска" &&
      defaultResponsibles.concat(responsibleData).indexOf(responsible) < 0
    ) {
      alert("Ответственный указан неправильно!");
      return;
    }

    if (item === "Переброска" && defaultResponsibles.indexOf(responsible) < 0) {
      alert("Ответственный указан неправильно!");
      return;
    }

    if (
      formData.terminal_item === "Переброска" &&
      formData.terminal_responsible_user === uContext.userData.abbreviation
    ) {
      alert("Нельзя делать переброску на самого себя!");
      return;
    }

    if (formData.terminal_sum >= 1000000 && compareDraft()) {
      setConfirmModalText(
        `Похожая запись уже есть в черновике, а указанная сумма больше миллиона (${formData.terminal_sum}). Вы уверены?`
      );
      setShowConfirmModal(true);
      return;
    }

    if (formData.terminal_sum >= 1000000) {
      setConfirmModalText(
        `Указанная сумма больше миллиона (${formData.terminal_sum}). Вы уверены?`
      );
      setShowConfirmModal(true);
      return;
    }

    if (compareDraft()) {
      setConfirmModalText(
        "Похожая запись уже есть в черновике. Создать еще одну?"
      );
      setShowConfirmModal(true);
      return;
    }

    handleSubmit(e);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let draftSendData = formData;
    draftSendData["cfo"] = CFO;
    draftSendData["terminal_responsible_user"] = responsible;
    draftSendData["terminal_sub_item"] = subItem;
    draftSendData["desk_owner"] = "СПб";

    if (
      draftSendData["terminal_work_type"] &&
      draftSendData["terminal_work_type"] === "Вид работ"
    ) {
      delete draftSendData["terminal_work_type"];
    }

    let res = await axios.post("/redesk/draft/send", draftSendData, {
      headers: { "X-CSRF-TOKEN": cookies.get("csrf_access_token") },
    });
    let contextData = uContext.userData;
    contextData.draft_data = res.data.draft_data;
    uContext.setUserData(contextData);
  };

  useEffect(() => {
    loadTerminalDropdown();
    loadObjectData();
    loadResponsibleData();
    return () => {};
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Form id="terminal_main_form" className="mt-2">
      <Row>
        <Col xs={6}>
          <Form.Group className="mb-3" controlId="t_r_u">
            <Form.Label>ЦФО</Form.Label>
            <Typeahead
              id="terminal_cfo"
              name="terminal_cfo"
              labelKey={"value"}
              placeholder="ЦФО"
              maxResults={7}
              emptyLabel="Нет совпадений"
              paginationText="Показать больше"
              highlightClassName="font-weight-bold"
              onChange={(selected) => {
                setCFO(selected[0]);
              }}
              onInputChange={(text) => {
                setCFO(text);
              }}
              options={CFOData}
              size="sm"
              renderMenuItemChildren={(option, props, idx) => (
                <Highlighter
                  search={props.text}
                  highlightClassName="fw-bold mx-0 px-0 bg-transparent"
                >
                  {option}
                </Highlighter>
              )}
            />
          </Form.Group>
        </Col>
        <Form.Group as={Col} className="mb-3" controlId="t_i_t">
          <Form.Label>Тип статьи</Form.Label>
          <Form.Select
            size="sm"
            name="terminal_item_type"
            value={itemType}
            aria-label="terminal_item_type"
            onChange={(e) => {
              handleItemType(e.target.value);
            }}
          >
            <option key="Приход" value="Приход">
              Приход
            </option>
            <option key="Расход" value="Расход">
              Расход
            </option>
          </Form.Select>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} className="mb-3" controlId="t_r_u">
          <Form.Label>Статья</Form.Label>
          <Typeahead
            id="terminal_item"
            name="terminal_item"
            labelKey={"value"}
            placeholder="Статья"
            maxResults={7}
            emptyLabel="Нет совпадений"
            paginationText="Показать больше"
            highlightClassName="font-weight-bold"
            onChange={(selected) => {
              handleChange(selected[0], "terminal_item");
              setItem(selected[0]);
            }}
            onInputChange={(text) => {
              handleChange(text, "terminal_item");
              setItem(text);
            }}
            options={itemType === "Приход" ? incomeItemData : outcomeItemData}
            size="sm"
            renderMenuItemChildren={(option, props, idx) => (
              <Highlighter
                search={props.text}
                highlightClassName="fw-bold mx-0 px-0 bg-transparent"
              >
                {option}
              </Highlighter>
            )}
          />
        </Form.Group>
        <Form.Group as={Col} className="mb-3" controlId="t_o">
          <Form.Label>Вид работ</Form.Label>
          <Form.Select
            size="sm"
            name="terminal_work_type"
            defaultValue="Вид работ"
            aria-label="terminal_work_type"
            onChange={handleChange}
          >
            <option key="Вид работ" value="Вид работ">
              Вид работ
            </option>
            {dropdownData.map(({ value, label, dropdown_name }, index) => {
              if (dropdown_name === "management_spb_work_type") {
                return (
                  <option key={value} value={value}>
                    {label}
                  </option>
                );
              }
              return null;
            })}
          </Form.Select>
        </Form.Group>
      </Row>
      <Row>
        <Col xs={6}>
          <Form.Group className="mb-3" controlId="t_s">
            <Form.Label>Сумма</Form.Label>
            <CurrencyInput
              id="terminal_sum"
              name="terminal_sum"
              className="form-control"
              style={{ maxHeight: "30px" }}
              value={sum}
              placeholder="Сумма"
              decimalsLimit={2}
              allowNegativeValue={false}
              groupSeparator={" "}
              onValueChange={(e) => {
                setSum(e);
                handleChange(e, "terminal_sum");
              }}
            />
          </Form.Group>
        </Col>
        <Form.Group as={Col} className="mb-3" controlId="t_p_t">
          <Form.Label>Тип платежа</Form.Label>
          <Form.Select
            size="sm"
            name="terminal_payment_type"
            value={paymentType}
            aria-label="terminal_payment_type"
            onChange={(e) => {
              handlePaymentType(e.target.value);
            }}
          >
            <option key="Безнал" value="Безнал">
              Безнал
            </option>
            <option key="Нал" value="Нал">
              Нал
            </option>
          </Form.Select>
        </Form.Group>
      </Row>
      <Row>
        <Col xs={6}>
          <Form.Group className="mb-3" controlId="t_o_id">
            <Form.Label>Ид объекта</Form.Label>
            <Typeahead
              id="terminal_object_id"
              name="terminal_object_id"
              placeholder="Ид объекта"
              maxResults={7}
              emptyLabel="Нет совпадений"
              paginationText="Показать больше"
              highlightClassName="font-weight-bold"
              onInputChange={(text) => {
                handleChange(text, "terminal_object_id");
                loadObjectHelpers(text);
              }}
              onChange={(selected) => {
                handleChange(selected[0], "terminal_object_id");
                loadObjectHelpers(selected[0]);
              }}
              options={objectData}
              size="sm"
              renderMenuItemChildren={(option, props, idx) => (
                <Highlighter
                  search={props.text}
                  highlightClassName="fw-bold mx-0 px-0 bg-transparent"
                >
                  {option}
                </Highlighter>
              )}
            />
          </Form.Group>
        </Col>
        <Form.Group as={Col} className="mb-3" controlId="t_r_u">
          <Form.Label>Ответственный</Form.Label>
          <Typeahead
            id="terminal_responsible_user"
            name="terminal_responsible_user"
            labelKey={"value"}
            placeholder="Ответственный"
            maxResults={7}
            emptyLabel="Нет совпадений"
            paginationText="Показать больше"
            highlightClassName="font-weight-bold"
            onChange={(selected) => {
              setResponsible(selected[0]);
            }}
            onInputChange={(text) => {
              setResponsible(text);
            }}
            options={
              item === "Переброска"
                ? defaultResponsibles
                : defaultResponsibles.concat(responsibleData)
            }
            size="sm"
            renderMenuItemChildren={(option, props, idx) => (
              <Highlighter
                search={props.text}
                highlightClassName="fw-bold mx-0 px-0 bg-transparent"
              >
                {option}
              </Highlighter>
            )}
          />
        </Form.Group>
      </Row>
      <Row></Row>
      <Row>
        <Col lg={6}>
          <Form.Group className="mb-3" controlId="t_c">
            <Form.Label>Примечание</Form.Label>
            <Form.Control
              size="sm"
              name="terminal_comment"
              placeholder="Примечание"
              type="text"
              autoComplete="off"
              onChange={handleChange}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-2">
        <Stack gap={2}>
          <Button
            variant="primary"
            className="mb-3 mt-2 mx-auto"
            onClick={handleSubmitWithConfirm}
          >
            Отправить
          </Button>
        </Stack>
      </Row>
      <Modal
        centered
        show={showConfirmModal}
        onHide={() => setShowConfirmModal(false)}
      >
        <Modal.Body>
          <p>{confirmModalText}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowConfirmModal(false)}>
            Нет
          </Button>
          <Button
            variant="danger"
            style={{ minWidth: "12%" }}
            onClick={(e) => {
              handleSubmit(e);
              setShowConfirmModal(false);
            }}
          >
            Да
          </Button>
        </Modal.Footer>
      </Modal>
    </Form>
  );
}

export default ManagementSpbMainTerminal;
