import React, { useState } from 'react';
import "bootstrap/dist/css/bootstrap.css";
import CCPartnersTerminal from './CCPartnersTerminal';
import IncomingItems from '../general/IncomingItems';
import CCPartnersHistory from './CCPartnersHistory';
import {Tabs, Tab} from "react-bootstrap";


function CCPartnersNav() {

  const [state, setState] = useState('terminal');

  return(
    <Tabs
      justify
      id="controlled-tab-example"
      activeKey={state}
      onSelect={(k) => setState(k)}
      //className="mb-3"
      unmountOnExit={true}
    >
      <Tab eventKey="terminal" title="Терминал" unmountOnExit={false}>
        <CCPartnersTerminal />
      </Tab>
      <Tab eventKey="profile" title="Переброски">
        <IncomingItems />
      </Tab>
      <Tab eventKey="contact" title="История">
        <CCPartnersHistory />
      </Tab>
    </Tabs>
  )

}

export default CCPartnersNav
