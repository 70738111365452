import React, {useState, useEffect, useContext} from 'react';
import ReactDOM from 'react-dom';
import { unmountComponentAtNode } from 'react-dom';
import "bootstrap/dist/css/bootstrap.css";
import {Row, Col, Button, Table, Modal, Form, Spinner} from "react-bootstrap";
import axios from 'axios';
import cookies from 'js-cookie';
import NumberFormat from 'react-number-format';
import CurrencyInput from 'react-currency-input-field';
import { setISODay } from 'date-fns';
import { MessageDlg } from "../general/MessageDlg";
import { AccountingContext } from '../AccountingContextManager';

function AccountingExcerptParser(){

  const aContext = useContext(AccountingContext);

  const [isLoading, setIsLoading] = useState(false)
  const [excerptDraft, setExcerptDraft] = useState([]);
  const [modalStatus, setModalStatus] = useState(false);
  const [mdConfirmAllStatus, setMdConfirmAllStatus] = useState(false);

  const [selectedExcerpt, setSelectedExcerpt] = useState([]);
  const [selectedExcerptInitialSum, setSelectedExcerptInitialSum] = useState(0);
  const [selectedExcerptInitialPurpose, setSelectedExcerptInitialPurpose] = useState('');
  const [selectedExcerptInitialSource, setSelectedExcerptInitialSource] = useState('');

  const [selectedExcerptFactSum, setSelectedExcerptFactSum] = useState(0);

  const fileInputRef = React.createRef();

  const loadAccountingHelpers = async () => {
    let res = await axios.get('/redesk/accounting/helpers');
    aContext.setHelpersData(res.data.helpers_data);
  }

  const showErrorModal = (error) =>
  {
    ReactDOM.render(
      <MessageDlg
        title={"Ошибка сервера: " + error.name}
        message={error.message}
        actions={{ onClose: () => unmountComponentAtNode( document.getElementById('message-dlg-container') ) }}
      />, document.getElementById('message-dlg-container')
    );
  }

  const loadExcerptDraft = async () => {
    
    setIsLoading(true)

    let res = await axios.get('/redesk/accounting/excerpt/draft');

    setExcerptDraft(res.data.excerpt_draft);
    setIsLoading(false)

  }

  const uploadExcerpt = async (e) => {

    let formData = new FormData();
    formData.append('excerpt', e.target.files[0]);
    e.target.value=null;
    let res = await axios.post('/redesk/accounting/excerpt/parse', formData, {headers:{'X-CSRF-TOKEN': cookies.get("csrf_access_token")}});

    if (res.data.message){
      alert(res.data.message)
    }else{
      loadExcerptDraft();
    }
    
  }

  const showSelectedExcerpt = (e) => {

    let draftCopy = excerptDraft.map(item => ({...item}))

    let selectedRow = draftCopy.find((element) => {return element.uuid === e.currentTarget.getAttribute('uuid')})
    setSelectedExcerpt([selectedRow]);
    setSelectedExcerptInitialSum(selectedRow.ex_sum);
    setSelectedExcerptInitialPurpose(selectedRow.payment_purpose);
    setSelectedExcerptInitialSource(selectedRow.source);
    setSelectedExcerptFactSum(selectedRow.ex_sum);
    selectedRow.ex_sum = selectedRow.ex_sum;
    setModalStatus(true);
  }

  const splitSelectedExcerpt = () => {
    
    let exListcopy = [...selectedExcerpt]

    let initialRowCopy = {...selectedExcerpt.find((element) => {return isNaN(element.uuid)})};
    let newRow = {...initialRowCopy}

    

    newRow.uuid = (Math.random()).toString();
    newRow.ex_sum = 0;
    newRow.payment_purpose = selectedExcerptInitialPurpose;
    newRow.source = selectedExcerptInitialSource;

    exListcopy.push(newRow)

    setSelectedExcerpt(exListcopy);
    
  };

  const deleteSplittedExcerpt = (e) => {

    let currentSelectedExcerpt = [...selectedExcerpt]
    let newSelectedList = currentSelectedExcerpt.filter(item => item.uuid.toString() !== e.target.id.toString());


    let newSum = newSelectedList.map((x) => parseInt(x.ex_sum)).reduce((a, b) => a+b);

    setSelectedExcerptFactSum(newSum);
    setSelectedExcerpt(newSelectedList);
  }

  const changeSelectedProperty = (e) => {
    let currentSelectedExcerpt = [...selectedExcerpt]
    let index = currentSelectedExcerpt.findIndex(x => x.uuid === e.target.id);

    currentSelectedExcerpt[index][e.target.name] = e.target.value;
    setSelectedExcerpt(currentSelectedExcerpt);
  }

  const countSplittedSum = (e) => {

    let changedSum = (e.target.value === '' || isNaN(e.target.value.replace(',', '.'))) ? 0 :parseFloat(e.target.value.replace(',', '.'))

    let currentSelectedExcerpt = [...selectedExcerpt]
    let index = currentSelectedExcerpt.findIndex(x => x.uuid === e.target.id);
    currentSelectedExcerpt[index].ex_sum = changedSum;
    

    let newSum = currentSelectedExcerpt.map((x) => x.ex_sum).reduce((a, b) => a+b);
    
    let newSumFormatted = parseFloat(newSum.toFixed(2));

    setSelectedExcerptFactSum(newSumFormatted);
    setSelectedExcerpt(currentSelectedExcerpt)
  }

  const confirmSplittedExcerpt = async () => {

    if (selectedExcerptFactSum !== selectedExcerptInitialSum){
      alert('Суммы записей должны совпадать с изначальной суммой!');
      return
    }
    
    let blankList = selectedExcerpt.filter((x) => {return x.ex_sum === 0 || isNaN(x.ex_sum)})
    if (blankList.length > 0){
      alert('Невозможно разделить выписку: сумма некоторых строк равна нулю или заполнена неправильно!')
      return
    }

    await axios.post('/redesk/accounting/excerpt', selectedExcerpt, {headers:{'X-CSRF-TOKEN': cookies.get("csrf_access_token")}});

    setModalStatus(false);
    loadExcerptDraft();

  }

  const btn_confirmExcerpt_click = (e) => 
  {
    console.log( e.currentTarget.attributes.uuid.value );

    let currenExcerpt = excerptDraft.find((element) => {return element.uuid === e.currentTarget.getAttribute('uuid')})

    if( currenExcerpt )
    {
      confirmExcerpt( currenExcerpt );
    }
  }

  const confirmExcerpt = async (excerpt) =>
  {
    await axios.post('/redesk/accounting/excerpt', [excerpt], {headers:{'X-CSRF-TOKEN': cookies.get("csrf_access_token")}})
      .then( (response) =>
      {
        console.log(response);
        loadExcerptDraft();
        loadAccountingHelpers();
      })
      .catch( (error) =>
      {
        if( error.response && error.response.data.description )
        {
          console.log( "E: " + error.response.status + " M: " + error.message + " SRV: " + error.response.data.description )

          let e_details = {
            'data': error.config.data,
            'url': error.config.url,
            'method': error.config.method,
            'response_data': error.response.data
          };

          console.log( e_details );
          console.log( JSON.stringify( e_details ) );

          showErrorModal( { "name": error.response.status, "message": error.message + "\n" + error.response.data.description } );
        }
        else
        {
          console.log( error );
        }

      });
  }

  const btn_confirmAllExcerpt_click = () =>
  {
    excerptDraft.forEach(excerpt => {

      axios.post('/redesk/accounting/excerpt', [excerpt], {headers:{'X-CSRF-TOKEN': cookies.get("csrf_access_token")}})
      .catch( (error) =>
      {
        if( error.response && error.response.data.description )
        {
          console.log( "E: " + error.response.status + " M: " + error.message + " SRV: " + error.response.data.description )

          let e_details = {
            'data': error.config.data,
            'url': error.config.url,
            'method': error.config.method,
            'response_data': error.response.data
          };

          console.log( e_details );
          console.log( JSON.stringify( e_details ) );

          showErrorModal( { "name": error.response.status, "message": error.message + "\n" + error.response.data.description } );
        }
        else
        {
          console.log( error );
        }
      })
    });

    setTimeout(loadExcerptDraft, 2000);
    loadAccountingHelpers();
  }

  useEffect(() => {
    loadExcerptDraft();
    return () => {}
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return(
    <React.Fragment>
      <Row>
        <Col lg={6} className='justify-content-left' style={{marginTop: '5px'}}>
          <input ref={fileInputRef} onChange={uploadExcerpt} className="d-none" type="file" accept='.txt'/>
          <Button onClick={() => {fileInputRef.current?.click();}} variant="primary" size="sm">Загрузить выписку</Button>
        </Col>
        <Col lg={6}>
        {(excerptDraft.length > 0 & isLoading === false) ? (          
          <div style={{textAlign: "right"}}>
            <Button variant="outline-primary" size="sm" className='me-3' onClick={() => {setMdConfirmAllStatus(true)}}> 
              Подтвердить все&nbsp;<img src="/images/sm-w-gatling.gif"></img>
            </Button>
          </div>
        ) : (<></>)}
        </Col>
      </Row>
      <Row>
        {(excerptDraft.length > 0 & isLoading === false) ? (
        <Col className='overflow-auto' style={{height: 'calc(100vh - 345px)'}} lg={12}>
        {excerptDraft.length > 0 ? (
          <>
            <Table striped bordered hover size="sm" style={{marginTop: '5px'}} responsive className="accnt-exc-parser-maintable">
              <thead>
                <tr>
                  <th>Дата</th>
                  <th>Сумма</th>
                  <th>Назначение платежа</th>
                  <th>Счет</th>
                  <th>Действия</th>
                </tr>
              </thead>
              <tbody>
                {
                  excerptDraft.map((item) => (
                    <tr uuid={item.uuid}>
                      <td className="align-middle accnt-exc-parser-date-col">{item.date}</td>
                      <td className="align-middle accnt-exc-parser-sum-col">{window.redesk_app.common.fmtFinValue.format(item.ex_sum)}</td>
                      <td className="align-middle accnt-exc-parser-purp-col">
                        <p class="text-start lh-sm">{item.payment_purpose}</p>
                        <p class="text-end lh-sm accnt-exc-list-subsource"><span>Контрагент: {item.source}</span></p>
                      </td>
                      <td className="align-middle accnt-exc-parser-org-col">{item.organization}</td>
                      <td className="align-middle accnt-exc-parser-actions-col">
                        <Button variant="success" size="sm" key={item.uuid} uuid={item.uuid} onClick={showSelectedExcerpt} className='btn-with-icon'>
                          <span class="material-icons-sm">library_add</span>
                        </Button>
                        &nbsp;&nbsp;
                        <Button variant="primary" size="sm" uuid={item.uuid} onClick={btn_confirmExcerpt_click} className='btn-with-icon'> 
                          <span class="material-icons-sm">check</span>
                        </Button>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>
            
          </>
        ) : <></>}
        </Col>
        ) : (excerptDraft.length < 1 & isLoading === false) ? (
          <Col sm={12} style={{textAlign: "center"}}>
          <h4 style={{marginTop: "15%", marginBottom: "15%"}}>Нет выписок</h4>
          </Col>
        ) : (
          <Col sm={12} style={{textAlign: "center"}}>
          <div style={{marginTop: "15%", marginBottom: "15%"}}>
            <Spinner animation="border" variant="primary"/>
          </div>
        </Col>
        )}
      </Row>

      <Modal show={modalStatus} fullscreen={true} onHide={() => {setModalStatus(false)}}>
      <Modal.Header closeButton>
        <Modal.Title>{selectedExcerptInitialPurpose}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table striped bordered size="sm" style={{textAlign: "center"}} responsive>
          <thead>
          <tr>
            <th>Дата</th>
            <th>Сумма</th>
            <th>Назначение платежа</th>
            <th>Счет</th>
            <th>Назначение платежа</th>
            <th>Источник</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
            {
              selectedExcerpt.map((item) => (
                <tr key={item.uuid} uuid={item.uuid}>
                <td>{item.date}</td>
                <td>
                  <Form.Group style={{margin: '0'}}>
                    <Form.Control
                      id={item.uuid}
                      size="sm"
                      name="ex_sum"
                      placeholder='0'
                      defaultValue={item.ex_sum == 0 ? '' : item.ex_sum}
                      onInput={(e) => {countSplittedSum(e);}}
                    />
                  </Form.Group>
                </td>
                <td>{item.inn}</td>
                <td>{item.organization}</td>
                <td>
                  <Form.Group style={{margin: '0'}}>
                    <Form.Control
                      id={item.uuid}
                      size="sm"
                      name="payment_purpose"
                      defaultValue={item.payment_purpose}
                      onInput={changeSelectedProperty}
                    />
                  </Form.Group>
                </td>
                <td>
                  <Form.Group style={{margin: '0'}}>
                    <Form.Control
                      id={item.uuid}
                      size="sm"
                      name="source"
                      defaultValue={item.source}
                      onInput={changeSelectedProperty}
                    />
                  </Form.Group>
                </td>
                <td>
                {isNaN(item.uuid) ? (
                  <></>
                ) : (
                  <Button variant="danger" id={item.uuid}
                  style={{maxHeight: '30px', paddingTop: '0', paddingBottom: '0'}}
                  className="align-items-center"
                  onClick={deleteSplittedExcerpt}>
                    -
                  </Button>
                )}
                </td>
                </tr>
              ))
            }
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Row style={{minWidth: '100%', textAlign: 'center', margin: '0'}}>
          <Col lg={4} style={{textAlign: 'left', color: selectedExcerptFactSum === selectedExcerptInitialSum ? 'green' : 'red'}}>
            <p style={{margin: '0', paddingTop: '8px'}}>Общая сумма:
              <strong>
                <NumberFormat
                  style={{marginLeft: '5px'}}
                  value={selectedExcerptInitialSum}
                  displayType={'text'}
                  thousandSeparator={' '}
                  decimalSeparator={'.'}
                  suffix={' ₽'}
                  />
              </strong>
            </p>
          </Col>
          <Col lg={4}>
            <Button variant="success" onClick={splitSelectedExcerpt}>
              +
            </Button>
          </Col>
          <Col xs={4} style={{textAlign: 'end'}}>
            <Button variant="primary" onClick={confirmSplittedExcerpt}>
              Подтвердить
            </Button>
          </Col>
        </Row>

      </Modal.Footer>
      </Modal>

      <Modal centered show={mdConfirmAllStatus} fullscreen={false} onHide={() => {setMdConfirmAllStatus(false)}}>
        <Modal.Header closeButton>
        </Modal.Header>

        <Modal.Body>
          <span class="material-icons" style={{verticalAlign: "middle", color: "orangered"}}>help_outline</span>
          <span style={{verticalAlign: "middle"}}>Действительно хотите подтвердить все выписки?</span>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" size="sm" onClick={() => {btn_confirmAllExcerpt_click(); setMdConfirmAllStatus(false)} }>
            Да, Подтвердить
          </Button>
          <Button variant="secondary" size="sm" onClick={() => {setMdConfirmAllStatus(false)}}> 
            Отмена
          </Button>
        </Modal.Footer>
      </Modal>

    </React.Fragment>
  );
}


export default AccountingExcerptParser;
