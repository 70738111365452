import React, {useState, useEffect, useContext} from 'react';
import "bootstrap/dist/css/bootstrap.css";
import {Row, Col, Button, Stack, Form, Modal} from "react-bootstrap";
import { UserContext } from '../UserContextManager';
import axios from 'axios';
import cookies from 'js-cookie';
import CurrencyInput from 'react-currency-input-field';
import { useNavigate } from "react-router-dom";
import { Typeahead, Highlighter } from 'react-bootstrap-typeahead';

function DKWatch(){

  const navigate = useNavigate();

  const uContext = useContext(UserContext);

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmModalText, setConfirmModalText] = useState('');

  const [dropdownData, setDropdownData] = useState([]);
  const [responsibleData, setResponsibleData] = useState([]);
  const [sourceData, setSourceData] = useState([]);
  const [formData, updateFormData] = useState({});

  const [paymentType, setPaymentType] = useState('Нал');
  const [item, setItem] = useState('Статья');
  const [itemType, setItemType] = useState('Приход');
  const [metric1, setMetric1] = useState('Вид работ');
  const [source, setSource] = useState('')
  const [sum, setSum] = useState('');
  const [responsibleUser, setResponsibleUser] = useState('')


  const loadTerminalDropdown = async () => {

    try{
      let dropdownsRes = await axios.get('/redesk/terminal/main-dropdowns', {params:{desk_type:'dk'}})

      setDropdownData(dropdownsRes.data.dropdowns_data);

      let responsibles = []
      dropdownsRes.data.dropdowns_data.forEach(function (x) {
        if(x.dropdown_name === 'dk_responsible_user'){
          responsibles.push(x.value)
        }
      })

      let companies = []
      dropdownsRes.data.dropdowns_data.forEach(function (x) {
        if(x.dropdown_name === 'dk_company_name'){
          companies.push(x.value)
        }
      })
      
      setSourceData(companies);
      setResponsibleData(responsibles);

    } catch (error){
      navigate('/');
    }
  }

  const handleChange = (e, k=null) => {
    if (k !== null){
      updateFormData({...formData, [k]: e});
    }else{
      updateFormData({...formData, [e.target.name]: e.target.value});
    }
  }

  const compareDraft = () => {

    let draftCopy = [...uContext.userData.draft_data]
    
    let has_duplicate = false
    draftCopy.forEach((x) => {
      if(
        Math.abs(x.sum) === Number(sum)
        && x.responsible_user === responsibleUser
        && x.item === item
        && x.payment_type === paymentType
        && x.work_type === metric1
        && x.company_name === source
        ){
        has_duplicate = true
      }
    })

    return has_duplicate
  }

  const handleSubmitWithConfirm = (e) => {

    if (itemType === 'Тип статьи'){
      alert('Укажите тип статьи!')
      return
    }

    if (item === 'Статья'){
      alert('Укажите статью!')
      return
    }

    if (metric1 === 'Вид работ'){
      alert('Укажите вид работ!')
      return
    }

    if (sum === ''){
      alert('Введите сумму!')
      return
    }

    if (!sourceData.includes(source)){
      alert('Источник указан неправильно')
      return
    }


    if (!responsibleData.includes(responsibleUser)){
      alert('Ответственный указан неправильно')
      return
    }


    if (item === 'Переброска' && paymentType === 'Безнал'){
      alert('Нельзя делать переброску с таким типом платежа!')
      return
    }

    if (compareDraft()){
      setConfirmModalText('Похожая запись уже есть в черновике. Создать еще одну?')
      setShowConfirmModal(true);
      return
    }

    handleSubmit(e)
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    let draftSendData = {...formData}
    draftSendData['cfo'] = 'ДК'
    draftSendData['terminal_item_type'] = itemType
    draftSendData['terminal_item'] = item
    draftSendData['terminal_payment_type'] = paymentType
    draftSendData['terminal_company_name'] = source
    draftSendData['terminal_sum'] = sum
    draftSendData['terminal_work_type'] = metric1
    draftSendData['terminal_responsible_user'] = responsibleUser
    draftSendData['desk_owner'] = 'ДК'

    //responsibleRef.current.clear();

    let res = await axios.post('/redesk/draft/send', draftSendData, {headers:{'X-CSRF-TOKEN': cookies.get("csrf_access_token")}});

    let contextData = uContext.userData;
    contextData.draft_data = res.data.draft_data;
    uContext.setUserData(contextData);

    /*document.getElementById("terminal_main_form").reset();
    setItemType('Тип статьи');
    updateFormData({});
    setItem('Статья');
    setPaymentType('Тип платежа');
    setSum('');
    setCompanyName('Юрлицо');
    setObjectId('');*/
  }


  useEffect(() => {
    loadTerminalDropdown();
    return () => {}
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return(
    <React.Fragment>
    <Form id='terminal_main_form' className='mt-2 p-2'>
      <Row>
      <Col lg={4}>
        <Form.Group className="mb-3" controlId="t_i_t" >
          <Form.Label>Тип статьи</Form.Label>
          <Form.Select size="sm" name="terminal_item_type" value={itemType} aria-label="terminal_item_type" onChange={(e) => {setItemType(e.target.value); setItem('Статья')}}>
            <option key="Приход">Приход</option>
            <option key="Расход">Расход</option>
          </Form.Select>
        </Form.Group>
      </Col>
      <Col lg={4}>
        <Form.Group className="mb-3" controlId="t_i">
          <Form.Label>Статья</Form.Label>
          <Form.Select size="sm" name="terminal_item" value={item} aria-label="terminal_item" onChange={(e) => setItem(e.target.value)}>
            <option key="Статья" disabled>Статья</option>
            {dropdownData.map(({value, label, dropdown_name, spec}, index) => {if(dropdown_name === 'dk_item' && spec === itemType){return <option key={value} value={value}>{label}</option>}return null})}
          </Form.Select>
        </Form.Group>
      </Col>
      <Col lg={4}>
        <Form.Group className="mb-3" controlId="t_m_1">
          <Form.Label>Вид работ</Form.Label>
          <Form.Select size="sm" name="terminal_work_type" value={metric1} aria-label="terminal_work_type" onChange={(e) => setMetric1(e.target.value)}>
            <option key="Статья" disabled>Вид работ</option>
            {dropdownData.map(({value, label, dropdown_name, spec}, index) => {if(dropdown_name === 'dk_metric_1'){return <option key={value} value={value}>{label}</option>}return null})}
          </Form.Select>
        </Form.Group>
      </Col>
      </Row>
      <Row>
      <Col xs={4}>
      <Form.Group className="mb-3" controlId="t_s">
          <Form.Label>Сумма</Form.Label>
          <CurrencyInput
            autoComplete='off'
            id="terminal_sum"
            name="terminal_sum"
            className='form-control'
            style={{maxHeight: '30px'}}
            value={sum}
            placeholder="Сумма"
            decimalsLimit={2}
            onValueChange={(e) => {setSum(e.replace(',','.'))}}
            allowNegativeValue={false}
            groupSeparator={' '}
          />
      </Form.Group>
      </Col>
      <Col xs={4}>
        <Form.Group className="mb-3" controlId="t_o_id">
            <Form.Label>Источник</Form.Label>
            <Typeahead
              id='terminal_company_name'
              name='terminal_company_name'
              placeholder='Источник'
              maxResults={7}
              emptyLabel='Нет совпадений'
              paginationText='Показать больше'
              highlightClassName='font-weight-bold'
              options={sourceData}
              onInputChange={(text) => {setSource(text)}}
              onChange={(selected) => {setSource(selected[0])}}
              size='sm'
              renderMenuItemChildren={(option, props, idx) => (
                <Highlighter search={props.text} highlightClassName='fw-bold mx-0 px-0 bg-transparent'>
                  {option}
                </Highlighter>
              )}
             />
          </Form.Group>
      </Col>
      <Col xs={4}>
      <Form.Group className="mb-3" controlId="t_p_t">
        <Form.Label>Тип платежа</Form.Label>
        <Form.Select size="sm" name="terminal_payment_type" aria-label="terminal_payment_type" value={paymentType} onChange={(e) => setPaymentType(e.target.value)}>
          <option key="Нал" >Нал</option>
          <option key="Безнал">Безнал</option>
        </Form.Select>
      </Form.Group>
      </Col>
      </Row>
      <Row>
      <Col xs={4}>
      <Form.Group className="mb-3" controlId="t_r_u">
          <Form.Label>Ответственный</Form.Label>
          <Typeahead
            id='terminal_responsible_user'
            name='terminal_responsible_user'
            placeholder='Ответственный'
            maxResults={7}
            emptyLabel='Нет совпадений'
            paginationText='Показать больше'
            highlightClassName='font-weight-bold'
            options={responsibleData}
            onInputChange={(text) => {setResponsibleUser(text)}}
            onChange={(selected) => {setResponsibleUser(selected[0])}}
            size='sm'
            renderMenuItemChildren={(option, props, idx) => (
              <Highlighter search={props.text} highlightClassName='fw-bold mx-0 px-0 bg-transparent'>
                {option}
              </Highlighter>
            )}
            />
        </Form.Group>
      </Col>
      <Col xs={4}>
        <Form.Group className="mb-3" controlId="t_c">
          <Form.Label>Примечание</Form.Label>
          <Form.Control size="sm" name="terminal_comment" placeholder="Примечание" autoComplete="off" type="text" onChange={handleChange} />
        </Form.Group>
      </Col>
      <Col xs={4}></Col>
      </Row>
      <Row className="mt-2">
      <Col lg={12} style={{ textAlign: "center" }}>
          <Button variant="primary" className='mb-3' size='sm' onClick={handleSubmitWithConfirm}>Отправить</Button>
      </Col>
      </Row>  
    </Form>
    <Modal centered show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
      <Modal.Body>
        <p>{confirmModalText}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick = {() => setShowConfirmModal(false)}>
          Нет
        </Button>
        <Button variant="danger" style={{minWidth: "12%"}} onClick={(e) => {handleSubmit(e); setShowConfirmModal(false)}}>
          Да
        </Button>
      </Modal.Footer>
    </Modal>
    </React.Fragment>
  )
}


export default DKWatch;
