import React, { useState } from 'react'

export const SupplySpbContext = React.createContext({
  helpersData: {
    remainder: 0,
    outcoming: 0,
    incoming: 0
  },
  setHelpersData: () => {},
})

export const SupplySpbContextProvider = (props) => {
  const setHelpersData = (helpersData) => {
    setState({...state, helpersData: helpersData})
  }

  const initState = {
    helpersData: {
        remainder: 0,
        outcoming: 0,
        incoming: 0
    },
    setHelpersData: setHelpersData
  }

  const [state, setState] = useState(initState)

  return (
    <SupplySpbContext.Provider value={state}>
      {props.children}
    </SupplySpbContext.Provider>
  )
}