import React, {useEffect, useState} from 'react';
import "bootstrap/dist/css/bootstrap.css";
import Draft from '../general/Draft';
import {Container, Row, Col, Tabs, Tab} from "react-bootstrap";
import AccountingMainTerminal from './AccountingMainTerminal'
import FotStatement from '../general/FotStatement';
import AccountingReasTerminal from './AccountingReasTerminal'

function AccountingTerminal(){

  const [tabState, setTabState] = useState('terminal-main')

  useEffect(() => {
    return () => {}
  }, [])


  return(
    <Container fluid className="pt-2">
      <Row>
        <Col lg={2}></Col>
        <Col lg={8}>
            <Tabs
            justify
            id="controlled-inner-tab"
            activeKey={tabState}
            onSelect={(k) => setTabState(k)}
            unmountOnExit={false}
            style={{maxWidth: '450px'}}
            >   
                <Tab eventKey="terminal-main" title="Основной">
                    <AccountingMainTerminal />
                </Tab>
                <Tab eventKey="terminal-statement" title="Со счета на счет">
                    <AccountingReasTerminal />
                </Tab>
            </Tabs>
        </Col>
        <Col lg={2}></Col>
      </Row>

      <Row>
        <Col lg={12}>
          <Draft />
        </Col>
      </Row>
    </Container>
  )

}

export default AccountingTerminal;
