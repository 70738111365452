import React, {useState, useEffect, useContext} from 'react';
import "bootstrap/dist/css/bootstrap.css";
import {Row, Col, Button, Table, Form, Container, Spinner} from "react-bootstrap";
import { UserContext } from '../UserContextManager';
import { AccountingContext } from '../AccountingContextManager';
import axios from 'axios';
import cookies from 'js-cookie';
import { CalcByClick } from '../general/CalcByClick';
import MaskedFormControl from 'react-bootstrap-maskedinput'

function AccountingIncoming(){

  const uContext = useContext(UserContext);
  const aContext = useContext(AccountingContext);

  const [isLoading, setIsLoading] = useState(false);

  const [iiData, setIiData] = useState([]);

  const [itemsTotal, setItemsTotal] = useState(0);

  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const [accountingDropDowns, setAccountingDropDowns] = useState([]);

  const [modalIi, setModalIi] = useState({});
  const [show, setShow] = useState(false);
  const [fIModal, setFIModal] = useState(false);

  const [CalcByClick_value, setCalcByClick_value ] = useState(0);

  const inst_CalcByClick = new CalcByClick(setCalcByClick_value , 'table.rd-accounting td' );

  const loadAccountingHelpers = async () => {
    let res = await axios.get('/redesk/accounting/helpers');
    aContext.setHelpersData(res.data.helpers_data);
  }

  const initExcerptSummarizer = () =>
  {
    if( typeof( window.redesk_app.accounting.in_excerpts ) )
    {
      if( ! window.redesk_app.accounting.in_excerpts.hasOwnProperty('is_inited') )
      {
        window.redesk_app.accounting.in_excerpts = { is_inited: true,  selected_summs: [], selected_items: [], summ: 0 }
      }
      else
      {
        window.redesk_app.accounting.in_excerpts.selected_summs = [];
        window.redesk_app.accounting.in_excerpts.selected_items = [];
        window.redesk_app.accounting.in_excerpts.summ = 0;
      }
    }
  }

  const initExcerptSummarizer_loaded = () =>
  {
    let items = document.querySelectorAll('div.accnt-incoming-maincontainer input[type=checkbox]');
    
    if (items.length < 1) {
      return
    }

    for( let i = 0; i < items.length; i++){ 
    
      if( items[i].checked )
      { 
        processExcerptCheckbox( items[i].id, items[i].dataset.sum, true );
      }
  
    }
    recalcSelectedSumms();
  }

  const recalcSelectedSumms = () =>
  {
    window.redesk_app.accounting.in_excerpts.selected_summs = [];
    window.redesk_app.accounting.in_excerpts.summ = 0;

    for( let i = 0; i < window.redesk_app.accounting.in_excerpts.selected_items.length; i++)
    { 
      let current_sum = parseFloat(window.redesk_app.accounting.in_excerpts.selected_items[i].sum);
      window.redesk_app.accounting.in_excerpts.selected_summs.push( current_sum );

      window.redesk_app.accounting.in_excerpts.summ = window.redesk_app.accounting.in_excerpts.summ + current_sum;
    }
    
    document.getElementById('accnt-incoming-excerpts-selected-sum').innerHTML = new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format( window.redesk_app.accounting.in_excerpts.summ );
  }

  const processExcerptCheckbox = (uuid, sum, checked) =>
  {  
    if( checked )
    {
      window.redesk_app.accounting.in_excerpts.selected_items.push( {'uuid': uuid, 'sum': sum } );
    }
    else
    {
      for( let i = 0; i < window.redesk_app.accounting.in_excerpts.selected_items.length; i++){ 
    
        if ( window.redesk_app.accounting.in_excerpts.selected_items[i].uuid == uuid)
        { 
          window.redesk_app.accounting.in_excerpts.selected_items.splice(i, 1); 
        }
      }
    }
  }

  const loadIiData = async (currentPage) => {

    let paginationParams = {
      'page': currentPage,
      'per_page': 2000,
    }

    setIsLoading(true);

    let res = await axios.get('/redesk/accounting/incoming', {params:paginationParams});

    setPageCount(Math.ceil(res.data.total/15))

    setIiData(res.data.incoming_items);
    setItemsTotal(res.data.total);
    setIsLoading(false);
    initExcerptSummarizer_loaded();
  }

  const handlePageClick = async (data) => {

    setCurrentPage(data.selected);

    loadIiData(data.selected);
  

  };

  const loadUserData = async () => {

    let userDataRes = await axios.get('/redesk/general/remainder');

    let data = await userDataRes.data;

    let draftRes = await axios.get('/redesk/draft');

    let draftData = await draftRes.data;

    data.user_data['draft_data'] = draftData.draft_data;

    uContext.setUserData(data.user_data);

  }


  const getAccountingDropdowns = async () => {

    try{
      let res = await axios.get('/redesk/accounting/dropdowns');
      setAccountingDropDowns(res.data.accounting_dropdowns);
    }catch{
      alert('Не удалось загрузить списки счетов')
    }
  }


  const handleShow = (e) => {

    let selectedRow = iiData.find((element) => {return element.uuid === e.currentTarget.getAttribute('row-uuid')})

    setModalIi(selectedRow);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  }

  const handleTableClose = () => {
    setFIModal(false);
  }

  const showWholeTable = () => {
    setFIModal(true)
  }

  const confirmModalRow = async (uuid) => {

    let iiCopy = [...iiData]
    let si = iiCopy.findIndex(x => x.uuid === uuid)

    if(!iiCopy[si].account){
      return
    }

    await axios.patch(
      '/redesk/accounting/incoming/confirm',
      [{
        uuid: uuid,
        account: iiCopy[si].account,
      },],
      {headers:{'X-CSRF-TOKEN': cookies.get("csrf_access_token")}}
    );

    handleClose();
    loadIiData();
    loadUserData();
    loadAccountingHelpers();
  }

  const dismissModalRow = async (uuid) => {
    
    await axios.patch('/redesk/accounting/incoming/dismiss', [{uuid: uuid}], {headers:{'X-CSRF-TOKEN': cookies.get("csrf_access_token")}});

    handleClose();
    loadIiData();
    loadUserData();
    loadAccountingHelpers();
  }

  const handleTableConfirm = async () => {

    let iiCopy = [...iiData]
    let confirmBatch = []
    
    iiCopy.forEach(function(x){
      if (x.checked && x.account){
        
        if (x.fact_date !== null){

          let dateParts = x.fact_date.split('/')
          let rowDate = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]); 
        
          if (rowDate > new Date('2023-01-01') && rowDate < new Date('2030-01-01')){
            confirmBatch.push({uuid: x.uuid, account: x.account})
          }else{
            alert('Дата в одной из строк заполнена неправильно!')
          }

        }else{
          confirmBatch.push({uuid: x.uuid, account: x.account})
        }
        
      }
    })

    if (confirmBatch.length < 1){
      return null
    }

    await axios.patch('/redesk/accounting/incoming/confirm', confirmBatch, {headers:{'X-CSRF-TOKEN': cookies.get("csrf_access_token")}});

    loadIiData();
    loadUserData();
    loadAccountingHelpers();

  }

  const handleTableDismiss = async () => {

    let iiCopy = [...iiData]
    let dismissBatch = []

    iiCopy.forEach(function(x){
      if (x.checked){
        dismissBatch.push({uuid: x.uuid})
      }
    })

    if (dismissBatch.length < 1){
      return null
    }

    await axios.patch('/redesk/accounting/incoming/dismiss', dismissBatch, {headers:{'X-CSRF-TOKEN': cookies.get("csrf_access_token")}});

    loadIiData();
    loadUserData();
    loadAccountingHelpers();
  }

  const handleCheckBox = async (uuid, isChecked, event) => {

    let iiCopy = [...iiData]
    let si = iiCopy.findIndex(x => x.uuid === uuid)

    iiCopy[si].checked = isChecked

    setIiData(iiCopy)
    
    processExcerptCheckbox(uuid, event.target.dataset.sum, isChecked);
    recalcSelectedSumms();

    await axios.patch('/redesk/accounting/incoming/save-checked', {uuid: uuid, checked: isChecked}, {headers:{'X-CSRF-TOKEN': cookies.get("csrf_access_token")}});
  }

  const handleAccount = async (uuid, account) => {

    let iiCopy = [...iiData]
    let si = iiCopy.findIndex(x => x.uuid === uuid)

    let newAccount = account === 'Счет' ? null : account

    iiCopy[si].account = newAccount
    setIiData(iiCopy)

    await axios.patch('/redesk/accounting/incoming/save-account', {uuid: uuid, account: newAccount ? newAccount : false}, {headers:{'X-CSRF-TOKEN': cookies.get("csrf_access_token")}});
  }

  const handleFactDate = async (uuid, dateStr) => {
    let iiCopy = [...iiData]
    let si = iiCopy.findIndex(x => x.uuid === uuid)

    iiCopy[si].fact_date = dateStr
    setIiData(iiCopy)

    await axios.patch('/redesk/accounting/incoming/save-date', {uuid: uuid, fact_date: dateStr ? dateStr : false}, {headers:{'X-CSRF-TOKEN': cookies.get("csrf_access_token")}});
  }

  useEffect(() => {
    loadIiData();
    getAccountingDropdowns();
    loadAccountingHelpers();
    initExcerptSummarizer();
    return () => {}
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  return(
    <Container fluid className="shadow pt-2">
      {(iiData.length > 0 && isLoading === false) ? (
      <>
      <Row className="overflow-auto accnt-incoming-maincontainer">
      <Table striped bordered size="sm" className='rd-accounting' responsive>
              <thead>
                <tr>
                  <th>Дата</th>
                  <th>От кого</th>
                  <th>ЦФО</th>
                  <th>Сумма</th>
                  <th>Статья</th>
                  <th>Подстатья</th>
                  <th>Вид работ</th>
                  <th>Ид</th>
                  <th>Метрика 4</th>
                  <th>Ответственный</th>
                  <th>Счет</th>
                  <th>Факт. дата</th>
                  <th>Выбор записи</th>
                </tr>
              </thead>
              <tbody>
                {
                  iiData.map((item) => (

                    <tr key={item.uuid} row-uuid={item.uuid} style={item.item_type === 'Приход' ? {backgroundColor: '#b1f7ad'} : {backgroundColor: '#ff9494'}}>
                      <td>{item.creation_date}</td>
                      <td>{item.desk_owner}</td>
                      <td>{item.cfo}</td>
                      <td className='col-value' data-calcbyclickvalue={item.sum} onClick={inst_CalcByClick.onItemClick}>{window.redesk_app.common.fmtFinValue.format(item.sum)}</td>
                      <td>{item.item}</td>
                      <td>{item.sub_item}</td>
                      <td>{item.metric_1}</td>
                      <td>{item.object_id}</td>
                      <td>{item.metric_4}</td>
                      <td>{item.responsible_user}</td>
                      <td>
                        <Form.Group controlId="a_a_t">
                          <Form.Select size="sm" name="account" value={item.account ? item.account : 'Счет'} id={item.uuid} aria-label="terminal_metric_5" onChange={(e) => {handleAccount(item.uuid, e.target.value)}}>
                            <option key="Счет">Счет</option>
                            {accountingDropDowns.map(({value, label, dropdown_name}, index) => {if(dropdown_name === 'accounting_account'){return <option key={value} value={label}>{label}</option>}return null})}
                          </Form.Select>
                        </Form.Group>
                      </td>
                      <td>
                      <Form.Group>
                        <MaskedFormControl type='text' name='fact_date' className='m-0' style={{maxHeight: '25px'}} placeholder='Дата' value={item.fact_date} mask='11/11/1111' onChange={(e) => {handleFactDate(item.uuid, e.target.value)}}/>
                      </Form.Group>
                      </td>
                      <td className='col-centred'>
                      <Form.Check
                        type='checkbox'
                        key={item.uuid}
                        id={item.uuid}
                        data-sum={item.sum}
                        checked={item.checked}
                        onChange={(e) => {handleCheckBox(item.uuid, e.target.checked, e)}}
                      />
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>
      </Row>
      <Row>
        <Col lg={2} className='align-self-center'>
          Отмечены: <span id="accnt-incoming-excerpts-selected-sum"> -- </span>
        </Col>
        <Col lg={2} className='align-self-center'>
          Выбрано: <span onClick={inst_CalcByClick.onResultClick}>{window.redesk_app.common.fmtFinValue.format(CalcByClick_value)}</span>
        </Col>
        <Col lg={1} className='align-self-center'>
          
        </Col>
        <Col lg={7} style={{margin: 0, padding: 0, marginBottom: '5px', paddingRight: '5px'}} className='d-flex justify-content-end align-self-center mt-1'>
          <Button variant="danger" size="sm" style={{maxHeight: '40px', marginRight: '10px'}} onClick={handleTableDismiss}>Отменить</Button>
          <Button variant="primary" size="sm" style={{maxHeight: '40px'}} onClick={handleTableConfirm}>Подтвердить</Button>
        </Col>
      </Row>
      </>
      ) : (iiData.length < 1 && isLoading === false) ? (
        <Row>
        <Col sm={12} style={{textAlign: "center"}}>
          <h4 style={{marginTop: "15%", marginBottom: "15%"}}>Нет входящих</h4>
        </Col>
        </Row>
        ) : (
          <Row>
          <Col sm={12} style={{textAlign: "center"}}>
            <div style={{marginTop: "15%", marginBottom: "15%"}}>
              <Spinner animation="border" variant="primary"/>
            </div>
          </Col>
          </Row>
        )}

    </Container>
  )
}

export default AccountingIncoming;
