import { useEffect, useRef } from "react";
import { Modal, Button } from "react-bootstrap";

export const MessageDlg = ({ title, message, actions }) => {
  const dialogRef = useRef();

  useEffect(() => {
    dialogRef.current?.showModal?.();
    return () => dialogRef.current?.close?.();
  }, []);

  let dlgButtons;

  if (actions.onClose) {
    dlgButtons = (
      <Button variant="secondary" onClick={actions.onClose}>
        Закрыть
      </Button>
    );
  }

  return (
    <div
      className="modal hide"
      style={{ display: "block", position: "absolute" }}
    >
      <Modal.Dialog ref={dialogRef} centered="true">
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>{message}</p>
        </Modal.Body>

        <Modal.Footer>{dlgButtons}</Modal.Footer>
      </Modal.Dialog>
    </div>
  );
};
