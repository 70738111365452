import React, {useEffect} from 'react';
import "bootstrap/dist/css/bootstrap.css";
import Draft from '../general/Draft';
import {Container, Row, Col} from "react-bootstrap";
import SupplySpbMainTerminal from './SupplySpbMainTerminal';

function SupplySpbTerminal(){

  useEffect(() => {
    return () => {}
  }, [])


  return(
    <Container fluid className="shadow pt-2">
      <Row>
        <Col xs={12}>
          <SupplySpbMainTerminal />
        </Col>
        <Col xs={12}>
          <Draft />
        </Col>
      </Row>
    </Container>
  )

}

export default SupplySpbTerminal;
