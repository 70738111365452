import React from 'react'
import {useState, useRef} from 'react';
import {Button, Form, Row, Col, Tooltip, Overlay} from "react-bootstrap";
import sha256 from 'js-sha256'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function LoginSignUp(){

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showTip, setShowTip] = useState(false);
  const tipTarget = useRef(null);

  const navigate = useNavigate();

  const signUp = (res) => {
    if (email.length < 1){
      alert('Введите почту')
    }
    else if (password.length < 6 || confirmPassword.length < 6){
      alert('Пароль должен состоять как минимум из 6-ти символов!')
    } else if (password !== confirmPassword){
      alert('Пароли не совпадают')
    } else {
      axios.post('/redesk/profile/sign-up', {email: email, pass_hash: sha256(password)})
      .then(res => {
        navigate('/');
      }).catch(function(error){
        if (error.response) {
          alert(error.response.data.error_message);
        }
      })
    };
  }

  const handleTip = () => {
    let not_first_time = localStorage.getItem('not_first_time')
    if (not_first_time == null){
      setShowTip(true)
    }
  }

  return(
    <>
      <Form id='signUp_form'>
        <Row>
          <Form.Group as={Col} xs='12' className="mb-3" controlId="su_e">
            <Form.Label>Email</Form.Label>
            <Form.Control size="sm" ref={tipTarget} defaultValue='' name="signup_email" placeholder="Email"
              onFocus={handleTip}
              onBlur={() => {localStorage.setItem('not_first_time', true); setShowTip(false)}}
              onInput={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
          <Overlay target={tipTarget} show={showTip} placement="top">
            {(props) => (
              <Tooltip id="tip-overlay" {...props}>
                Используйте рабочую почту, указанную в 1С
              </Tooltip>
            )}
          </Overlay>
          <Form.Group as={Col} xs='12' className="mb-3" controlId="su_p">
            <Form.Label>Password</Form.Label>
            <Form.Control size="sm" defaultValue='' name="signup_password" placeholder="Password" type='password' onInput={(e) => setPassword(e.target.value)}/>
          </Form.Group>
          <Form.Group as={Col} xs='12' className="mb-3" controlId="su_c_p">
            <Form.Label>Confirm password</Form.Label>
            <Form.Control size="sm" defaultValue='' name="signup_confitm_password" placeholder="Confirm password" type='password' onInput={(e) => setConfirmPassword(e.target.value)}/>
          </Form.Group>
        </Row>
      </Form>
      <Col style={{textAlign: "center"}}>
        <Button variant="primary" onClick={signUp}>Sign Up</Button>
      </Col>
    </>
  )
}

export default LoginSignUp;
