import React, { useState } from 'react'

export const UserContext = React.createContext({
  userData: {
    'nu_main_remainder': 0,
    'nu_outcoming_reas_remainder': 0,
    'nu_incoming_reas_remainder': 0,
    'role': 'guest',
    'abbreviation': '',
    'draft_data': []
  },
  setUserData: () => {},
})

export const UserContextProvider = (props) => {

  const setUserData = (userData) => {
    setState({...state, userData: userData})
  }


  const initState = {
    userData: {
      'nu_main_remainder': 0,
      'nu_outcoming_reas_remainder': 0,
      'nu_incoming_reas_remainder': 0,
      'role': 'guest',
      'abbreviation': '',
      'draft_data': []
    },
    setUserData: setUserData
  }

  const [state, setState] = useState(initState)

  return (
    <UserContext.Provider value={state}>
      {props.children}
    </UserContext.Provider>
  )
}
