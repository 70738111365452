import React, {useState, useEffect, useContext} from 'react';
import "bootstrap/dist/css/bootstrap.css";
import {Row, Col, Button, Stack, Form} from "react-bootstrap";
import { UserContext } from '../UserContextManager';
import { AccountingContext } from '../AccountingContextManager';
import axios from 'axios';
import cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";
import CurrencyInput from 'react-currency-input-field';

function  ManagementAccountTerminal(){

  const navigate = useNavigate();

  const uContext = useContext(UserContext);

  const [dropdownData, setDropdownData] = useState([]);

  const [formData, updateFormData] = useState({});

  const [sum, setSum] = useState('');


  const loadTerminalDropdown = async () => {
    try{
      let dropdownsRes = await axios.get('/redesk/management/account-switch-dropdowns');
      setDropdownData(dropdownsRes.data.dropdowns_data);
    } catch (error){
      navigate('/');
    }
  }

  const handleChange = (e, k=null) => {

    let newFormData = {}

    if (k !== null){
      newFormData = {...formData, [k]: e}
    }else{
      newFormData = {...formData, [e.target.name]: e.target.value}
    }

    updateFormData(newFormData);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.terminal_sum){
      alert('Введите сумму!')
      return
    }

    if (!formData.terminal_metric_5){
      alert('Укажите счет списания!')
      return
    }

    if (!formData.destination_account){
        alert('Укажите счет зачисления!')
        return
      }
    
    if (formData.terminal_metric_5 === formData.destination_account){
        alert('Счета списания и зачисления не должны совпадать!')
        return
    }

    let draftSendData = {...formData}
    draftSendData['cfo'] = 'Дирекция'
    draftSendData['desk_owner'] = uContext.userData.abbreviation
    

    let res = await axios.post('/redesk/draft/send/management-account-switch', draftSendData, {headers:{'X-CSRF-TOKEN': cookies.get("csrf_access_token")}});

    let contextData = uContext.userData;
    contextData.draft_data = res.data.draft_data;
    uContext.setUserData(contextData);

  }

  useEffect(() => {
    loadTerminalDropdown();
    return () => {}
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
 // eslint-disable-line react-hooks/exhaustive-deps

  return(
    <Form id='terminal_main_form' className='p-2 mt-2'>
      <Row>
        <Col lg={3}>
          <Form.Group as={Col} controlId="t_s">
            <Form.Label>Сумма</Form.Label>
              <CurrencyInput
                id="terminal_sum"
                name="terminal_sum"
                className='form-control'
                style={{maxHeight: '30px'}}
                value={sum}
                placeholder="0,00"
                decimalsLimit={2}
                allowNegativeValue={false}
                groupSeparator={' '}
                onValueChange={(e) => {setSum(e); handleChange(e, 'terminal_sum')}}
              />
          </Form.Group>
        </Col>

        <Col lg={3}>
          <Form.Group as={Col} controlId="t_b_a">
            <Form.Label>Счет списания</Form.Label>
            <Form.Select size="sm" name="terminal_metric_5" defaultValue='Счет' aria-label="terminal_metric_5" onChange={handleChange}>
              <option key="Счет" disabled>Счет</option>
              {dropdownData.map(({value, label, dropdown_name}, index) => {if(dropdown_name === 'account-switch'){return <option key={value} value={label}>{label}</option>}return null})}
            </Form.Select>
          </Form.Group>
        </Col>

        <Col lg={3}>
          <Form.Group as={Col} controlId="t_b_a">
            <Form.Label>Счет зачисления</Form.Label>
            <Form.Select size="sm" name="destination_account" defaultValue='Счет' aria-label="destination_account" onChange={handleChange}>
              <option key="Счет" disabled>Счет</option>
              {dropdownData.map(({value, label, dropdown_name}, index) => {if(dropdown_name === 'account-switch'){return <option key={value} value={label}>{label}</option>}return null})}
            </Form.Select>
          </Form.Group>
        </Col>

        <Col lg={3}>
          <Form.Group as={Col} controlId="t_c">
            <Form.Label>Примечание</Form.Label>
              <Form.Control size="sm" name="terminal_comment" placeholder="Примечание" type="text" onChange={handleChange} />
          </Form.Group>
        </Col>

      </Row>

      <Row className="mt-2">
        
        <Col lg={12} style={{ textAlign: "center" }}>
          <Button variant="primary" size="sm" className='mt-2 mx-auto' onClick={handleSubmit}>Отправить</Button>
        </Col>
      </Row>
    </Form>
  )
}


export default ManagementAccountTerminal;
