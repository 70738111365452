import React, { useState } from "react";

export const DesContext = React.createContext({
  helpersData: {
    remainder: 0,
    outcoming: 0,
    incoming: 0,
    main_helper: 0,
    np_helper: 0,
    output_helper: 0,
    output_all_helper: 0,
  },
  setHelpersData: () => {},
});

export const DesContextProvider = (props) => {
  const setHelpersData = (helpersData) => {
    setState({ ...state, helpersData: helpersData });
  };

  const initState = {
    helpersData: {
      remainder: 0,
      outcoming: 0,
      incoming: 0,
      main_helper: 0,
      np_helper: 0,
      output_helper: 0,
      output_all_helper: 0,
    },
    setHelpersData: setHelpersData,
  };

  const [state, setState] = useState(initState);

  return (
    <DesContext.Provider value={state}>{props.children}</DesContext.Provider>
  );
};
