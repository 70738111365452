import React, {useState, useEffect, useContext} from 'react';
import "bootstrap/dist/css/bootstrap.css";
import {Row, Col, Button, Table, Modal, Container, Spinner} from "react-bootstrap";
import { UserContext } from '../UserContextManager'
import axios from 'axios';
import cookies from 'js-cookie';

function NuSOConfirmed(){

  const uContext = useContext(UserContext)

  const [isLoading, setIsLoading] = useState(false)

  const [iiData, setIiData] = useState([]);
  const [batchConfirm, setBatchConfirm] = useState([]);


  const [modalIi, setModalIi] = useState({});
  const [show, setShow] = useState(false);
  const [fIModal, setFIModal] = useState(false);

  const loadIiData = async () => {

    setIsLoading(true);

    let url = '/redesk/nu/so/confirmed'

    const res = await axios.get(url);

    setIiData(res.data.ii_data);
    setIsLoading(false);
  }

  const loadUserData = async () => {

    let userDataRes = await axios.get('/redesk/general/remainder');

    let data = await userDataRes.data;

    let draftRes = await axios.get('/redesk/draft');

    let draftData = await draftRes.data;

    data.user_data['draft_data'] = draftData.draft_data;

    uContext.setUserData(data.user_data);

  }

  const handleShow = (e) => {
    setModalIi(iiData.find((element) => {return element.uuid === e.currentTarget.getAttribute('row-uuid')}));
    setShow(true);
  };

  const handleClose = () => setShow(false);

  const showWholeTable = () => {
    setFIModal(true)
  }

  const deleteDismissed = async (e) => {
    e.preventDefault();

    let res = await axios.post('/redesk/reas/outcoming/delete', {'delete':e.currentTarget.getAttribute('row-uuid')}, {headers:{'X-CSRF-TOKEN': cookies.get("csrf_access_token")}});
    setIiData(res.data.ii_data);
    setFIModal(false);
    setShow(false);
    loadUserData();
  }


  useEffect(() => {
    loadIiData();
    return () => {}
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  return(
    <Container fluid className="pt-2 px-0">
      <Row>

        {(-1 > 0 && isLoading === false) ? (
          <Col xs={12} style={{textAlign: "right"}}>
            <Button variant="primary" size='sm' className=" mb-2 mx-auto" onClick={showWholeTable}>
              Таблица
            </Button>
          </Col>
        ) : (<></>)}

        {(iiData.length > 0 && isLoading === false) ? (
          <div>
          <Col lg={12} className='overflow-auto accnt-outcoming-maincontainer'>
            <Table striped bordered size="sm" className='rd-accounting' responsive>
              <thead>
                <tr>
                <th>Дата создания</th>
                <th>ИД объекта</th>
                <th>Сумма</th>
                <th>Тип заказа</th>
                <th>Номер заказа</th>
                <th>Прораб</th>
                <th>Примечание</th>
                <th>Кто провел</th>
                </tr>
              </thead>
              <tbody>
                {
                  iiData.map((item) => (

                    <tr key={item.uuid} row-uuid={item.uuid} >
                      <td>{item.creation_date}</td>
                      <td>{item.object_id}</td>
                      <td className='col-value'>{window.redesk_app.common.fmtFinValue.format(item.sum)}</td>
                      <td>{item.metric_1}</td>
                      <td>{item.metric_4}</td>
                      <td>{item.responsible_user}</td>
                      <td>{item.comment}</td>
                      <td>{item.created_by_user}</td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>
          </Col>

            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Объект {modalIi.object_id}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p><strong>Дата создания:</strong> {modalIi.creation_date}</p>
                <p><strong>Ид объекта:</strong> {modalIi.object_id}</p>
                <p><strong>Ответственный:</strong> {modalIi.responsible_user}</p>
                <p><strong>Создал:</strong> {modalIi.created_by_user}</p>
                <p><strong>Тип статьи:</strong> {modalIi.item_type}</p>
                <p><strong>Статья:</strong> {modalIi.item}</p>
                <p><strong>Сумма:</strong> {window.redesk_app.common.fmtFinValue.format(modalIi.sum)}</p>
                <p><strong>Тип платежа:</strong> {modalIi.payment_type}</p>
                <p><strong>Вид работы:</strong> {modalIi.metric_1}</p>
                <p><strong>Подстатья:</strong> {modalIi.sub_item}</p>
                <p><strong>Юрлицо:</strong> {modalIi.company_name}</p>
                <p><strong>Примечание:</strong> {modalIi.comment}</p>
              </Modal.Body>
              {modalIi.confirmation_date !== null & modalIi.confirmed == false ?(
                <Modal.Footer>
                <Button row-uuid={modalIi.uuid} variant="danger" onClick={deleteDismissed}>
                  Удалить
                </Button>
              </Modal.Footer>
              ): (<></>)}
            </Modal>
          </div>
        ) : (iiData.length < 1 && isLoading === false) ? (
          <Col sm={12} style={{textAlign: "center"}}>
            <h4 style={{marginTop: "15%", marginBottom: "15%"}}>Нет подтвержденных</h4>
          </Col>
        ) : (
          <Col sm={12} style={{textAlign: "center"}}>
            <div style={{marginTop: "15%", marginBottom: "15%"}}>
              <Spinner animation="border" variant="primary"/>
            </div>
          </Col>
        )}
        </Row>
    </Container>
  )
}

export default NuSOConfirmed;
