import "bootstrap/dist/css/bootstrap.css";
import {Card, OverlayTrigger, Tooltip} from "react-bootstrap";
import NumberFormat from 'react-number-format';


function RemainderCard (props){

  let tooltipPlacement = '';
  props.tooltipPlacement === undefined ? tooltipPlacement = 'right' : tooltipPlacement = props.tooltipPlacement;

  return(
    <OverlayTrigger
      trigger="click"
      placement={tooltipPlacement}
      rootClose='true'
      overlay={
        <Tooltip>
            {props.tooltipText}
        </Tooltip>
      }
    >
      <Card
        bg={props.bgColor}
        key={props.keyProp}
        text={props.textColor}
        style={{ width: '100%', cursor:'pointer' }}
        className="mb-2"
        onClick={props.onClick}
      >
        <Card.Header>{props.title}</Card.Header>
        <Card.Body>
          <Card.Text>
            <NumberFormat
              value={props.value}
              displayType={'text'}
              thousandSeparator={' '}
              decimalSeparator={'.'}
              suffix={' ₽'}
              />
          </Card.Text>
        </Card.Body>
      </Card>
    </OverlayTrigger>
  )
}

export default RemainderCard
